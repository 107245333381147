import { ProductData, ProductCustomField } from "@/lib/5874/types";

export const getCustomField = (
	product: ProductData | undefined,
	customFieldName: string,
): ProductCustomField | undefined => {
	if (!product) {
		return undefined;
	}

	const targetName = customFieldName.trim().split(" ").join("").toLowerCase();

	const foundField =
		product.customFields?.find((customField) => {
			const name = customField.name?.trim().split(" ").join("").toLowerCase();
			return name === targetName;
		}) || undefined;

	return foundField;
};

/**
 * Get custom fields that by name that include
 * @param product
 * @param includes
 * @returns
 */
export const customFieldsInclude = (
	product: ProductData | undefined,
	includes: string,
): ProductCustomField[] | undefined => {
	if (!product) {
		return undefined;
	}

	const targetName = includes.trim().split(" ").join("").toLowerCase();

	return (
		product.customFields?.filter((customField) => {
			const name = customField.name.trim().split(" ").join("").toLowerCase();
			return name.includes(targetName);
		}) || []
	);
};

export const findInCustomFields = (
	customFields: ProductCustomField[],
	customFieldName: string,
): ProductCustomField | undefined => {
	const targetName = customFieldName.trim().split(" ").join("").toLowerCase();

	const foundField =
		customFields?.find((customField) => {
			const name = customField.name.trim().split(" ").join("").toLowerCase();
			return name === targetName;
		}) || undefined;

	return foundField;
};

export const includesInCustomFields = (
	customFields: ProductCustomField[],
	includes: string,
): ProductCustomField[] | undefined => {
	const targetName = includes.trim().split(" ").join("").toLowerCase();

	return (
		customFields?.filter((customField) => {
			const name = customField.name.trim().split(" ").join("").toLowerCase();
			return name.includes(targetName);
		}) || []
	);
};
