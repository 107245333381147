"use client";

import clsx from "clsx";
import { FC, useMemo } from "react";
import { useI18n } from "@/i18n/client";
import { ProductData } from "@/lib/5874/types";
import * as Popover from "@radix-ui/react-popover";
import { getLineItemForProduct } from "@/utils/cart-utils";

import { getCustomField } from "@/lib/bigcommerce/utils/bigcommerceCustomFieldsUtils";
import { useStoreSettingsContext } from "@/providers/StoreSettingsContextProvider";
import { useCartContext } from "@/providers/CartContextProvider";
import InfoIcon from "@/app/assets/images/svg/info.svg";
import CloseIcon from "@/app/assets/images/svg/xmark.svg";
import styles from "./StockAvailability.module.scss";

interface StockAvailabilityProps {
	product: ProductData;
	className?: string;
}

const StockAvailability: FC<StockAvailabilityProps> = ({
	product,
	className,
}) => {
	const { inventoryThreshold } = useStoreSettingsContext();
	const { cart } = useCartContext();
	const t = useI18n();

	const threshold = useMemo(() => {
		const customField = getCustomField(
			product,
			"inventory thresholds override",
		);

		if (customField) {
			const { value } = customField;

			try {
				return parseInt(value);
			} catch (error) {
				return inventoryThreshold;
			}
		}

		return inventoryThreshold;
	}, [inventoryThreshold, product]);

	const inventoryLevel = useMemo(() => {
		const customField = getCustomField(product, "Inventory Level");

		if (customField) {
			const { value } = customField;
			try {
				return parseInt(value);
			} catch (error) {
				return undefined;
			}
		}

		return undefined;
	}, [product]);

	if (
		inventoryLevel === undefined ||
		(inventoryLevel !== 0 && inventoryLevel > threshold)
	) {
		return <div className={clsx([styles.spacer, className])} />;
	}

	const lineItem = getLineItemForProduct(cart, product);

	const isMixed = !lineItem ? false : inventoryLevel - lineItem.quantity < 0;

	if (isMixed && lineItem) {
		return (
			<MixedAvailability
				className={className}
				cartQuantity={lineItem.quantity}
				inventoryLevel={inventoryLevel}
			/>
		);
	}

	if (inventoryLevel === 0) {
		return (
			<span className={clsx([styles.message, className])}>
				<InfoIcon className={styles.icon} /> {t("product.outOfStockPreOrder")}
			</span>
		);
	}

	if (inventoryLevel <= threshold) {
		return (
			<span className={clsx([styles.message, className])}>
				<InfoIcon className={styles.icon} />
				{t("product.limitedStock")}
			</span>
		);
	}

	return null;
};

const MixedAvailability = ({
	className,
	cartQuantity,
	inventoryLevel,
}: {
	className?: string;
	cartQuantity: number;
	inventoryLevel: number;
}) => {
	// const [show, setShow] = useState(false);
	const t = useI18n();
	const backOrderQuantity = Math.abs(inventoryLevel - cartQuantity);

	return (
		<span className={clsx([styles.message, className])}>
			<Popover.Root>
				<Popover.Trigger className={styles.trigger}>
					<InfoIcon className={styles.icon} /> {t("product.mixedAvailablity")}
				</Popover.Trigger>
				<Popover.Content
					className={styles.popoverContent}
					side='top'
					align='start'
					sideOffset={6}
				>
					<div className={styles.popoverContentInner}>
						<h4>{t("product.mixedAvailablity")}</h4>
						<p>
							{cartQuantity} {t("cart.addedToCart")}. {backOrderQuantity}{" "}
							{t("product.areOnBackOrder")}.
						</p>
					</div>
					<Popover.Close className={styles.popoverClose} aria-label='Close'>
						<CloseIcon />
					</Popover.Close>
				</Popover.Content>
			</Popover.Root>
		</span>
	);
};

export default StockAvailability;
