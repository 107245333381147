import {
	MetaResponseModel,
	SearchRequestModelFilter,
	SearchResponseModelFilter,
	SearchResponseModelMerchandising,
	SearchResponseModelPagination,
	SearchResponseModelResult,
	SearchResponseModelSearch,
	SearchResponseModelSorting,
} from "@searchspring/snapi-types";
import { ProductData } from "../5874/types";
import {
	ProfileResponseModel,
	RecommendationRequestFilterModel,
	RecommendCombinedRequestModel,
} from "@searchspring/snap-client/dist/cjs/types";

export enum SearchSpringCookie {
	ssUserId = "ssUserId",
	ssSessionId = "ssSessionId",
	ssShopperId = "ssShopperId",
	ssCartProducts = "ssCartProducts",
	ssViewedProducts = "ssViewedProducts",
}

export type SearchSpringCookies = Record<
	SearchSpringCookie,
	string | undefined
>;

export interface FacetType {
	field: string;
	type: string;
	filtered: boolean;
	values: FacetValue[];
}

export interface FacetValue {
	filtered?: boolean;
	label: string;
	count: number;
	value?: string;
	high?: number;
	low?: number;
}

export interface EnrichedSearchRequestModel {
	search?: SearchResponseModelSearch;
	filters?: Array<SearchResponseModelFilter>;
	pagination?: SearchResponseModelPagination;
	sorting?: Array<SearchResponseModelSorting>;
	results?: Array<ProductData>;
	facets?: Array<FacetType>;
	merchandising?: SearchResponseModelMerchandising;
}

export type EnrichedRecommendCombinedRequestModel =
	RecommendCombinedRequestModel & {
		limits?: number;
	};

export type EnrichedRecommendCombinedResponseModel = ProfileResponseModel & {
	results: ProductData[];
} & {
	meta: MetaResponseModel;
};

interface SearchSortOption extends SearchResponseModelSorting {
	label: string;
}

export interface SearchFilter extends SearchRequestModelFilter {
	value: string;
}

export interface ToApplyFacetData {
	params: string[];
	count?: number;
}

export interface searchSpringResponse {
	results: any[];
	profile: {
		tag: string;
	};
}
