import { CartPhysicalItem, ProductData } from "@/lib/5874/types";
import Cookies from "js-cookie";
import useStoreKeys from "./useStoreKeys";
import { getConfig } from "@/lib/searchspring/searchspring.config";

const useIntelliSuggestTracking = () => {
	const { store, lang } = useStoreKeys();
	const config = getConfig(store);
	const siteId = config.globals.siteId;

	const intellisuggestTrackProductViews = (product: ProductData) => {
		// @ts-ignore
		IntelliSuggest.init({
			siteId,
			context: `Product/${product.sku}`,
			seed: [product.sku],
		});
		// @ts-ignore
		IntelliSuggest.setShopperId(Cookies.get("ssShopperId"));
		// @ts-ignore
		IntelliSuggest.viewItem({ sku: product.sku });
	};

	const intellisuggestTrackBasketContents = (
		products: CartPhysicalItem[] | undefined,
	) => {
		if (products === undefined) {
			console.log("All removed.");
			return;
		}
		const seed = products.map((product) => product.sku);
		try {
			// @ts-ignore
			IntelliSuggest.init({
				siteId,
				context: "Basket",
				seed,
			});
			// @ts-ignore
			IntelliSuggest.setShopperId(Cookies.get("ssShopperId"));
			products.forEach((product) => {
				// @ts-ignore
				IntelliSuggest.haveItem({
					uid: product.id,
					sku: product.sku,
					childSku: "",
					qty: product.quantity.toString(),
					price: product.list_price.toString(),
				});
			});
			// @ts-ignore
			IntelliSuggest.inBasket({});
		} catch (error) {
			console.error("track basket call went wrong");
		}
	};

	const intellisuggestTrackProductClick = (product: ProductData) => {
		if (document.images) {
			const apiUrl = `https://${
				config.globals.siteId || ""
			}.a.searchspring.io/api/`;

			const imgTag = new Image();
			imgTag.src = `${apiUrl}track/track.json?d=${
				product.intellisuggestData
			}&s=${product.intellisuggestSignature}&u=${encodeURIComponent(
				product.path,
			)}&r=${
				encodeURIComponent(document.referrer) ||
				encodeURIComponent(window.location.toString())
			}`;
		}

		return true;
	};

	return {
		productView: intellisuggestTrackProductViews,
		productClick: intellisuggestTrackProductClick,
		basketContents: intellisuggestTrackBasketContents,
	};
};

export default useIntelliSuggestTracking;
