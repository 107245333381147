import React from "react";
import clsx from "clsx";
import { useSwiper } from "swiper/react";
import IconArrow from "../UI/Icon/IconArrow/IconArrow";
import styles from "./SwiperNavigationButton.module.scss";
import { AdditionalArgs, CommonArgs } from "@/lib/searchspring/tracker";
import {
	genericSendBeaconEvent,
	beaconTrackProfileClick,
} from "@/lib/searchspring/tracker";
import { StoreRegion } from "@/lib/builder/builder.config";

interface SwiperNavigationButtonsProps {
	buttonIdentifier: string;
	buttonYPosition?: "middle";
	clickData?: CommonArgs & AdditionalArgs;
	store?: StoreRegion;
	className?: string;
	prevButtonClassName?: string;
	nextButtonClassName?: string;
}

interface SwiperNavigationButtonProps extends SwiperNavigationButtonsProps {
	movement: "previous" | "next";
	clickData?: CommonArgs & AdditionalArgs;
	store?: StoreRegion;
}

const SwiperNavigationButton: React.FC<SwiperNavigationButtonProps> = ({
	movement,
	buttonIdentifier,
	buttonYPosition,
	clickData,
	store,
	className,
}) => {
	return (
		<button
			type='button'
			aria-label={`button-${movement}`}
			data-button-ref={`${buttonIdentifier}-button-${movement}`}
			className={
				className ||
				clsx([
					styles.carouselArrowButtons,
					styles[`buttonPlacement--${movement}`],
					[buttonYPosition === "middle" ? styles.buttonYPosition : ""],
				])
			}
			onClick={() => {
				if (clickData && store) {
					const event = [beaconTrackProfileClick(clickData)];
					genericSendBeaconEvent(event, store);
				}
			}}
		>
			<IconArrow
				direction={movement}
				aria-labelledby={`directional chevron pointing down ${movement}`}
			/>
		</button>
	);
};

const SwiperNavigationButtons: React.FC<SwiperNavigationButtonsProps> = ({
	buttonIdentifier,
	clickData,
	store,
	prevButtonClassName,
	nextButtonClassName,
}) => {
	return (
		<>
			<SwiperNavigationButton
				movement='previous'
				buttonIdentifier={buttonIdentifier}
				buttonYPosition='middle'
				clickData={clickData}
				store={store}
				className={prevButtonClassName}
			/>

			<SwiperNavigationButton
				movement='next'
				buttonIdentifier={buttonIdentifier}
				buttonYPosition='middle'
				clickData={clickData}
				store={store}
				className={nextButtonClassName}
			/>
		</>
	);
};

export default SwiperNavigationButtons;
