"use client";
import Logo from "@/app/assets/images/svg/logo.png";
import Link from "next/link";
import React, { FC } from "react";
import { HeaderLogoType } from "../headerTypes";
import styles from "./HeaderLogo.module.scss";

interface HeaderLogoProps extends HeaderLogoType {
	href?: string;
}

const HeaderLogo: FC<HeaderLogoProps> = ({
	url = Logo.src,
	href = "/",
	altText,
}) => {
	return (
		<Link href={href}>
			<img
				src={`${url}?format=webp`}
				className={styles.logo}
				alt={altText}
				aria-label='Return to Movora home'
			/>
		</Link>
	);
};

export default HeaderLogo;
