"use client";

import { FC, useMemo } from "react";
import clsx from "clsx";
import Link from "next/link";
import useStoreKeys from "@/hooks/useStoreKeys";
import { useProductContext } from "@/providers/ProductContextProvider";
import CompressedImage from "@/components/UI/CompressedImage/CompressedImage";
import mapProductOptions from "@/hooks/mapProductOptions";
import PlaceholderImage from "@/app/assets/images/img/placeholder.png";
import styles from "./CommonProductDetails.module.scss";

interface Props {
	isCustomer: boolean;
	showSimpleView?: boolean;
	clampProductName?: boolean;
}

const CommonProductDetails: FC<Props> = ({
	isCustomer,
	showSimpleView = false,
	clampProductName,
}) => {
	const { store, lang } = useStoreKeys();
	const {
		product,
		product: { id, brand, media, name: productName, path: productLink, sku },
	} = useProductContext();

	// TODO: verify if gql media data is correct, then clean-up commented code below
	// const thumbnailImage = media.images.find((item) => item.isThumbnail === true);
	const thumbnailImage = product.media?.images?.[0];

	const productSpecifications = useMemo(() => {
		return mapProductOptions([product]);
	}, [product]);

	return (
		<div className={styles.commonDetails}>
			<CompressedImage
				src={thumbnailImage?.url.thumbnail ?? PlaceholderImage.src}
				altText={`Product ${productName} Image`}
				height={300}
				width={300}
				containerClass={clsx(styles.imageContainer, {
					[styles.imageContainerPadding]: clampProductName,
				})}
				imgLink={`/${store}/${lang}${productLink}`}
				withNextImage={false}
			/>
			<div className={styles.details}>
				{brand && (
					<Link
						href={`/${store}/${lang}/${brand?.path}`}
						// onMouseDown={() => onClick(product)}
						onMouseEnter={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
						className={clsx([styles.brandLink, "swiper-no-swiping"])}
					>
						{brand?.name}
					</Link>
				)}
				{isCustomer && !showSimpleView && <p className={styles.sku}>{sku}</p>}
				<Link
					href={`/${store}/${lang}${productLink}`}
					onMouseEnter={(e) => e.stopPropagation()}
					// onMouseDown={() => onClick(product)}
					className={clsx([
						styles.productName,
						"swiper-no-swiping",
						clampProductName ? styles.ellipsis : "",
					])}
				>
					{productName}
				</Link>
			</div>
			{productSpecifications && productSpecifications.length > 0 && (
				<div className={styles.specs}>
					{productSpecifications?.map((spec) => (
						<div key={spec.label} className={styles.spec}>
							<span className={styles.label}>{spec.label}:</span>
							<span className={styles.value}>{spec.options[0].label}</span>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default CommonProductDetails;
