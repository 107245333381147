"use client";

import { Builder } from "@builder.io/react";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
import Button from "../UI/Button/Button";
import IconArrow from "../UI/Icon/IconArrow/IconArrow";
import Markdown from "../UI/Markdown/Markdown";
import styles from "./SolutionsCarousel.module.scss";
import clsx from "clsx";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

// import required modules
import { A11y, Navigation, Scrollbar } from "swiper/modules";
import SwiperNavigationButtons from "../SwiperNavigationButton/SwiperNavigationButton";

interface SolutionsCarouselProps {
	solutions: [
		{
			title: string;
			description: string;
			button: {
				text: string;
				link: string;
				alternativeAria: boolean;
				ariaLabel: string;
			};
			image: {
				imageCurveBottom: boolean;
				imageCurveTop: boolean;
				image: string;
				altText: string;
			};
		},
	];
}

const SolutionsCarousel: React.FC<SolutionsCarouselProps> = ({ solutions }) => {
	const carouselId = "solutions-carousel";
	return (
		<section className={styles.solutionsCarousel}>
			<Swiper
				modules={[Navigation, Scrollbar, A11y]}
				navigation={{
					enabled: true,
					nextEl: `[data-button-ref="${carouselId}-button-next"]`,
					prevEl: `[data-button-ref="${carouselId}-button-previous"]`,
				}}
				scrollbar={{ enabled: true, draggable: true }}
				slidesPerView={1}
				spaceBetween={24}
				breakpoints={{
					551: {
						slidesPerView: "auto",
					},
				}}
			>
				{solutions.map((solution, index) => (
					<SwiperSlide key={index}>
						<div className={styles.solution}>
							<img
								src={`${solution.image.image}?format=webp`}
								alt={solution.image.altText}
								className={clsx(styles.imageContainer, {
									[styles.imageCurveBottom]: solution.image.imageCurveBottom,
									[styles.imageCurveTop]: solution.image.imageCurveTop,
									[styles.imageCurveTopBottom]:
										solution.image.imageCurveBottom &&
										solution.image.imageCurveTop,
								})}
							/>
							<div className={styles.textArea}>
								<h2>{solution.title}</h2>
								<Markdown
									content={solution.description}
									className={styles.markdown}
								/>
								<Button
									variant='secondary'
									as='link'
									href={solution.button.link}
									aria-label={
										solution.button.alternativeAria
											? solution.button.ariaLabel
											: solution.button.text
									}
								>
									{solution.button.text}
								</Button>
							</div>
						</div>
					</SwiperSlide>
				))}

				<SwiperNavigationButtons buttonIdentifier={carouselId} />
			</Swiper>
		</section>
	);
};

export default SolutionsCarousel;

Builder.registerComponent(SolutionsCarousel, {
	name: "Solutions Carousel",
	inputs: [
		{
			name: "solutions",
			type: "list",
			defaultValue: [
				{
					title: "Title",
					description: "description text",
					image: {
						image: "https://placehold.co/318x180",
						altText: "alt text",
					},
					button: {
						text: "Learn More",
						link: "#",
					},
				},
			],
			subFields: [
				{
					name: "title",
					type: "longText",
					defaultValue: "Title",
					required: true,
				},
				{
					name: "description",
					type: "richText",
					defaultValue: "description text",
					required: true,
				},
				{
					name: "image",
					type: "object",
					defaultValue: {
						image: "https://placehold.co/318x180",
						altText: "alt text",
					},
					subFields: [
						{
							name: "imageCurveBottom",
							friendlyName: "Add Bottom Image Curve?",
							type: "boolean",
							defaultValue: false,
							helperText: "Toggle to add a curve to the bottom of the image",
						},
						{
							name: "imageCurveTop",
							friendlyName: "Add Top Image Curve?",
							type: "boolean",
							defaultValue: false,
							helperText: "Toggle to add a curve to the top of the image",
						},
						{
							name: "image",
							type: "file",
							allowedFileTypes: ["jpeg", "jpg", "png"],
							helperText: "318×180 px | .jpeg, .jpg, .png | ~150 KB",
							required: true,
						},
						{
							name: "altText",
							friendlyName: "Alt Text",
							type: "longText",
							required: true,
						},
					],
				},
				{
					name: "button",
					type: "object",
					defaultValue: {
						text: "Learn More",
						link: "#",
					},
					subFields: [
						{
							name: "text",
							type: "longText",
							required: true,
						},
						{
							name: "link",
							type: "longText",
							required: true,
						},
						{
							name: "alternativeAria",
							friendlyName: "Add Custom Link Aria Label?",
							type: "boolean",
							defaultValue: false,
						},
						{
							name: "ariaLabel",
							friendlyName: "Aria Label",
							type: "long-text",
							showIf: `options.get('alternativeAria')`,
						},
					],
				},
			],
		},
	],
});
