"use client";

import * as Toast from "@radix-ui/react-toast";
import clsx from "clsx";
import { useState } from "react";
import styles from "./Notification.module.scss";

import WarningIcon from "@/app/assets/images/svg/alert-triangle.svg";
import SuccessIcon from "@/app/assets/images/svg/check-circle.svg";
import InfoIcon from "@/app/assets/images/svg/info.svg";
import ErrorIcon from "@/app/assets/images/svg/x-octagon.svg";

export type NotificationVariant = "info" | "success" | "warning" | "error";
interface Props {
	type?: "notification" | "toast";
	variant: NotificationVariant;
	title?: string;
	children?: React.ReactNode;
	toastAutoClose?: boolean;
}

const Notification = ({ type = "notification", title, ...props }: Props) => {
	const getIcon = () => {
		switch (props.variant) {
			case "info":
				return <InfoIcon />;
			case "success":
				return <SuccessIcon />;
			case "warning":
				return <WarningIcon />;
			case "error":
				return <ErrorIcon />;
		}
	};

	const content = (
		<div className={styles.container}>
			{getIcon()}
			<div className={styles.content}>
				{title && <div className={styles.title}>{title}</div>}
				{props.children}
			</div>
		</div>
	);

	if (type === "toast") {
		return <ToastNotification {...props}>{content}</ToastNotification>;
	}

	return (
		<div className={clsx([styles.notification, styles[props.variant]])}>
			{content}
		</div>
	);
};

const ToastNotification = ({ variant, children, toastAutoClose }: Props) => {
	const [open, setOpen] = useState(true);

	return (
		<Toast.Provider swipeDirection='right'>
			<Toast.Root
				className={clsx([styles.toastRoot, styles[variant]])}
				open={open}
				onOpenChange={(open) => {
					if (!open && toastAutoClose) {
						setOpen(false);
					}
				}}
			>
				<Toast.Description asChild>
					<>
						{children}
						<button
							type='button'
							aria-label='Close notification'
							className={styles.closeToast}
							onClick={() => setOpen(false)}
						>
							Close
						</button>
					</>
				</Toast.Description>
			</Toast.Root>
			<Toast.Viewport className='ToastViewport' />
		</Toast.Provider>
	);
};

export default Notification;
