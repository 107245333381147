"use client";
import { useScopedI18n } from "@/i18n/client";
import Link from "next/link";
import { FC } from "react";
import PublishDate from "../PublishDate/PublishDate";
import styles from "./NewsCard.module.scss";

interface Props {
	title: string;
	link: string;
	date?: Date;
	image?: string;
	precis?: string;
}

const NewsCard: FC<Props> = ({ title, link, date, image, precis }) => {
	const scopedT = useScopedI18n("text");

	return (
		<Link
			className={styles.link}
			href={link}
			aria-label={`${scopedT("readMoreAbout")} ${title}`}
		>
			<article className={styles.card}>
				{image ? (
					<div className={styles.image}>
						<img src={`${image}?format=webp`} alt={title} />
					</div>
				) : null}
				<div className={styles.content}>
					{date && <PublishDate date={date.valueOf()} />}
					<h3 className={styles.title}>{title}</h3>
					{precis && precis.length > 0 ? <p>{precis}</p> : null}
				</div>
				<span className={styles.readMore}>{scopedT("readMore")}</span>
			</article>
		</Link>
	);
};

export default NewsCard;
