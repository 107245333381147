"use client";

import { Builder } from "@builder.io/react";
import * as Select from "@radix-ui/react-select";
import clsx from "clsx";
import moment from "moment";
import Link from "next/link";
import React, { useState } from "react";
import Moment from "react-moment";
import countryList from "react-select-country-list";
import ExternalLink from "@/app/assets/images/svg/external-link.svg";
import InfoSvg from "@/app/assets/images/svg/info.svg";
import IconArrow from "../UI/Icon/IconArrow/IconArrow";
import styles from "./CoursesDisplay.module.scss";

interface CoursesDisplayProps {
	title: string;
	link: {
		linkText: string;
		linkUrl: string;
	};
	courses: [
		{
			region: string;
			title: string;
			startDate: string;
			endDate: string;
			link: {
				linkText: string;
				linkUrl: string;
			};
			limitedSpace: boolean;
		},
	];
}

const CoursesDisplay: React.FC<CoursesDisplayProps> = ({
	title,
	link,
	courses,
}) => {
	const checkDate = (dateToCheck: string) => {
		return moment(dateToCheck).isAfter();
	};
	const isSameYear = (startDate: string, endDate: string) => {
		return moment(startDate).isSame(endDate, "year");
	};
	const courseRegions = new Set(
		courses
			.filter((course) => checkDate(course.startDate))
			.map((course) => course.region),
	);
	const [regionSelected, setRegionSelected] = useState("All");

	return (
		<section className={styles.section}>
			<div className={styles.heading}>
				<h3>{title}</h3>
				<Link href={link.linkUrl}>
					{link.linkText}
					<IconArrow
						direction={"right"}
						aria-labelledby='directional chevron pointing right'
					/>
				</Link>
			</div>
			<Select.Root
				onValueChange={(value) => setRegionSelected(value)}
				value={regionSelected}
			>
				<Select.Trigger className={styles.SelectTrigger}>
					<Select.Value placeholder='Select Region'>
						{`Region: ${regionSelected}`}
					</Select.Value>
					<IconArrow
						direction={"down"}
						aria-labelledby='directional chevron pointing down'
					/>
				</Select.Trigger>
				<Select.Portal>
					<Select.Content className={styles.SelectContent}>
						<Select.ScrollUpButton />
						<Select.Viewport>
							{["All", ...courseRegions].map((country, index) => (
								<Select.Item
									key={country + index}
									value={country}
									className={styles.SelectItem}
								>
									<Select.ItemText className={styles.SelectItemText}>
										{country}
									</Select.ItemText>
								</Select.Item>
							))}
						</Select.Viewport>
						<Select.ScrollDownButton />
					</Select.Content>
				</Select.Portal>
			</Select.Root>
			<div className={styles.courses}>
				{courses
					.filter((course) => checkDate(course.startDate))
					.filter((course) => {
						if (regionSelected === "All") {
							return true;
						}
						return course.region == regionSelected;
					})
					.map((course) => (
						<div
							key={course.link.linkUrl}
							className={clsx([
								styles.course,
								course.limitedSpace && styles.limited,
							])}
						>
							{course.limitedSpace && (
								<p className={styles.limited}>
									<InfoSvg />
									Limited spaces!
								</p>
							)}
							<div className={styles.details}>
								<p className={styles.dates}>
									{isSameYear(course.startDate, course.endDate) ? (
										<Moment format='MMM D'>{course.startDate}</Moment>
									) : (
										<Moment format='MMM D, YYYY'>{course.startDate}</Moment>
									)}{" "}
									- <Moment format='MMM D, YYYY'>{course.endDate}</Moment>
								</p>
								<h4>{course.title}</h4>
								<Link href={course.link.linkUrl}>
									{course.link.linkText}
									<ExternalLink />
								</Link>
							</div>
						</div>
					))}
			</div>
		</section>
	);
};

export default CoursesDisplay;

Builder.registerComponent(CoursesDisplay, {
	name: "Courses Display",
	inputs: [
		{
			name: "title",
			friendlyName: "Title",
			type: "longText",
			defaultValue: "Title",
			required: true,
		},
		{
			name: "link",
			type: "object",
			defaultValue: {
				linkText: "Explore Full Catalog",
				linkUrl: "#",
			},
			subFields: [
				{
					name: "linkText",
					type: "longText",
					defaultValue: "Explore Full Catalog",
				},
				{
					name: "linkUrl",
					type: "url",
					defaultValue: "#",
				},
			],
		},
		{
			name: "courses",
			friendlyName: "Courses",
			type: "list",
			defaultValue: [
				{
					startDate: "12, 20, 2024",
					endDate: "12, 30, 2024",
					title: "Course Title",
					region: "United Kingdom",
					link: {
						linkText: "Learn More",
						linkUrl: "#",
					},
					limitedSpaces: false,
				},
			],
			subFields: [
				{
					name: "region",
					friendlyName: "Region",
					enum: countryList().getLabels(),
					type: "longText",
					defaultValue: "United Kingdom",
					required: true,
				},
				{
					name: "startDate",
					friendlyName: "Start Date",
					type: "date",
					defaultValue: "12, 31, 2024",
				},
				{
					name: "endDate",
					friendlyName: "End Date",
					type: "date",
					defaultValue: "20, 12, 2024",
				},
				{
					name: "title",
					friendlyName: "Course Title",
					type: "longText",
					defaultValue: "Title",
					required: true,
				},
				{
					name: "link",
					type: "object",
					defaultValue: {
						linkText: "Learn More",
						linkUrl: "#",
					},
					subFields: [
						{
							name: "linkText",
							type: "longText",
							defaultValue: "Learn More",
						},
						{
							name: "linkUrl",
							type: "url",
							defaultValue: "#",
						},
					],
				},
				{
					name: "limitedSpace",
					friendlyName: "Limited Space",
					type: "boolean",
					defaultValue: false,
				},
			],
		},
	],
});
