"use client";

import React from "react";
import styles from "./TwoPanelQuoteBlock.module.scss";
import { Builder } from "@builder.io/react";
import Markdown from "../UI/Markdown/Markdown";
import IconArrow from "../UI/Icon/IconArrow/IconArrow";
import ReactPlayer from "react-player";
import { SwiperNavigationButtonProps } from "@/lib/5874/types";

// Import Swiper React components
import {
	A11y,
	EffectFade,
	Keyboard,
	Navigation,
	Scrollbar,
} from "swiper/modules";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

interface TwoPanelQuoteBlockProps {
	toggleBorder: boolean;
	leftPanel: {
		isVideo: boolean;
		videoSrc?: string;
		content: string;
	};
	quotes: [
		{
			title: string;
			quote: string;
		},
	];
}

const TwoPanelQuoteBlock: React.FC<TwoPanelQuoteBlockProps> = ({
	toggleBorder,
	leftPanel,
	quotes,
}) => {
	return (
		<section
			className={`${styles.twoPanelQuoteBlockSection} ${
				toggleBorder ? styles.toggleBorder : ""
			}`}
		>
			<div
				className={`${styles.twoPanelQuoteBlockWrapper} 
					${leftPanel.isVideo ? styles.alignVideo : ""}
					`}
			>
				<div className={styles.leftPanel}>
					{leftPanel.isVideo ? (
						<div className={styles.imageContainer}>
							<ReactPlayer url={leftPanel.videoSrc} light={true} />
						</div>
					) : (
						<Markdown className={styles.markdown} content={leftPanel.content} />
					)}
				</div>
				<Swiper
					className={styles.swiper}
					slidesPerView={1}
					modules={[Navigation, Scrollbar, EffectFade, A11y, Keyboard]}
					loop={quotes?.length > 1 ? true : false}
					a11y={{
						prevSlideMessage: "Previous Slide",
						nextSlideMessage: "Next Slide",
					}}
					keyboard={{
						enabled: true,
					}}
					scrollbar={true}
					effect='fade'
					fadeEffect={{
						crossFade: true,
					}}
				>
					{quotes?.map((quote, index) => (
						<SwiperSlide key={index}>
							<div className={styles.quoteArea}>
								{quote.title && <h2>{quote.title}</h2>}
								<Markdown
									className={`${styles.markdown} ${styles.quoteText}`}
									content={quote.quote}
								/>
							</div>
						</SwiperSlide>
					))}
					<div className={styles.navigationButtons}>
						{quotes.length > 1 && (
							<>
								<SwiperNavigationButton movement='previous' />
								<SwiperNavigationButton movement='next' />
							</>
						)}
					</div>
				</Swiper>
			</div>
		</section>
	);
};

const SwiperNavigationButton: React.FC<SwiperNavigationButtonProps> = ({
	movement,
}) => {
	const swiper = useSwiper();
	return (
		<button
			type='button'
			aria-label={`button-${movement}`}
			className={`${styles.carouselArrowButtons} ${
				styles[`buttonPlacement--${movement}`]
			}`}
			onClick={() =>
				movement === "next" ? swiper.slideNext() : swiper.slidePrev()
			}
		>
			<IconArrow
				direction={movement}
				aria-labelledby={`directional chevron pointing down ${movement}`}
			/>
		</button>
	);
};

export default TwoPanelQuoteBlock;

Builder.registerComponent(TwoPanelQuoteBlock, {
	name: "Two Panel Quote Block",
	inputs: [
		{
			name: "toggleBorder",
			type: "boolean",
			defaultValue: false,
			friendlyName: "Show Top Border?",
			helperText: "toggle to add a top border",
		},
		{
			name: "leftPanel",
			type: "object",
			defaultValue: {
				content: "some content",
				isVideo: false,
				videoSrc:
					"https://www.youtube.com/embed/oznr-1-poSU?si=vDTwpUUlOjAqgGTG",
			},
			subFields: [
				{
					name: "isVideo",
					friendlyName: "Video?",
					type: "boolean",
					defaultValue: false,
				},
				{
					name: "videoSrc",
					type: "text",
					friendlyName: "Video Link",
					defaultValue:
						"https://www.youtube.com/embed/oznr-1-poSU?si=vDTwpUUlOjAqgGTG",
					helperText: "1600×1000 px | YouTube/Vimeo video link",
					showIf: `options.get('isVideo')`,
				},
				{
					name: "content",
					type: "richText",
					helperText:
						"Use 'H2' for title headings. Use 'H5' for footnotes | ~300 characters",
					showIf: `!options.get('isVideo')`,
				},
			],
		},
		{
			name: "quotes",
			type: "list",
			subFields: [
				{
					name: "title",
					type: "longText",
					defaultValue: "This is the title",
					helperText: "~30 characters",
				},
				{
					name: "quote",
					type: "richText",
					defaultValue: "Here is the quote",
					helperText:
						"Use 'H2' for title headings. Use 'H5' for footnotes | ~300 characters",
					required: true,
				},
			],
			defaultValue: [
				{
					title: "This is the title",
					quote: "This is a quote",
				},
			],
		},
	],
});
