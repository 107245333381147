import { CurrencyFormattingRules } from "@/lib/5874/types";

export const formatPrice = (value: number, rules: CurrencyFormattingRules) => {
	if (value === undefined) {
		return {};
	}

	const {
		currencySymbol,
		currencySymbolLocation,
		decimalPlaces,
		decimalSeparator,
		thousandsSeparator,
		currency,
	} = rules;

	const formattedPrice = value.toFixed(decimalPlaces);
	const [integerPart, decimalPart] = formattedPrice.split(".");
	const integerPartWithThousandsToken = integerPart.replace(
		/\B(?=(\d{3})+(?!\d))/g,
		thousandsSeparator,
	);

	const formattedDecimalPart = `${decimalSeparator}${decimalPart}`;

	const rawValue = `${integerPartWithThousandsToken}${formattedDecimalPart}`;

	const formattedValue =
		currencySymbolLocation === "left"
			? `${currencySymbol}${rawValue}`
			: `${rawValue}${currencySymbol}`;

	return {
		currency,
		rawValue: value,
		formattedValue: formattedValue,
	};
};
