import clsx from "clsx";
import { SwiperSlide } from "swiper/react";
import CompressedImage from "../CompressedImage/CompressedImage";
import PlaceholderImage from "@/app/assets/images/img/placeholder.png";
import styles from "./ProductSliderSkeleton.module.scss";

const ProductSliderSkeleton = Array.from({ length: 6 }, (_, index) => (
	<SwiperSlide
		key={index}
		className={clsx([styles.slide, styles.skeletonTemplate])}
	>
		<article key={index} className={styles.card}>
			<div className={styles.inner}>
				<div>
					<CompressedImage
						src={PlaceholderImage.src}
						altText={"Product Loading"}
						height={316}
						width={316}
						containerClass={styles.imageContainer}
						withNextImage={false}
					/>
					<div className={styles.skeletonDetails}>
						{Array.from({ length: 3 }, (_, index) => (
							<p key={index} />
						))}
					</div>
				</div>
			</div>
		</article>
	</SwiperSlide>
));

export default ProductSliderSkeleton;

export const ProductSliderSkeletonSmall = Array.from(
	{ length: 6 },
	(_, index) => (
		<SwiperSlide
			key={index}
			className={clsx([styles.slide, styles.skeletonTemplate])}
		>
			<article key={index} className={styles.cardSmall}>
				<div className={clsx([styles.inner])}>
					<CompressedImage
						src={PlaceholderImage.src}
						altText={"Product Loading"}
						height={80}
						width={80}
						containerClass={styles.imageContainer}
						withNextImage={false}
					/>
					<div className={styles.skeletonDetails}>
						{Array.from({ length: 2 }, (_, index) => (
							<p key={index}>&nbsp;</p>
						))}
					</div>
				</div>
			</article>
		</SwiperSlide>
	),
);
