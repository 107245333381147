"use client";

import AddToListIcon from "@/app/assets/images/svg/wishlist_add.svg";
import { useI18n } from "@/i18n/client";
import React, { FC } from "react";
import IconOnlyButton from "../Button/IconOnlyButton/IconOnlyButton";
import { useProductContext } from "@/providers/ProductContextProvider";
import { ProductData } from "@/lib/5874/types";

interface AddToWishlistButtonProps {
	userId?: number;
	customClass?: string;
	product: ProductData;
}

const AddToWishlistButtonComplex: FC<AddToWishlistButtonProps> = ({
	userId,
	customClass,
	product,
}) => {
	if (!userId) return null;

	const { handleShowModal, handleSetWishlistModalProduct } =
		useProductContext();

	const handleClick = () => {
		handleShowModal(true);
		handleSetWishlistModalProduct(product);
	};

	const t = useI18n();

	return (
		<>
			<IconOnlyButton
				as='button'
				type='button'
				variant='secondary'
				onClick={(e) => {
					e.stopPropagation();
					handleClick();
				}}
				aria-label={t("account.wishlist.addToList")}
				icon={<AddToListIcon />}
				className={customClass}
			/>
		</>
	);
};

export default AddToWishlistButtonComplex;
