"use client";
import { useEffect } from "react";
import { useDataLayerContext } from "./DataLayerContextProvider";
import { DataLayerEvents } from "./DataLayerContextProvider";

interface Props {
	pageType: PageType;
	userId: number | undefined;
	companyName: string | undefined;
	currencyCode?: string | null;
	accountNumber?: string | null;
}

type PageType =
	| "page"
	| "product"
	| "cart"
	| "checkout"
	| "company"
	| "accounts"
	| "informational"
	| "regional"
	| "blogs"
	| "news";

export default function PageViewEvent({
	pageType,
	userId,
	companyName,
	currencyCode,
	accountNumber,
}: Props) {
	const { push } = useDataLayerContext();
	useEffect(() => {
		const eventPayload: Record<string, any> = {
			event: DataLayerEvents.PAGE_VIEW,
			userId: userId !== 0 ? userId : undefined,
			companyName: companyName ?? undefined,
			pageType,
		};

		// Conditionally add optional properties
		if (currencyCode) {
			eventPayload.currencyCode = currencyCode;
		}
		if (accountNumber) {
			eventPayload.accountNumber = accountNumber;
		}

		push(eventPayload);
	}, []);

	return null;
}
