"use client";

import { useMediaQuery as _useMediaQuery } from "@chakra-ui/react";

import variables from "@/scss/modules/variables.module.scss";

const useMediaQuery = () => {
	const [mobile, tablet, desktop] = _useMediaQuery([
		"only screen and (min-width : 0)",
		`only screen and (min-width : ${variables.sm})`,
		`only screen and (min-width : ${variables.md})`,
	]);

	return {
		isMobile: mobile,
		isTablet: tablet,
		isDesktop: desktop,
	};
};

export default useMediaQuery;
