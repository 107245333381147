import { Builder } from "@builder.io/react";
import { FC } from "react";
import styles from "./PromotionalBanner.module.scss";

type PromoProps = {
	imgSrc: string;
	altText: string;
	link: string;
	ariaLabel: string;
};

const PromotionalBanner: FC<PromoProps> = ({
	imgSrc,
	altText,
	link = "#",
	ariaLabel,
}) => {
	return (
		<section className={styles.contentSection}>
			<a href={link} target='_blank' rel='noreferrer'>
				<div className={styles.imgContainer}>
					<img
						src={`${imgSrc}?format=webp`}
						alt={altText}
						aria-label={ariaLabel}
					/>
				</div>
			</a>
		</section>
	);
};

export default PromotionalBanner;

Builder.registerComponent(PromotionalBanner, {
	name: "Promotional Banner",
	inputs: [
		{
			name: "imgSrc",
			type: "file",
			friendlyName: "Hero image",
			defaultValue: "https://placehold.co/1200x300/webp",
			helperText: "Add an image to your banner (1200 x 300).",
			allowedFileTypes: ["jpeg", "jpg", "png"],
		},
		{
			name: "altText",
			type: "string",
			friendlyName: "Alt text",
			defaultValue: "e.g. The two dogs run",
			helperText: "Describe the image the best you can.",
			required: true,
		},
		{
			name: "link",
			type: "string",
			friendlyName: "Link",
			defaultValue: "#",
			helperText: "Provide a url path you would like to link too.",
			required: true,
		},
		{
			name: "ariaLabel",
			type: "string",
			friendlyName: "Aria-label",
			defaultValue: "Go to here",
			helperText:
				"Provide a helpful hint about what clicking this banner does.",
			required: true,
		},
	],
});
