"use client";
import { Builder } from "@builder.io/react";
import React from "react";
import Button from "../UI/Button/Button";
import Markdown from "../UI/Markdown/Markdown";
import styles from "./SingleImageContent.module.scss";
import clsx from "clsx";

type ButtonType = {
	showButton?: boolean;
	buttonText: string;
	buttonType: "primary" | "secondary";
	buttonLink: string;
	openInNewTab: boolean;
	alternativeAria: boolean;
	ariaLabel: string;
};
interface SingleImageContentProps {
	marginTB: boolean;
	marginTOnly: boolean;
	marginBOnly: boolean;
	mainImage: {
		imageLocation: boolean;
		imageCurveBottom: boolean;
		imageCurveTop: boolean;
		image: string;
		altText: string;
	};
	logo: {
		showLogo: boolean;
		logo: string;
		logoAlt: string;
	};

	title: string;
	description: string;
	primaryButton: ButtonType;
	secondaryButton: ButtonType;
}

const SingleImageContent: React.FC<SingleImageContentProps> = ({
	marginTB,
	marginTOnly,
	marginBOnly,
	mainImage: { imageLocation, imageCurveBottom, imageCurveTop, image, altText },
	logo: { showLogo, logo, logoAlt },
	title,
	description,
	primaryButton,
	secondaryButton,
}) => {
	return (
		<section
			className={clsx(styles.contentSection, {
				[styles.ecomMargins]: marginTB,
				[styles.corpMargins]: marginTOnly,
				[styles.bottomMargins]: marginBOnly,
			})}
		>
			<div
				className={clsx(styles.contentWrapper, {
					[styles.switchOrder]: imageLocation !== true,
				})}
			>
				<div className={styles.imageArea}>
					<img
						src={`${image}?format=webp`}
						alt={altText}
						className={clsx({
							[styles.imageCurveBottom]: imageCurveBottom,
							[styles.imageCurveTop]: imageCurveTop,
							[styles.imageCurveTopBottom]: imageCurveBottom && imageCurveTop,
						})}
					/>
				</div>
				<div className={styles.textArea}>
					{showLogo && (
						<img
							className={styles.logo}
							src={`${logo}?format=webp`}
							alt={logoAlt}
						/>
					)}
					{title && <h2>{title}</h2>}
					{description && (
						<Markdown content={description} className={styles.description} />
					)}
					<div className={styles.buttons}>
						{primaryButton?.showButton && (
							<Button
								as='link'
								variant={primaryButton.buttonType || "primary"}
								href={primaryButton.buttonLink}
								target={primaryButton.openInNewTab ? "_blank" : "_parent"}
								aria-label={
									primaryButton.alternativeAria
										? primaryButton.ariaLabel
										: primaryButton.buttonText
								}
							>
								{primaryButton.buttonText}
							</Button>
						)}
						{secondaryButton?.showButton && (
							<Button
								as='link'
								variant={secondaryButton.buttonType || "secondary"}
								className={styles.btnPlacement}
								href={secondaryButton.buttonLink}
								target={secondaryButton.openInNewTab ? "_blank" : "_parent"}
								aria-label={
									secondaryButton.alternativeAria
										? secondaryButton.ariaLabel
										: secondaryButton.buttonText
								}
							>
								{secondaryButton.buttonText}
							</Button>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default SingleImageContent;

Builder.registerComponent(SingleImageContent, {
	name: "Single Image Content Display",
	inputs: [
		{
			name: "marginTB",
			friendlyName: "Add Top and Bottom Margins?",
			type: "boolean",
			defaultValue: false,
		},
		{
			name: "marginTOnly",
			friendlyName: "Add Top Margin?",
			type: "boolean",
			defaultValue: false,
		},
		{
			name: "marginBOnly",
			friendlyName: "Add Bottom Margin?",
			type: "boolean",
			defaultValue: false,
		},
		{
			name: "mainImage",
			friendlyName: "Main Image",
			type: "object",
			defaultValue: {
				imageLocation: true,
				imageCurveBottom: true,
				imageCurveTop: false,
				image: "https://placehold.co/632x632",
				altText: "alt text",
			},
			subFields: [
				{
					name: "imageLocation",
					friendlyName: "Show Image on Left",
					type: "boolean",
					helperText: "Enbled = Display Left | Disabled = Display Right",
				},
				{
					name: "imageCurveBottom",
					friendlyName: "Add Bottom Image Curve?",
					type: "boolean",
					helperText: "Toggle to add a curve to the bottom of the image",
				},
				{
					name: "imageCurveTop",
					friendlyName: "Add Top Image Curve?",
					type: "boolean",
					helperText: "Toggle to add a curve to the top of the image",
				},
				{
					name: "image",
					friendlyName: "Image",
					type: "file",
					allowedFileTypes: ["jpeg", "jpg", "png"],
					helperText: "632×632 px | .jpeg, .jpg, .png | ~150 KB",
					required: true,
				},
				{
					name: "altText",
					friendlyName: "Alt Text",
					type: "string",
					required: true,
				},
			],
		},
		{
			name: "logo",
			friendlyName: "Logo",
			type: "object",
			defaultValue: {
				showLogo: false,
				logo: "https://placehold.co/80x80",
				logoAlt: "this is the logo alt text",
			},
			subFields: [
				{
					name: "showLogo",
					friendlyName: "Add Logo",
					type: "boolean",
				},
				{
					name: "logo",
					friendlyName: "Logo",
					type: "file",
					allowedFileTypes: ["png", "svg"],
					showIf: `options.get('showLogo')`,
					helperText: "~80×80 px | .svg, .png | ~100 KB",
				},
				{
					name: "logoAlt",
					friendlyName: "Logo Alt Text",
					type: "string",
					showIf: `options.get('showLogo')`,
				},
			],
		},

		{
			name: "title",
			helperText:
				"Please leave blank if you use 'H3' in the Description editor | ~25 characters",
			friendlyName: "Title",
			type: "string",
			defaultValue: "Title",
		},
		{
			name: "description",
			helperText:
				"To add a title, please use 'H3'. For footnotes, 'H5' | ~125 characters",
			friendlyName: "Description",
			type: "richText",
			defaultValue: "description text",
		},
		{
			name: "primaryButton",
			friendlyName: "Primary CTA Button",
			type: "object",
			defaultValue: {
				showButton: false,
				buttonText: "Meet the Team",
				buttonType: "primary",
				buttonLink: "#",
				alternativeAria: false,
				ariaLabel: "aria label",
			},
			subFields: [
				{
					name: "showButton",
					friendlyName: "Add Button",
					type: "boolean",
					helperText: "Enabled = Display Button",
				},
				{
					name: "buttonText",
					friendlyName: "Button Text",
					type: "string",
					showIf: `options.get('showButton')`,
					helperText: "~15 characters",
				},
				{
					name: "buttonType",
					friendlyName: "Button Type",
					type: "string",
					enum: ["primary", "secondary"],
					showIf: `options.get('showButton')`,
				},
				{
					name: "buttonLink",
					friendlyName: "Button Link",
					type: "string",
					showIf: `options.get('showButton')`,
				},
				{
					name: "openInNewTab",
					friendlyName: "Open in new tab?",
					type: "boolean",
					showIf: `options.get('showButton')`,
				},
				{
					name: "alternativeAria",
					friendlyName: "Add Custom Link Aria Label?",
					type: "boolean",
					defaultValue: false,
					showIf: `options.get('showButton')`,
				},
				{
					name: "ariaLabel",
					friendlyName: "Aria Label",
					type: "long-text",
					showIf: `options.get('alternativeAria')`,
				},
			],
		},
		{
			name: "secondaryButton",
			friendlyName: "Secondary CTA Button",
			type: "object",
			defaultValue: {
				showButton: false,
				buttonText: "Meet the Team",
				buttonType: "secondary",
				buttonLink: "#",
				alternativeAria: false,
				ariaLabel: "aria label",
			},
			subFields: [
				{
					name: "showButton",
					friendlyName: "Add Button",
					type: "boolean",
					helperText: "Enabled = Display Button",
				},
				{
					name: "buttonText",
					friendlyName: "Button Text",
					type: "string",
					showIf: `options.get('showButton')`,
					helperText: "~15 characters",
				},
				{
					name: "buttonType",
					friendlyName: "Button Type",
					type: "string",
					enum: ["primary", "secondary"],
					showIf: `options.get('showButton')`,
				},
				{
					name: "buttonLink",
					friendlyName: "Button Link",
					type: "string",
					showIf: `options.get('showButton')`,
				},
				{
					name: "openInNewTab",
					friendlyName: "Open in new tab?",
					type: "boolean",
					showIf: `options.get('showButton')`,
				},
				{
					name: "alternativeAria",
					friendlyName: "Add Custom Link Aria Label?",
					type: "boolean",
					defaultValue: false,
					showIf: `options.get('showButton')`,
				},
				{
					name: "ariaLabel",
					friendlyName: "Aria Label",
					type: "long-text",
					showIf: `options.get('alternativeAria')`,
				},
			],
		},
	],
});
