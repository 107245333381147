import clsx from "clsx";
import { InputHTMLAttributes, forwardRef } from "react";
import styles from "./TextInput.module.scss";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	errorMessage?: string;
}

const TextInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
	const { errorMessage = false, className, ...rest } = props;

	return (
		<input
			{...rest}
			className={clsx([
				styles.input,
				errorMessage ? styles.error : null,
				className,
			])}
			ref={ref}
		/>
	);
});

export default TextInput;
