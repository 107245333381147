"use client";

import { Builder } from "@builder.io/react";
import React from "react";
import styles from "./ShopByBrandCarousel.module.scss";

import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import IconArrow from "../UI/Icon/IconArrow/IconArrow";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

// import required modules
import { A11y, Navigation, Scrollbar } from "swiper/modules";
import SwiperNavigationButtons from "../SwiperNavigationButton/SwiperNavigationButton";

type PropType = {
	title: string;
	link: {
		text: string;
		href: string;
	};
	toggleBackground: boolean;
	slides: [
		{
			brand: string;
			link: string;
			alternativeAria: boolean;
			ariaLabel: string;
			alt: string;
		},
	];
};

const ShopByBrandCarousel: React.FC<PropType> = ({
	slides,
	title,
	link,
	toggleBackground,
}) => {
	const carouselId = "brands-carousel";

	return (
		<section
			className={`${styles.brandCarousel} ${
				toggleBackground ? styles.toggleBackground : styles.background
			}`}
		>
			<div className={styles.brandCarouselWrapper}>
				<div className={styles.heading}>
					{title && <h3>{title}</h3>}
					{link?.href && link?.text && (
						<Link href={link.href}>
							{link.text}
							<IconArrow
								direction={"right"}
								aria-labelledby='directional chevron pointing right'
							/>
						</Link>
					)}
				</div>

				<Swiper
					modules={[Navigation, Scrollbar, A11y]}
					navigation={{
						enabled: true,
						nextEl: `[data-button-ref="${carouselId}-button-next"]`,
						prevEl: `[data-button-ref="${carouselId}-button-previous"]`,
					}}
					scrollbar={{ enabled: true, draggable: true }}
					slidesPerView={"auto"}
					spaceBetween={8}
				>
					{slides
						?.filter((slide) => slide.brand !== undefined)
						.map((slide, index) => (
							<SwiperSlide key={index}>
								{slide.link ? (
									<a
										href={slide.link}
										aria-label={
											slide.alternativeAria ? slide.ariaLabel : slide.alt
										}
									>
										<img src={`${slide.brand}?format=webp`} alt={slide.alt} />
									</a>
								) : (
									<img src={`${slide.brand}?format=webp`} alt={slide.alt} />
								)}
							</SwiperSlide>
						))}

					<SwiperNavigationButtons buttonIdentifier={carouselId} />
				</Swiper>
			</div>
		</section>
	);
};

export default ShopByBrandCarousel;

Builder.registerComponent(ShopByBrandCarousel, {
	name: "ShopByBrandCarousel",
	inputs: [
		{
			name: "toggleBackground",
			friendlyName: "Toggle Background",
			type: "boolean",
			defaultValue: true,
		},
		{
			name: "title",
			friendlyName: "Title",
			type: "string",
			defaultValue: "Shop by Brand",
		},
		{
			name: "link",
			friendlyName: "Link",
			type: "object",
			subFields: [
				{
					name: "text",
					friendlyName: "Text",
					type: "longText",
					defaultValue: "Explore Brands",
					helperText: "~25 characters",
				},
				{
					name: "href",
					friendlyName: "URL",
					type: "url",
					defaultValue: "#",
				},
			],
		},

		{
			name: "slides",
			type: "list",
			defaultValue: [
				{
					brand: "https://placehold.co/80x80",
					link: "#",
					alt: "This is the alt text",
				},
			],
			subFields: [
				{
					name: "brand",
					friendlyName: "Brand Logo",
					type: "file",
					allowedFileTypes: ["svg", "png"],
					required: true,
					defaultValue: "https://placehold.co/80x80",
					helperText: "~125×125 px | .svg, .png | ~100 KB",
				},
				{
					name: "link",
					type: "string",
					friendlyName: "URL Link",
				},
				{
					name: "alternativeAria",
					friendlyName: "Add Custom Link Aria Label?",
					type: "boolean",
					defaultValue: false,
				},
				{
					name: "ariaLabel",
					friendlyName: "Aria Label",
					type: "long-text",
					showIf: `options.get('alternativeAria')`,
				},
				{
					name: "alt",
					type: "string",
					friendlyName: "Alt text",
				},
			],
		},
	],
});
