import { FC, SVGProps } from "react";
import Icon from "../Icon";
import styles from "./IconMiniLogo.module.scss";

interface Props extends SVGProps<typeof Icon> {}

const IconMiniLogo: FC<Props> = ({ ...props }) => {
	return <Icon icon={<SVG className={styles.icon} {...props} />} />;
};

export default IconMiniLogo;

const SVG = (props: any) => {
	return (
		<svg
			className={props.className}
			viewBox='0 0 96 96'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g id='Frame_2' className='rotating'>
				<g id='Group'>
					<path
						id='Vector'
						d='M59.3822 93.0108L52.5131 67.0463C51.3084 62.4924 48.3669 58.8595 44.587 56.6683C40.8115 54.4694 36.1951 53.7109 31.6349 54.9151L5.63438 61.7808C5.2924 61.8711 5.08846 62.2211 5.1788 62.5626L7.36777 70.838C7.44717 71.1381 7.75517 71.3171 8.05569 71.2377L35.3668 64.0251L35.7065 63.9611C37.2186 63.5618 38.7489 63.8099 40.0036 64.5337C41.2537 65.2652 42.2258 66.4712 42.6253 67.9812L42.7092 68.2012L49.9521 95.5815C50.0315 95.8816 50.3395 96.0605 50.64 95.9812L58.9267 93.7926C59.2686 93.7023 59.4726 93.3523 59.3822 93.0108Z'
						fill='#EAE8E7'
					></path>
					<path
						id='Vector_2'
						d='M5.67372 33.7644L31.6033 40.8924C36.1511 42.1425 40.775 41.4307 44.5725 39.27C48.3745 37.1172 51.3526 33.5141 52.6034 28.9726L59.7351 3.07878C59.8289 2.7382 59.6286 2.38619 59.2875 2.29242L51.0234 0.0202917C50.7237 -0.062089 50.4139 0.113756 50.3315 0.413043L42.841 27.6125L42.7255 27.9379C42.3108 29.4438 41.3265 30.6399 40.069 31.3588C38.8071 32.0699 37.2744 32.3025 35.7664 31.888L35.5338 31.8498L8.19069 24.3324C7.89099 24.25 7.58119 24.4259 7.49876 24.7252L5.22607 32.978C5.13227 33.3186 5.33267 33.6706 5.67372 33.7644Z'
						fill='#EAE8E7'
					></path>
					<path
						id='Vector_3'
						d='M83.7098 17.1313L64.6903 36.1184C61.3545 39.4485 59.6892 43.8143 59.6937 48.1787C59.6892 52.5431 61.3545 56.9089 64.6903 60.239L83.7098 79.2261C83.96 79.4758 84.3655 79.4758 84.6157 79.2261L90.6777 73.1749C90.8976 72.9554 90.8976 72.5996 90.6777 72.3801L70.6988 52.4363L70.4725 52.1753C69.3664 51.0711 68.8115 49.6256 68.807 48.1787C68.8115 46.7318 69.3664 45.2863 70.4725 44.1821L70.6207 43.999L90.6777 23.9773C90.8975 23.7578 90.8975 23.402 90.6777 23.1825L84.6156 17.1313C84.3655 16.8815 83.96 16.8815 83.7098 17.1313Z'
						fill='#EAE8E7'
					></path>
				</g>
			</g>
		</svg>
	);
};
