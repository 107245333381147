"use client";

import { FC, useEffect, useState } from "react";
import IconArrow from "../Icon/IconArrow/IconArrow";
import styles from "./ScrollToTop.module.scss";

const MIN_SCROLL_DEPTH = 100;

const ScrollToTop: FC = () => {
	const [show, setShow] = useState<boolean>(false);

	const handleScroll = () => {
		if (window.scrollY >= MIN_SCROLL_DEPTH) {
			setShow(true);
		} else {
			setShow(false);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	return (
		<button
			title='Scroll To Top'
			aria-label='Scroll To Top'
			className={`${show ? styles.Show : ""} ${styles.ScrollButton}`}
			onClick={() => scrollToTop()}
			onKeyDown={(e: any) => {
				if (e.key === "Enter") scrollToTop();
			}}
			tabIndex={0}
			type='button'
		>
			<IconArrow
				direction='up'
				aria-labelledby='directional chevron pointing up'
			/>
		</button>
	);
};

export default ScrollToTop;
