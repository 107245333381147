"use client";

import { useState, useRef } from "react";
import { useI18n } from "@/i18n/client";
import { updateWishlistData } from "@/lib/bigcommerce-b2b/api/account/wishlist";
import { WishlistDetailsData } from "@/lib/bigcommerce-b2b/types";
import Button from "@/components/UI/Button/Button";
import TextInput from "@/components/UI/Input/TextInput/TextInput";
import styles from "./WishlistEditModal.module.scss";
import fieldStyles from "../../../../Form/FormField/FormField.module.scss";

interface WishlistEditModalProps {
	createNewWishlist: boolean;
	wishlistData?: WishlistDetailsData;
	handleUpdateWishlist: any;
	handleModalClose?: any;
}

const WishlistEditModal: React.FC<WishlistEditModalProps> = ({
	createNewWishlist,
	wishlistData,
	handleUpdateWishlist,
	handleModalClose,
}) => {
	const t = useI18n();
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
	const wishlistName = useRef<HTMLInputElement | null>(null);

	const handleCreateNewWishlist = async () => {
		setShowLoadingSpinner(true);

		if (createNewWishlist) {
			// Create a new wishlist
			handleUpdateWishlist(wishlistName?.current?.value);
		} else if (wishlistData) {
			// Update the current wishlist
			const updatedWishlistData: WishlistDetailsData = {
				...wishlistData,
				name: wishlistName?.current?.value ?? "",
				// ...data,
			};

			const res = await updateWishlistData(updatedWishlistData);

			if (res) {
				handleUpdateWishlist(updatedWishlistData);
				handleModalClose(false);
				setShowLoadingSpinner(false);
			}
		}
	};

	return (
		<div className={styles.form}>
			<div className={fieldStyles.formField}>
				<label className={fieldStyles.label}>
					{t("account.wishlist.listName")}
				</label>
				<TextInput
					ref={wishlistName}
					className={styles.input}
					defaultValue={wishlistData?.name}
				/>
			</div>
			<Button
				as='button'
				isLoading={showLoadingSpinner}
				onClick={() => handleCreateNewWishlist()}
			>
				{t("form.save")}
			</Button>
		</div>
	);
};
export default WishlistEditModal;
