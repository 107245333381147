"use client";
import { Builder } from "@builder.io/react";
import React from "react";
import RotateIcon from "@/app/assets/images/svg/rotate.svg";
import CompressedImage from "../UI/CompressedImage/CompressedImage";
import styles from "./UspBlock.module.scss";

interface UspBlockProps {
	title: string;
	usps: [
		{
			imageSrc: string;
			altText: string;
			frontTitle: string;
			backTitle: string;
			backText: string;
		},
	];
}

const UspBlock: React.FC<UspBlockProps> = ({ title, usps }) => {
	return (
		<section className={styles.contentSection}>
			<h2 className={styles.title}>{title}</h2>
			<div className={styles.uspsWrapper}>
				{usps?.map((usp, index) => (
					<div key={`${index}-${usp.imageSrc}`} className={styles.card}>
						<div className={styles.front}>
							<RotateIcon className={styles.icon} />
							<CompressedImage
								src={`${usp.imageSrc}/format=webp`}
								altText={usp.altText}
								height={300}
								width={300}
								containerClass={styles.image}
							/>
							<h4>{usp.frontTitle}</h4>
						</div>
						<div className={styles.back}>
							<RotateIcon className={styles.icon} />
							<div className={styles.body}>
								<h4>{usp.backTitle}</h4>
								{usp.backText && <p>{usp.backText}</p>}
							</div>
						</div>
					</div>
				))}
			</div>
		</section>
	);
};

export default UspBlock;

Builder.registerComponent(UspBlock, {
	name: "USP Block",
	inputs: [
		{
			name: "title",
			friendlyName: "Title",
			type: "string",
			defaultValue: "Title",
		},
		{
			name: "usps",
			friendlyName: "USPs",
			type: "list",
			defaultValue: [
				{
					imageSrc: "https://placehold.co/240x300/FFFFFF/212323/png",
					altText: "Alt text",
					frontTitle: "Title...",
					backTitle: "Title...",
					backText: "Text...",
				},
			],
			subFields: [
				{
					name: "imageSrc",
					type: "file",
					friendlyName: "Image",
					defaultValue: "https://placehold.co/240x300/FFFFFF/212323/png",
					helperText: "240×300 px | .jpeg, .jpg, .png | ~25 KB.",
					allowedFileTypes: ["jpeg", "jpg", "png"],
				},
				{
					name: "altText",
					type: "string",
					friendlyName: "Alt text",
					defaultValue: "Alt text",
					required: true,
				},
				{
					name: "frontTitle",
					type: "longText",
					friendlyName: "Card front title",
					defaultValue: "Title...",
					helperText: "Displays on the front face of the card | ~15 characters",
					required: true,
				},
				{
					name: "backTitle",
					type: "longtext",
					friendlyName: "Card back title",
					defaultValue: "Title...",
					helperText:
						"Displays on the back face of the card when rotated | ~20 characters",
					required: true,
				},
				{
					name: "backText",
					type: "longtext",
					friendlyName: "Card back paragraph",
					defaultValue: "Text...",
					helperText:
						"Leave empty if not required. Displays on the back face of the card | ~100 characters",
					required: false,
				},
			],
		},
	],
});
