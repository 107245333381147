import { Builder } from "@builder.io/react";
import React, { FC } from "react";
import Markdown from "../UI/Markdown/Markdown";
import styles from "./HeaderLeftContentDisplay.module.scss";

interface HeaderLeftContentDisplayProps {
	title: string;
	mainContent: string;
}

const HeaderLeftContentDisplay: FC<HeaderLeftContentDisplayProps> = ({
	title,
	mainContent,
}) => {
	return (
		<aside className={styles.headerLeftContentMainWrapper}>
			<div className={styles.headerLeftContent}>
				<div className={styles.titleWrapper}>
					<h3>{title}</h3>
				</div>
				<div className={styles.mainContentWrapper}>
					<Markdown
						content={mainContent}
						className={styles.customMarkdownStyle}
					/>
				</div>
			</div>
		</aside>
	);
};

export default HeaderLeftContentDisplay;

Builder.registerComponent(HeaderLeftContentDisplay, {
	name: "Header Left Content Display",
	inputs: [
		{
			name: "title",
			friendlyName: "Title",
			type: "longText",
			defaultValue: "Title",
			helperText: "~30 characters",
		},
		{
			name: "mainContent",
			friendlyName: "Main Content",
			type: "richText",
			defaultValue: "nulla ex culpa anim eu duis aliqua in in eiusmod",
			helperText: "~1,700 characters",
		},
	],
});
