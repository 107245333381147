import {
	ChangeType,
	getCartDatalayerDetails,
	getItemListData,
	useDataLayerContext,
} from "@/datalayer/DataLayerContextProvider";
import { Cart, ProductData } from "@/lib/5874/types";
import { useProductContext } from "@/providers/ProductContextProvider";

export function useFireDataLayerCartEvents() {
	const { addToCart, removeFromCart } = useDataLayerContext();
	const { product } = useProductContext();

	const fireCartEvent = (
		changeType: ChangeType,
		quantity: number,
		cart: Cart | undefined,
		prod?: ProductData | undefined,
	) => {
		const { itemListId, itemListName } = getItemListData(product);
		const cartDetails = getCartDatalayerDetails(cart);

		if (
			changeType === "none" ||
			(prod === undefined && product === undefined)
		) {
			return;
		}

		const datalayerProduct = {
			product: prod ?? product,
			quantity,
			variantId: prod?.id ?? product.id,
		};

		if (changeType === "increase") {
			addToCart({
				products: [datalayerProduct],
				itemListId,
				itemListName,
				cart: cartDetails,
			});
		} else if (changeType === "decrease") {
			removeFromCart({
				products: [datalayerProduct],
				itemListId,
				itemListName,
				cart: cartDetails,
			});
		}
	};

	return { fireCartEvent };
}
