"use client";
import UpToDateBanner from "@/components/UpToDateBanner/UpToDateBanner";
import useMediaQuery from "@/hooks/useMediaQuery";
import * as Accordion from "@radix-ui/react-accordion";
import { FC, useState } from "react";
import styles from "./Footer.module.scss";
import FooterHeader from "./FooterHeader/FooterHeader";
import FooterHorizontalLinks from "./FooterHorizontalLinks/FooterHorizontalLinks";
import FooterLinksList from "./FooterLinksList/FooterLinksList";
import FooterSocialLinks from "./FooterSocialLiniks/FooterSocialLinks";

type LinkList = {
	text: string;
	link: string;
	ariaLabel: string;
};

export type EmailSignupSettings = {
	region: string;
	portalId: string;
	formId: string;
};

type Socials = {
	image: string;
	link: string;
	altText: string;
	ariaLabel: string;
};

interface Props {
	content: {
		upToDateTitle: string;
		upToDateDescription: string;
		upToDatePlaceholder: string;
		newsletterSignUpLink: string;
		hubspotEmailSignupSettings: EmailSignupSettings;
		logo: string;
		logoAltText: string;
		emailAddress: string;
		phoneNumber: string;
		openingHours: string;
		address: string;
		linkListOne: LinkList[];
		linkListTwo: LinkList[];
		linkListThree: LinkList[];
		socials: Socials[];
		horizontalLinkList: LinkList[];
	};
	site: "corp" | "ecom";
}

const Footer: FC<Props> = ({ content, site }) => {
	const { isTablet } = useMediaQuery();

	const isECom: boolean = site === "ecom";

	const accordionData = isECom
		? [content.linkListOne, content.linkListTwo, content.linkListThree]
		: [content.linkListOne, content.linkListTwo];

	const [activeAccordions, setActiveAccordions] = useState<number[]>([]);

	// function only runs on the ecom site
	const toggleColumn = (index: number) => {
		if (isTablet) {
			return;
		}

		const columnList = [...activeAccordions];
		if (columnList.includes(index)) {
			columnList.splice(columnList.indexOf(index), 1);
		} else {
			columnList.push(index);
		}

		setActiveAccordions(columnList);
	};

	if (!content) {
		return null;
	}

	const headerData = {
		logo: content.logo,
		logoAltText: content.logoAltText,
		emailAddress: content.emailAddress,
		phoneNumber: content.phoneNumber,
		openingHours: content.openingHours,
		address: content.address,
	};

	return (
		<footer className={styles.footer}>
			<UpToDateBanner signUpLink={content.newsletterSignUpLink} />
			<div className={styles.footerContainer}>
				<div
					className={` ${styles.footerHeaderLinks} ${
						isECom ? "" : styles.isECom
					}`}
				>
					<section className={styles.footerMainContent}>
						{headerData && (
							<FooterHeader headerData={headerData} isECom={isECom} />
						)}
						{accordionData && (
							<Accordion.Root
								type='multiple'
								className={`${styles.footerLinkBlocksWrapper} ${
									isECom ? styles.isECom : ""
								}`}
								value={
									isECom
										? isTablet
											? accordionData.map((_, index: number) => String(index))
											: activeAccordions.map(String)
										: undefined
								}
								disabled={!isECom}
								defaultValue={
									isECom
										? undefined
										: accordionData.map((_, index: number) => String(index))
								}
							>
								{accordionData.map((data: LinkList[], index: number) => (
									<FooterLinksList
										key={index}
										isECom={isECom}
										column={String(index)}
										linkDataArray={data}
										toggleColumn={toggleColumn}
										isDisabled={isTablet ? true : undefined}
									/>
								))}
							</Accordion.Root>
						)}
					</section>
					{content.socials && (
						<FooterSocialLinks isECom={isECom} socials={content.socials} />
					)}
				</div>
				{content.horizontalLinkList && (
					<FooterHorizontalLinks
						isECom={isECom}
						horizontalLinkList={content.horizontalLinkList}
					/>
				)}
			</div>
		</footer>
	);
};

export default Footer;
