"use client";

import Button from "../UI/Button/Button";
import { FC } from "react";
import styles from "./UpToDateBanner.module.scss";

const UpToDateBanner: FC<{ signUpLink: string }> = ({ signUpLink }) => {
	return (
		<div className={styles.Banner}>
			<div className={styles.BannerInner}>
				<h3>Keep Up-to-date</h3>
				<p>
					Don't miss any fantastic offers or important news by joining our
					newsletter.
				</p>
				<Button
					as='link'
					target='_blank'
					variant='primary'
					href={signUpLink ?? "https://news.movora.com/subscribe"}
				>
					Sign Up
				</Button>
			</div>
		</div>
	);
};

export default UpToDateBanner;
