"use client";

import { useEffect, useState } from "react";
import { Builder } from "@builder.io/react";
import { builder } from "@builder.io/sdk";
import { useI18n } from "@/i18n/client";
import Link from "next/link";
import { ArticleEntryType } from "@/lib/builder/server-quries";
import NewsCard from "../NewsCard/NewsCard";
import Chevron from "../../../app/assets/images/svg/chevron.svg";
import styles from "./FeaturedArticles.module.scss";

interface Props {
	title?: string;
	articleType: ArticleEntryType;
	link: {
		showLink: boolean;
		text?: string;
		btnLink?: string;
		ariaLabel?: string;
	};
	tags: string[];
	articleIds: string[];
}

const FeaturedArticles = ({
	title,
	articleType,
	link = { showLink: false },
	tags = [],
	articleIds = [],
}: Props) => {
	const { showLink, btnLink = "/", text, ariaLabel } = link;

	const [articles, setArticles] = useState<any[]>([]);
	const t = useI18n();

	useEffect(() => {
		const getAllArticles = async () => {
			const limit = 100;
			let offset = 0;
			const articles = [];

			const tagsOnly = tags.length > 0 && articleIds.length === 0;

			/* Continue to make api calls until all blogs are returned */
			while (true) {
				const options = {
					limit,
					options: {
						offset,
						noTargeting: true,
					},
					query: {
						...(tagsOnly && {
							"data.tags": {
								$regex: tags.join("|"),
								$options: "i",
							},
						}),
						...(articleIds && {
							id: {
								$regex: articleIds.join("|"),
								$options: "i",
							},
						}),
					},
				};

				const content = await builder.getAll(articleType, options);

				if (content.length === 0) {
					break;
				}

				articles.push(...content);

				if (content.length < limit) {
					/* If the fetched batch is < the limit, then no more articles to fetch */
					break;
				}

				/* Increment the offset for the next batch */
				offset += limit;
			}

			return articles;
		};

		const getFeaturedArticles = async () => {
			const sortedArticles = (await getAllArticles()).sort((a: any, b: any) => {
				const dateA = new Date(a.data.date);
				const dateB = new Date(b.data.date);
				return dateB.getTime() - dateA.getTime();
			});

			setArticles(sortedArticles.slice(0, 3));
		};

		getFeaturedArticles();
	}, [tags]);

	if (articles.length === 0)
		return (
			<div className={styles.noArticlesFoundWrapper}>
				<h2>{t("search.noResults.noArticlesFound")}</h2>
			</div>
		);

	return (
		<div className={styles.featuredArticlesWrapper}>
			<div className={styles.outer}>
				{(title || showLink) && (
					<div className={styles.header}>
						{title && <h2 className={styles.title}>{title}</h2>}
						{showLink && (
							<Link
								className={styles.link}
								href={btnLink}
								aria-label={ariaLabel ? ariaLabel : text}
							>
								{text} <Chevron aria-labelledby='directional chevron' />
							</Link>
						)}
					</div>
				)}

				<div className={styles.articles}>
					{articles.map((article) => (
						<NewsCard
							key={article.id}
							title={article.data?.title}
							link={article.data?.url}
							date={
								article.data?.date ? new Date(article.data?.date) : undefined
							}
							image={article.data?.featuredImage}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default FeaturedArticles;

Builder.registerComponent(FeaturedArticles, {
	name: "Featured Articles",
	inputs: [
		{
			name: "title",
			type: "string",
			defaultValue: "This is a title",
		},
		{
			name: "articleType",
			type: "string",
			enum: ["blogs", "news"],
			defaultValue: "news",
		},
		{
			name: "link",
			type: "object",
			subFields: [
				{
					name: "showLink",
					type: "boolean",
					friendlyName: "Show Link?",
					defaultValue: false,
					helperText: "Enabled to display a link top right",
				},
				{
					name: "text",
					type: "string",
					defaultValue: "Explore News",
					showIf: `options.get('showLink')`,
					helperText: "~25 characters",
				},
				{
					name: "btnLink",
					friendlyName: "Link",
					type: "string",
					defaultValue: "/",
					showIf: `options.get('showLink')`,
				},
				{
					name: "ariaLabel",
					type: "string",
					friendlyName: "Aria-label",
					defaultValue: "Go to here",
					showIf: `options.get('showLink')`,
				},
			],
		},
		{
			name: "tags",
			type: "Tags",
			helperText: "Enter Tags of Articles",
		},
		{
			name: "articleIds",
			friendlyName: "Article IDs",
			type: "Tags",
			helperText: "Enter Article Builder.io IDs",
		},
	],
});
