import React, { FC } from "react";
import styles from "./HeaderDropdown.module.scss";
import { subMenuItem } from "../UI/Layout/Header/headerTypes";
import Link from "next/link";
import clsx from "clsx";
import HeaderBurgerButton from "../UI/Layout/Header/HeaderBurgerButton/HeaderBurgerButton";
import Chevron from "@/app/assets/images/svg/chevron.svg";
import Button from "@/components/UI/Button/Button";

interface HeaderDropdownProps {
	submenuItems: subMenuItem[];
	setShowSubmenu: (state: boolean) => void;
	showSubmenu: boolean;
	setOpenMobileMenu: (state: boolean) => void;
}

const HeaderDropdown: FC<HeaderDropdownProps> = ({
	submenuItems,
	setShowSubmenu,
	showSubmenu,
	setOpenMobileMenu,
}) => {
	const handleCloseAllSubMenus = () => {
		setOpenMobileMenu(false);
		setShowSubmenu(false);
	};

	return (
		<div
			className={clsx(styles.headerDropdown, {
				[styles.mobileMenu]: showSubmenu,
			})}
		>
			<div className={styles.navHeader}>
				<Button
					as='button'
					variant='empty'
					className={styles.navHeaderBackButton}
					onClick={() => setShowSubmenu(false)}
				>
					<Chevron aria-labelledby='directional chevron' />
					<span>Back</span>
				</Button>
				<HeaderBurgerButton
					isOpen
					ariaLabel='Close all menus'
					onButtonClick={() => handleCloseAllSubMenus()}
				/>
			</div>

			<ul>
				{submenuItems?.map((menuItem) => (
					<li key={menuItem?.link}>
						<Link
							href={menuItem.link}
							aria-label={menuItem.ariaLabel}
							target={menuItem.openInNewTab ? "_blank" : "_parent"}
							onClick={() => handleCloseAllSubMenus()}
						>
							{menuItem?.text}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
};

export default HeaderDropdown;
