"use client";
import DOMPurify from "isomorphic-dompurify";
import React from "react";
import styles from "./Markdown.module.scss";

type Props<C extends React.ElementType> = {
	component?: C;
	className?: string;
	content?: string;
	renderEmpty?: boolean;
} & React.ComponentPropsWithoutRef<C>;

const Markdown = <C extends React.ElementType = "div">({
	component,
	className = "",
	content,
	renderEmpty = false,
	...rest
}: Props<C>) => {
	// remove empty lines that can get left
	if (content === "<p><br></p>") {
		content = "";
	}

	content = content ? content.replace(/<p>&nbsp;<\/p>/g, "") : "";

	if (!renderEmpty && !content) {
		return null;
	}

	const Component = component || "div";

	return (
		<Component
			{...rest}
			className={`${className} ${!content ? "no-content" : ""} ${
				styles.markdown
			}`}
			// biome-ignore lint: required for component to work
			dangerouslySetInnerHTML={{
				__html: DOMPurify.sanitize(content || ""),
			}}
		/>
	);
};

export default Markdown;
