import { Builder } from "@builder.io/react";
import Button from "./Button";

interface Props {
	children: string;
	href?: string;
	target?: string;
}

const BuilderButton = ({ children, href, target }: Props) => {
	if (href) {
		return (
			<Button
				as='link'
				href={href}
				target={target === "Current Tab/Window" ? "_self" : "_blank"}
			>
				{children}
			</Button>
		);
	}

	return <Button as='button'>{children}</Button>;
};

Builder.registerComponent(BuilderButton, {
	override: true,
	name: "Core:Button",
	defaultStyles: {
		width: "fit-content",
	},
	inputs: [
		{
			name: "children",
			friendlyName: "Text",
			type: "text",
			defaultValue: "Button",
		},
		{
			name: "href",
			friendlyName: "Link",
			defaultValue: "#",
			type: "url",
		},
		{
			name: "target",
			type: "text",
			enum: ["Current Tab/Window", "New Tab/Window"],
			defaultValue: "Current Tab/Window",
			friendlyName: "Where should the link open?",
			showIf: `options.get('href') !== ''`,
		},
	],
});
