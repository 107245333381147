"use client";

import { Builder } from "@builder.io/react";
import React from "react";
import Markdown from "../UI/Markdown/Markdown";
import styles from "./SolutionsProductGrid.module.scss";
import { hashString } from "@/lib/5874/util-functions/hashString";
import clsx from "clsx";

interface SolutionsProductGridProps {
	solutions: {
		backgroundImage: string;
		addFilter: boolean;
		title: string;
		description: string;
	}[];
}

const SolutionsProductGrid: React.FC<SolutionsProductGridProps> = ({
	solutions = [],
}) => {
	return (
		<section className={styles.solutionsWrapper}>
			{solutions?.map((solution) => (
				<div
					key={hashString(solution.title)}
					className={clsx(styles.solution, {
						[styles.backgroundColor]: solution.addFilter,
					})}
					style={{
						backgroundImage: `url(${solution.backgroundImage}?format=webp)`,
					}}
				>
					<div className={styles.solutionText}>
						{solution.title && <h2>{solution.title}</h2>}
						<Markdown
							className={styles.markdown}
							content={solution.description}
						/>
					</div>
				</div>
			))}
		</section>
	);
};

export default SolutionsProductGrid;

Builder.registerComponent(SolutionsProductGrid, {
	name: "Solutions Product Grid",
	inputs: [
		{
			name: "solutions",
			type: "list",
			defaultValue: [
				{
					backgroundImage: "https://placehold.co/715x340",
					title: "This is a title",
					description: "This is the description",
					addFilter: true,
				},
			],
			subFields: [
				{
					name: "backgroundImage",
					friendlyName: "Background Image",
					type: "file",
					allowedFileTypes: ["jpeg", "jpg", "png"],
					defaultValue: "https://placehold.co/632x632",
					helperText: "632×632 px | .jpeg, .jpg, .png | ~150 KB",
				},
				{
					name: "addFilter",
					friendlyName: "Add Blue Filter?",
					type: "boolean",
				},
				{
					name: "title",
					type: "longText",
					defaultValue: "Title",
					helperText: "~25 characters",
				},
				{
					name: "description",
					type: "richText",
					defaultValue: "description text",
					helperText: "~125 characters",
				},
			],
		},
	],
});
