import Icon from "@/components/UI/Icon/Icon";
import clsx from "clsx";
import Link from "next/link";
import { ButtonHTMLAttributes, FC, ReactElement } from "react";
import IconMiniLogo from "../../Icon/IconMiniLogo/IconMiniLogo";
import styles from "./IconOnlyButton.module.scss";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: "primary" | "secondary" | "tertiary" | "rounded" | "empty";
	icon: ReactElement<typeof Icon>;
	ariaLabel?: string;
	as?: "button" | "link";
	href?: string;
	isLoading?: boolean;
}

const IconOnlyButton: FC<Props> = ({
	variant = "primary",
	icon,
	as,
	isLoading,
	children,
	href = "#",
	ariaLabel = "",
	className,
	...props
}) => {
	// If button is link render a link tag
	if (as === "link")
		return (
			<Link
				className={clsx([styles.btn, styles[variant], className])}
				href={href}
				aria-label={ariaLabel}
				// {...props}
			>
				{!isLoading ? icon : <IconMiniLogo />}
			</Link>
		);

	// Otherwise return a button element
	return (
		<button
			className={clsx([styles.btn, styles[variant], className])}
			type='button'
			aria-label={ariaLabel}
			disabled={isLoading}
			{...props}
		>
			{!isLoading ? icon : <IconMiniLogo />}
		</button>
	);
};

export default IconOnlyButton;
