import { Builder } from "@builder.io/react";

/**
 * Hook that returns the edit/preview status of builder.
 *
 * isEditingBuilder is only true in visual editor.
 * isPreviewingBuilder is true is visual editor and draft preview.
 *
 * @returns isEditingBuilder, isPreviewingBuilder
 */
const useIsPreviewingBuilder = () => {
	// is true when loaded in visual editor, otherwise false.
	const isEditing = Builder.isEditing;

	//is true in visual editor and draft preview
	const isPreviewing = Builder.isPreviewing;

	return { isEditingBuilder: isEditing, isPreviewingBuilder: isPreviewing };
};

export default useIsPreviewingBuilder;
