"use client";

import { Builder } from "@builder.io/react";
import clsx from "clsx";
import React from "react";
import Button from "../UI/Button/Button";
import Markdown from "../UI/Markdown/Markdown";
import styles from "./BrandsContentDisplay.module.scss";

interface BrandsContentDisplayProps {
	description: string;
	imagePosition: boolean;
	button: {
		buttonText: string;
		buttonLink: string;
		alternativeAria: boolean;
		ariaLabel: string;
	};
	brands: [
		{
			logoSrc: string;
			altText: string;
		},
	];
}

const BrandsContentDisplay: React.FC<BrandsContentDisplayProps> = ({
	description,
	imagePosition,
	button,
	brands,
}) => {
	return (
		<section>
			<div
				className={`${styles.contentWrapper} ${
					imagePosition ? "" : styles.flipped
				}`}
			>
				<div className={styles.content}>
					<Markdown className={styles.markdown} content={description} />
					{button?.buttonText && (
						<Button
							as='link'
							variant='primary'
							href={button.buttonLink}
							className={styles.button}
							aria-label={
								button.alternativeAria ? button.ariaLabel : button.buttonText
							}
						>
							{button.buttonText}
						</Button>
					)}
				</div>
				<div className={styles.logoContainer}>
					{brands &&
						brands.map(
							(brand, index) =>
								brand.logoSrc && (
									<div
										className={styles.logo}
										key={`${index}-${brand.logoSrc}`}
									>
										<img
											src={`${brand.logoSrc}?format=webp`}
											alt={brand.altText}
										/>
									</div>
								),
						)}
				</div>
			</div>
		</section>
	);
};

export default BrandsContentDisplay;

Builder.registerComponent(BrandsContentDisplay, {
	name: "Brands content display",
	defaults: {
		component: {
			name: "Brands content display",
			options: {
				description: "Add a brand description here",
				imagePosition: true,
				button: {
					buttonText: "Button text",
					buttonLink: "#",
				},
				brands: [
					{
						logoSrc: "https://placehold.co/128x128",
						altText: "Brand logo",
					},
				],
			},
		},
	},
	inputs: [
		{
			name: "description",
			friendlyName: "Description",
			type: "richText",
			required: true,
			helperText: "~125 characters",
		},
		{
			name: "imagePosition",
			friendlyName: "Images Location (right)",
			helperText:
				"Select for images to appear on the right on desktop and bottom on mobile",
			type: "boolean",
			defaultValue: true,
		},
		{
			name: "button",
			type: "object",
			subFields: [
				{
					name: "buttonText",
					friendlyName: "Button Text",
					type: "string",
					defaultValue: "Learn More",
					helperText: "~25 characters",
				},
				{
					name: "buttonLink",
					friendlyName: "Link",
					type: "string",
					defaultValue: "#",
				},
				{
					name: "alternativeAria",
					friendlyName: "Add Custom Link Aria Label?",
					type: "boolean",
					defaultValue: false,
				},
				{
					name: "ariaLabel",
					friendlyName: "Aria Label",
					type: "long-text",
					showIf: `options.get('alternativeAria')`,
				},
			],
		},
		{
			name: "brands",
			friendlyName: "Brands",
			type: "list",
			defaultValue: [
				{
					image: "https://placehold.co/128x128",
					altText: "Brand's logo",
				},
			],
			subFields: [
				{
					name: "logoSrc",
					type: "file",
					friendlyName: "Logo",
					defaultValue: "https://placehold.co/128x128/webp",
					helperText: "~128×128 px | .svg, .png | ~150 KB",
					allowedFileTypes: ["png", "svg"],
				},
				{
					name: "altText",
					type: "string",
					friendlyName: "Alt text",
					defaultValue: "e.g. Brand logo",
					required: true,
				},
			],
		},
	],
});
