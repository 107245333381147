import React, { FC } from "react";
import styles from "./FooterHorizontalLinks.module.scss";

type HorizontalLink = { link: string; ariaLabel: string; text: string };
interface FooterHorizontalLinksProps {
	isECom: boolean;
	horizontalLinkList: HorizontalLink[];
}

const FooterHorizontalLinks: FC<FooterHorizontalLinksProps> = ({
	isECom,
	horizontalLinkList,
}) => {
	return (
		<div className={styles.FooterInlineLinks}>
			<div>
				<nav>
					<ul
						className={`${
							isECom ? styles.ecomInlineLinks : styles.corpInlineLinks
						}`}
					>
						{horizontalLinkList?.map((link, index) => (
							<li key={`${index}-${link.link}`}>
								<a href={link.link} aria-label={link.ariaLabel}>
									{link.text}
								</a>
							</li>
						))}
					</ul>
				</nav>
				<p>&copy; {new Date().getFullYear()} Movora</p>
			</div>
		</div>
	);
};

export default FooterHorizontalLinks;
