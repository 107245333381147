import { StoreRegion } from "@/lib/builder/builder.config";
import { isValidJSON } from "@/utils/is-valid-json";
import Cookies from "js-cookie";

const PROJECT_KEY = "MOV";

const COOKIE_PREFIX =
	process.env.NODE_ENV === "development" ? `${PROJECT_KEY}_` : "";

interface CookieAttributes {
	/**
	 * Define when the cookie will be removed. Value can be a Number
	 * which will be interpreted as days from time of creation or a
	 * Date instance. If omitted, the cookie becomes a session cookie.
	 */
	expires?: number | Date | undefined;

	/**
	 * Define the path where the cookie is available. Defaults to '/'
	 */
	path?: string | undefined;

	/**
	 * Define the domain where the cookie is available. Defaults to
	 * the domain of the page where the cookie was created.
	 */
	domain?: string | undefined;

	/**
	 * A Boolean indicating if the cookie transmission requires a
	 * secure protocol (https). Defaults to false.
	 */
	secure?: boolean | undefined;

	/**
	 * Asserts that a cookie must not be sent with cross-origin requests,
	 * providing some protection against cross-site request forgery
	 * attacks (CSRF)
	 */
	sameSite?: "strict" | "Strict" | "lax" | "Lax" | "none" | "None" | undefined;

	/**
	 * An attribute which will be serialized, conformably to RFC 6265
	 * section 5.2.
	 */
	[property: string]: any;
}

export type CookieOptions = CookieAttributes & {
	name: string;
	value: string;
};

/**
 * Returns a Date object representing the expiration date of a cookie.
 *
 * @param {number} [days] - The number of days until the cookie expires. If not provided, the default value is used.
 * @return {Date} The expiration date of the cookie.
 */
export const getCookieExpiryDate = (days: number) => {
	const date = new Date();
	date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
	return date;
};

const getLoggedOutCookieExpiry = () => {
	const minutes = 1;
	return new Date(Date.now() + minutes * 60 * 1000);
};

export const getCartIdCookie = ():
	| { cartId: string; store: StoreRegion; customerId: number }
	| undefined => {
	try {
		const cookie = getClientCookie("cartId");

		if (!cookie) {
			return undefined;
		}

		return isValidJSON(cookie);
	} catch (error: unknown) {
		console.error(error);
		return undefined;
	}
};

export const setClientCartCookie = (
	cartId: string,
	store: StoreRegion,
	customerId: number,
) => {
	const value = {
		cartId,
		store,
		customerId: customerId === 0 ? getCartIdCookie()?.customerId : customerId,
	};

	const SECURE = window.location.protocol.startsWith("https");

	setClientCookie({
		name: "cartId",
		value: JSON.stringify(value),
		expires:
			customerId !== 0 ? getCookieExpiryDate(30) : getLoggedOutCookieExpiry(),
		httpOnly: false,
		sameSite: "lax",
		secure: SECURE,
		path: "/",
	});
};

export const getClientCookie = (name: string) => {
	const cookie = Cookies.get(`${COOKIE_PREFIX}${name}`);

	return cookie;
};

export const setClientCookie = (options: CookieOptions) => {
	const { name, value, ...rest } = options;

	Cookies.set(`${COOKIE_PREFIX}${name}`, value, rest);
};

export const deleteClientCookie = (name: string) => {
	Cookies.remove(`${COOKIE_PREFIX}${name}`);
};
// export const setServerCookie = (options: CookieOptions) => {
// 	cookies().set({
// 		...options,
// 		name: `${COOKIE_PREFIX}_${options.name}`,
// 	});
// };

// export const removeServerCookie = (name: Cookie) => {
// 	cookies().delete(`${COOKIE_PREFIX}_${name}`);
// };
