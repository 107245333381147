import React from "react";

const DotIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 55 55'
		fill='none'
		aria-label='dot'
		role='img'
	>
		<path
			d='M27.5 54.5C42.4117 54.5 54.5 42.4117 54.5 27.5C54.5 12.5883 42.4117 0.5 27.5 0.5C12.5883 0.5 0.5 12.5883 0.5 27.5C0.5 42.4117 12.5883 54.5 27.5 54.5Z'
			fill='#D34430'
		/>
		<path
			d='M27.7812 40.4375C34.7711 40.4375 40.4375 34.7711 40.4375 27.7812C40.4375 20.7914 34.7711 15.125 27.7812 15.125C20.7914 15.125 15.125 20.7914 15.125 27.7812C15.125 34.7711 20.7914 40.4375 27.7812 40.4375Z'
			stroke='white'
		/>
		<path
			d='M27.5 34.25C31.2279 34.25 34.25 31.2279 34.25 27.5C34.25 23.7721 31.2279 20.75 27.5 20.75C23.7721 20.75 20.75 23.7721 20.75 27.5C20.75 31.2279 23.7721 34.25 27.5 34.25Z'
			fill='white'
		/>
	</svg>
);

export default DotIcon;
