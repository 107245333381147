import IconArrow from "@/components/UI/Icon/IconArrow/IconArrow";
import * as Accordion from "@radix-ui/react-accordion";
import { FC } from "react";
import styles from "./FooterLinksList.module.scss";
import { hashString } from "@/lib/5874/util-functions/hashString";

interface FooterLinksListProps {
	isECom: boolean;
	column: string;
	linkDataArray: LinkData[];
	toggleColumn: any;
	isDisabled: true | undefined;
}

interface LinkData {
	ariaLabel?: string;
	link: string;
	text: string;
	openInNewTab?: boolean;
}

const FooterLinksList: FC<FooterLinksListProps> = ({
	isECom,
	column,
	linkDataArray,
	toggleColumn,
	isDisabled,
}) => {
	return (
		<nav>
			<Accordion.Item value={column} className={styles.footerLinksList}>
				{linkDataArray?.map((linkData) => {
					if (linkData.link === "#") {
						return (
							<Accordion.Header
								className={styles.footerLinksHeader}
								key={hashString(linkData.text)}
							>
								<Accordion.Trigger
									className={styles.AccordionTrigger}
									onClick={() => toggleColumn(column)}
								>
									{linkData.text}
									{isECom && (
										<IconArrow
											direction={"down"}
											aria-labelledby='directional chevron pointing down'
										/>
									)}
								</Accordion.Trigger>
							</Accordion.Header>
						);
					}

					return (
						<Accordion.Content
							key={hashString(linkData.text)}
							forceMount={isDisabled}
							className={styles.footerLinksCollapsibleContent}
							asChild
						>
							<div>
								<a
									href={linkData.link}
									aria-label={linkData.ariaLabel}
									target={linkData.openInNewTab ? "_blank" : "_parent"}
								>
									{linkData.text}
								</a>
							</div>
						</Accordion.Content>
					);
				})}
			</Accordion.Item>
		</nav>
	);
};

export default FooterLinksList;
