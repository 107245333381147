import fr from "./urls-fr";

export type UrlObject = {
	[key: string]: string;
};

const urlTranslation: { [key: string]: UrlObject } = {
	fr,
};

export const getStorePrefix = (params: any) => {
	return `/${params.store}/${params.lang}`;
};
export const getTranslatedPath = (params: {
	language: string;
	url: string;
	prefix?: string;
}) => {
	const { language, url, prefix } = params;

	const trimmedUrl = url.startsWith("/") ? url.slice(1) : url;

	const urls = urlTranslation[language];

	if (!urls) {
		return prefix ? `${prefix}/${trimmedUrl}` : `/${trimmedUrl}`;
	}

	let translatedUrl = urls[trimmedUrl];

	if (!translatedUrl) {
		translatedUrl =
			Object.entries(urls).find(([key, value]) => value === trimmedUrl)?.[0] ||
			trimmedUrl;
	}

	return prefix ? `${prefix}/${translatedUrl}` : `/${translatedUrl}`;
};
