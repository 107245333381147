"use client";

import React, { FC, useState, useEffect } from "react";
import { useI18n } from "@/i18n/client";
import { WishlistDetailsData } from "@/lib/bigcommerce-b2b/types";
import { getUserWishlistData } from "@/lib/bigcommerce-b2b/api/account/wishlist";
import checkUserHasRolePermission from "@/hooks/checkUserHasRolePermission";
import Button from "../../Button/Button";
import IconMiniLogo from "../../Icon/IconMiniLogo/IconMiniLogo";
import styles from "./AddToListWishlistListing.module.scss";
interface AddToListWishlistListingProps {
	productId: number;
	userId: number;
	setStep?: any;
	handleAddProduct: (productId: number, wishlistId: number) => void;
}

const AddToListWishlistListing: FC<AddToListWishlistListingProps> = ({
	productId,
	userId,
	setStep,
	handleAddProduct,
}) => {
	const [wishlists, setWishlists] = useState<WishlistDetailsData[]>();
	const [loading, setLoading] = useState(false);
	const t = useI18n();

	useEffect(() => {
		const fetchWishlistData = async () => {
			setLoading(true);

			const wishlistData = await getUserWishlistData(Number(userId), {
				limit: 10,
				slimData: true,
			});

			if (wishlistData?.data) {
				if ((wishlistData.data as any).errMsg) {
					console.error((wishlistData.data as any).errMsg);
				} else {
					setWishlists(wishlistData.data);
				}
			}
			setLoading(false);
		};

		if (!loading) fetchWishlistData();
	}, []);

	if (loading)
		return (
			<div className={styles.loading}>
				<IconMiniLogo />
			</div>
		);

	const handleAddProductToList = async (wishlistId: number | undefined) => {
		if (!wishlistId) return;
		setLoading(true);

		handleAddProduct(productId, wishlistId);
	};

	return (
		<div className={styles.listContainer}>
			<div className={styles.list}>
				{wishlists?.map((wishlist) => (
					<button
						key={wishlist.id}
						type='button'
						className={styles.item}
						onClick={() => handleAddProductToList(wishlist.id)}
					>
						{wishlist.name}
					</button>
				))}
			</div>
			{checkUserHasRolePermission("create_shopping_list") && (
				<div className={styles.action}>
					<Button
						as='button'
						variant='secondary'
						type='button'
						className={styles.create}
						onClick={() => setStep(2)}
					>
						{t("account.wishlist.createNewList")}
					</Button>
				</div>
			)}
		</div>
	);
};

export default AddToListWishlistListing;
