"use client";

import React from "react";
import styles from "./DuelList.module.scss";
import { Builder } from "@builder.io/react";
import { hashString } from "@/lib/5874/util-functions/hashString";

type ListItem = { listItem: string };
type List = {
	title: string;
	listItems: ListItem[];
};

interface DuelListProps {
	title: string;
	listOne: List;
	listTwo: List;
}

const DuelList: React.FC<DuelListProps> = ({ title, listOne, listTwo }) => {
	return (
		<section className={styles.duelListsSection}>
			<div className={styles.duelListContent}>
				<div className={styles.duelListMainTitle}>
					<h2>{title}</h2>
				</div>
				<div className={styles.duelList}>
					<h3>{listOne.title}</h3>
					<div className={styles.duelListItems}>
						{listOne.listItems.map((item) => (
							<p key={hashString(item.listItem)}>{item.listItem}</p>
						))}
					</div>
				</div>
				<div className={styles.duelList}>
					<h3>{listTwo.title}</h3>
					<div className={styles.duelListItems}>
						{listTwo.listItems.map((item) => (
							<p key={hashString(item.listItem)}>{item.listItem}</p>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default DuelList;

Builder.registerComponent(DuelList, {
	name: "Duel List",
	inputs: [
		{
			name: "title",
			type: "longText",
			defaultValue: "Title",
			helperText: "~30 characters",
		},
		{
			name: "listOne",
			type: "object",
			defaultValue: {
				title: "List one title",
				listItems: [
					{
						listItem: "List Item One",
					},
				],
			},
			subFields: [
				{
					name: "title",
					type: "longText",
					helperText: "~30 characters",
				},
				{
					name: "listItems",
					type: "list",
					subFields: [
						{
							name: "listItem",
							type: "longText",
							defaultValue: "This is a list item",
							required: true,
							helperText: "~25 characters",
						},
					],
				},
			],
		},
		{
			name: "listTwo",
			type: "object",
			defaultValue: {
				title: "List two title",
				listItems: [
					{
						listItem: "List Item Two",
					},
				],
			},
			subFields: [
				{
					name: "title",
					type: "longText",
					helperText: "~30 characters",
				},
				{
					name: "listItems",
					type: "list",
					subFields: [
						{
							name: "listItem",
							type: "longText",
							defaultValue: "This is a list item",
							required: true,
							helperText: "~25 characters",
						},
					],
				},
			],
		},
	],
});
