"use client";

import { Builder } from "@builder.io/react";
import Link from "next/link";
import React from "react";
import Button from "../UI/Button/Button";
import Markdown from "../UI/Markdown/Markdown";
import styles from "./SixPanelContentDisplay.module.scss";

interface SixPanelContentProps {
	title: string;
	description: string;
	button: {
		showButton: boolean;
		buttonText: string;
		buttonLink: string;
	};

	panels: {
		[key: string]: {
			image: string;
			altText: string;
			title: string;
			link: string;
			linkText: string;
		};
	};
}

const SixPanelContent: React.FC<SixPanelContentProps> = ({
	title,
	description,
	button,
	panels,
}) => {
	return (
		<section className={styles.sixPanelContentSection}>
			<div className={styles.contentSection}>
				<h2 className={styles.mainTitle}>{title}</h2>
				<Markdown content={description} />
				{button?.showButton && button?.buttonText && (
					<Button
						as='link'
						href={button.buttonLink}
						variant='secondary'
						className={styles.button}
					>
						{button.buttonText}
					</Button>
				)}
			</div>
			<div className={styles.panelArea}>
				{panels &&
					Object.values(panels).map((panel) => {
						return (
							<div className={styles.panel} key={panel?.link}>
								<div className={styles.image}>
									<img
										src={`${panel?.image}?format=webp`}
										alt={panel?.altText}
									/>
								</div>
								<div className={styles.panelTextArea}>
									<p className={styles.title}>{panel?.title}</p>
									{panel?.linkText && (
										<Link className={styles.learnMore} href={panel?.link}>
											{panel?.linkText}
										</Link>
									)}
								</div>
							</div>
						);
					})}
			</div>
		</section>
	);
};

export default SixPanelContent;

const listDefaultValue = {
	image: "https://placehold.co/300x300",
	altText: "this is alt text",
	title: "Panel Title",
	link: "#",
	linkText: "Learn More",
};

Builder.registerComponent(SixPanelContent, {
	name: "Six Panel Content Display",
	inputs: [
		{
			name: "title",
			friendlyName: "Title",
			type: "string",
			defaultValue: "Movora Education Pathways",
			required: true,
		},
		{
			name: "description",
			friendlyName: "Description",
			type: "richText",
			defaultValue: "This is the description text",
		},
		{
			name: "button",
			friendlyName: "Button Options",
			type: "object",
			subFields: [
				{
					name: "showButton",
					friendlyName: "Show?",
					type: "boolean",
					defaultValue: "false",
				},
				{
					name: "buttonLink",
					friendlyName: "Link",
					type: "url",
					defaultValue: "#",
					showIf: `options.get('showButton')`,
				},
				{
					name: "buttonText",
					friendlyName: "Text",
					type: "longText",
					defaultValue: "Visit Movora Education",
					showIf: `options.get('showButton')`,
				},
			],
			defaultValue: {
				showButton: true,
				buttonLink: "#",
				buttonText: "Visit Movora Education",
			},
		},
		{
			name: "panels",
			type: "object",
			defaultValue: {
				panel1: listDefaultValue,
				panel2: listDefaultValue,
				panel3: listDefaultValue,
				panel4: listDefaultValue,
				panel5: listDefaultValue,
				panel6: listDefaultValue,
			},
			subFields: [...Array(6)].map((_, index) => {
				return {
					name: `panel${index + 1}`,
					type: "object",
					subFields: [
						{
							name: "image",
							friendlyName: "Image",
							type: "file",
							defaultValue: "https://placehold.co/300x300",
							helperText: "Please upload an image with an aspect ratio of 4:3",
						},
						{
							name: "altText",
							friendlyName: "Alt Text",
							type: "string",
							defaultValue: "This is the alt text",
						},
						{
							name: "title",
							friendlyName: "Title",
							type: "string",
							defaultValue: "Panel Title",
						},
						{
							name: "linkText",
							friendlyName: "Link Text",
							type: "string",
							defaultValue: "Learn More",
						},
						{
							name: "link",
							friendlyName: "Link",
							type: "string,",
							defaultValue: "#",
						},
					],
				};
			}),
		},
	],
});
