"use client";

import { Builder } from "@builder.io/react";
import React from "react";
import Button from "../UI/Button/Button";
import Markdown from "../UI/Markdown/Markdown";
import styles from "./BrandBlock.module.scss";

interface BrandBlockProps {
	brandName: string;
	description: string;
	button: {
		buttonText: string;
		buttonLink: string;
		alternativeAria: boolean;
		ariaLabel: string;
	};
	logo: {
		logoSrc: string;
		altText: string;
	};
}

const BrandBlock: React.FC<BrandBlockProps> = ({
	brandName,
	description,
	button,
	logo,
}) => {
	return (
		<section className={styles.section}>
			<div className={styles.contentWrapper}>
				<div className={styles.imgContainer}>
					{logo.logoSrc && (
						<img src={`${logo.logoSrc}?format=webp`} alt={logo.altText} />
					)}
				</div>
				<div className={styles.content}>
					<h2>{brandName}</h2>
					<Markdown className={styles.markdown} content={description} />
					{button?.buttonText && (
						<Button
							as='link'
							variant='secondary'
							href={button.buttonLink}
							className={styles.button}
							aria-label={
								button.alternativeAria ? button.ariaLabel : button.buttonText
							}
						>
							{button.buttonText}
						</Button>
					)}
				</div>
			</div>
		</section>
	);
};

export default BrandBlock;

Builder.registerComponent(BrandBlock, {
	name: "Brand block",
	defaults: {
		component: {
			name: "Brand block",
			options: {
				brandName: "Title",
				description: "Add a brand description here",
				button: {
					buttonText: "Button text",
					buttonLink: "#",
				},
				logo: {
					logoSrc: "https://placehold.co/256x256/webp",
					altText: "E.G. Image of dog running",
				},
			},
		},
	},
	inputs: [
		{
			name: "brandName",
			friendlyName: "Brand name",
			type: "longText",
			required: true,
			helperText: "~30 characters",
		},
		{
			name: "description",
			friendlyName: "Description",
			type: "richText",
			required: true,
			helperText: "~700 characters",
		},
		{
			name: "button",
			type: "object",
			subFields: [
				{
					name: "buttonText",
					friendlyName: "Button Text",
					type: "string",
					defaultValue: "Learn More",
					helperText: "~25 characters",
				},
				{
					name: "buttonLink",
					friendlyName: "Link",
					type: "string",
					defaultValue: "#",
				},
				{
					name: "alternativeAria",
					friendlyName: "Add Custom Link Aria Label?",
					type: "boolean",
					defaultValue: false,
				},
				{
					name: "ariaLabel",
					friendlyName: "Aria Label",
					type: "long-text",
					showIf: `options.get('alternativeAria')`,
				},
			],
		},
		{
			name: "logo",
			type: "object",
			subFields: [
				{
					name: "logoSrc",
					type: "file",
					friendlyName: "Logo",
					defaultValue: "https://placehold.co/256x256/webp",
					helperText: "Add a logo image",
					allowedFileTypes: ["jpeg", "jpg", "png"],
				},
				{
					name: "altText",
					type: "string",
					friendlyName: "Alt text",
					defaultValue: "e.g. The two dogs run",
					required: true,
				},
			],
		},
	],
});
