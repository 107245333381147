import { FC } from "react";

import clsx from "clsx";
import moment from "moment";
import styles from "./PublishDate.module.scss";

interface Props {
	date: number | Date | string;
	className?: string;
}

const PublishDate: FC<Props> = ({ date, className = "" }) => {
	if (!date) {
		return;
	}

	let published;

	if (typeof date === "string" || date instanceof Date) {
		// Ensure the date is parsed correctly by moment
		published = moment(new Date(date));
	} else if (typeof date === "number") {
		// Treat the number as a timestamp
		published = moment(new Date(date));
	} else {
		return null;
	}

	// If the date is invalid, return null to avoid rendering incorrect dates
	if (!published.isValid()) {
		return null;
	}
	return (
		<time
			className={clsx([styles.date, className])}
			dateTime={published.toLocaleString()}
		>
			{published.format("YYYY, MMM D")}
		</time>
	);
};

export default PublishDate;
