import React, { FC, useEffect, useState } from "react";
import styles from "./FooterSocialLinks.module.scss";
import { initBuilder } from "@/lib/builder/builder-utils";
import { builder } from "@builder.io/sdk";
import RegionWebsiteSelectorModal, {
	Region,
} from "@/components/LocalWebsiteSelector/RegionSelectorModal";
import Modal from "@/components/Modal/Modal";

interface FooterSocialLinksProps {
	isECom: boolean;
	socials: {
		link: string;
		ariaLabel: string;
		image: string;
		altText: string;
	}[];
}

const FooterSocialLinks: FC<FooterSocialLinksProps> = ({ socials, isECom }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	// const { closeModal, openModal, regionalModalOpen } = useRegionalModal();
	const [regionDropdowns, setRegionDropdowns] = useState<Region[]>([]);

	// const toggleModal = () => (regionalModalOpen ? closeModal() : openModal());
	const toggleModal = () => setIsModalOpen((prev) => !prev);

	initBuilder("global");

	useEffect(() => {
		const fetchRegions = async () => {
			try {
				const regions = await builder.getAll("local-regions");
				const mappedRegions = regions.map((region) => ({
					title: region?.data?.title,
					link: region?.data?.link,
					browserLanguageCode: region?.data?.browserLanguageCode,
				}));
				setRegionDropdowns(mappedRegions);
			} catch (error) {
				console.error("Error fetching regions:", error);
			}
		};

		fetchRegions();
	}, []);
	return (
		<>
			<Modal
				isOpen={isModalOpen}
				toggleModal={toggleModal}
				contentClassName={styles.modalContent}
				modalBackground='light'
			>
				<RegionWebsiteSelectorModal regionDropdowns={regionDropdowns} />
			</Modal>
			<div className={styles.FooterSocials}>
				<div className={styles.FooterInnerBottomSocial}>
					{isECom && (
						<button type='button' onClick={() => setIsModalOpen(true)}>
							Change Region & Language
						</button>
					)}
					<nav>
						<ul>
							{socials?.map((social) => {
								return (
									<li key={social.link}>
										<a
											href={social.link}
											aria-label={social.ariaLabel}
											target='_blank'
											rel='noreferrer'
										>
											<img src={social.image} alt={social.altText} />
										</a>
									</li>
								);
							})}
						</ul>
					</nav>
				</div>
			</div>
		</>
	);
};

export default FooterSocialLinks;
