"use client";
import { Builder } from "@builder.io/react";
import React from "react";
import Button from "../UI/Button/Button";
import Markdown from "../UI/Markdown/Markdown";
import styles from "./ListImageContentDisplay.module.scss";
import clsx from "clsx";

type ButtonType = {
	showButton?: boolean;
	buttonText: string;
	buttonType: "primary" | "secondary";
	buttonLink: string;
	openInNewTab: boolean;
	alternativeAria: boolean;
	ariaLabel: string;
};

type ImageBlock = {
	imageLocation: boolean;
	imageCurveBottom: boolean;
	imageCurveTop: boolean;
	image: string;
	altText: string;
	showLogo: boolean;
	logoSrc: string;
	logoAlt: string;
	title: string;
	subtitle?: string;
	description: string;
	primaryButton: ButtonType;
	secondaryButton: ButtonType;
};
interface ListImageContentDisplayProps {
	mainTitle: string;
	mainSubtitle: string;
	images: ImageBlock[];
}

const ListImageContentDisplay: React.FC<ListImageContentDisplayProps> = ({
	mainTitle,
	mainSubtitle,
	images,
}) => {
	return (
		<section className={styles.contentSection}>
			<div className={styles.content}>
				<div className={styles.header}>
					{mainTitle ? <h2>{mainTitle}</h2> : null}
					{mainSubtitle ? <p>{mainSubtitle}</p> : null}
				</div>
				<>
					{images.map((img, idx) => (
						<div
							key={`${idx}-${img.image}`}
							className={`${styles.contentWrapper} ${
								img.imageLocation === true ? "" : styles.switchOrder
							}`}
						>
							<div className={styles.imageArea}>
								<img
									src={`${img.image}?format=webp`}
									alt={img.altText}
									className={clsx({
										[styles.imageCurveBottom]: img.imageCurveBottom,
										[styles.imageCurveTop]: img.imageCurveTop,
										[styles.imageCurveTopBottom]:
											img.imageCurveBottom && img.imageCurveTop,
									})}
								/>
							</div>
							<div className={styles.textArea}>
								{img.showLogo && (
									<img
										className={styles.logo}
										src={`${img.logoSrc}?format=webp`}
										alt={img.logoAlt}
									/>
								)}
								{img.title ? <h2>{img.title}</h2> : null}
								{img.subtitle ? <h3>{img.subtitle}</h3> : null}
								{img.description && (
									<Markdown
										content={img.description}
										className={styles.markdown}
									/>
								)}
								<div className={styles.buttons}>
									{img.primaryButton?.showButton && (
										<Button
											as='link'
											variant={img.primaryButton.buttonType || "primary"}
											href={img.primaryButton.buttonLink}
											target={
												img.primaryButton.openInNewTab ? "_blank" : "_parent"
											}
											aria-label={
												img.primaryButton.alternativeAria
													? img.primaryButton.ariaLabel
													: img.altText
											}
										>
											{img.primaryButton.buttonText}
										</Button>
									)}
									{img.secondaryButton?.showButton && (
										<Button
											as='link'
											variant={img.secondaryButton.buttonType || "secondary"}
											target={
												img.secondaryButton.openInNewTab ? "_blank" : "_parent"
											}
											href={img.secondaryButton.buttonLink}
											aria-label={
												img.secondaryButton.alternativeAria
													? img.secondaryButton.ariaLabel
													: img.altText
											}
										>
											{img.secondaryButton.buttonText}
										</Button>
									)}
								</div>
							</div>
						</div>
					))}
				</>
			</div>
		</section>
	);
};

export default ListImageContentDisplay;

Builder.registerComponent(ListImageContentDisplay, {
	name: "List image content",
	defaults: {
		component: {
			name: "List image content",
			options: {
				mainTitle: "Solutions from over 80 years of experience",
				mainSubtitle:
					"Each brand in the Movora portfolio is rooted deep in veterinary orthopedics",
				images: [
					{
						imageLocation: true,
						image: "https://placehold.co/632x632",
						altText: "alt text",
						showLogo: false,
						logoSrc: "https://placehold.co/82x80",
						logoAlt: "this is the logo alt text",
						title: "Title",
						subtitle: "Subtitle",
						description: "Enter description...",
						primaryButton: {
							showButton: false,
							buttonText: "Example Text",
							buttonType: "primary",
							buttonLink: "#",
						},
						secondaryButton: {
							showButton: false,
							buttonText: "Example Text",
							buttonType: "secondary",
							buttonLink: "#",
						},
					},
				],
			},
		},
	},
	inputs: [
		{
			name: "mainTitle",
			friendlyName: "Main Title",
			type: "longText",
			helperText: "~40 characters",
		},
		{
			name: "mainSubtitle",
			friendlyName: "Main Subtitle",
			type: "longText",
			helperText: "~75 characters",
		},
		{
			name: "images",
			friendlyName: "Images",
			type: "list",
			defaultValue: [
				{
					imageLocation: true,
					imageCurveBottom: true,
					imageCurveTop: false,
					image: "https://placehold.co/632x632",
					altText: "alt text",
					logo: {
						showLogo: false,
						logo: "https://placehold.co/80x80",
						logoAlt: "this is the logo alt text",
					},
					title: "Title",
					subtitle: "Subtitle",
					description: "Enter description...",
					button: {
						showButton: false,
						buttonText: "Example Text",
						buttonType: "primary",
						buttonLink: "#",
					},
				},
			],
			subFields: [
				{
					name: "imageLocation",
					friendlyName: "Image location (left)",
					type: "boolean",
					helperText: "Enabled = Display Left | Disabled = Display Right",
				},
				{
					name: "imageCurveBottom",
					friendlyName: "Add Bottom Image Curve?",
					type: "boolean",
					helperText: "Toggle to add a curve to the bottom of the image",
				},
				{
					name: "imageCurveTop",
					friendlyName: "Add Top Image Curve?",
					type: "boolean",
					helperText: "Toggle to add a curve to the top of the image",
				},
				{
					name: "image",
					friendlyName: "Image",
					type: "file",
					allowedFileTypes: ["jpeg", "jpg", "png"],
					helperText: "632×632 px | .jpeg, .jpg, .png | ~150 KB",
					required: true,
				},
				{
					name: "altText",
					friendlyName: "Alt Text",
					type: "string",
					required: true,
				},
				{
					name: "showLogo",
					friendlyName: "Add Logo",
					type: "boolean",
				},
				{
					name: "logoSrc",
					friendlyName: "Logo",
					type: "file",
					allowedFileTypes: ["png", "svg"],
					showIf: `options.get('showLogo')`,
					helperText: "~80×80 px | .svg, .png | ~100 KB",
				},
				{
					name: "logoAlt",
					friendlyName: "Logo Alt Text",
					type: "string",
					showIf: `options.get('showLogo')`,
				},
				{
					name: "title",
					friendlyName: "Title",
					type: "string",
					defaultValue: "Title",
					helperText: "~25 characters",
				},
				{
					name: "subtitle",
					helperText:
						"Optional subtitle: Leave blank if not required. ~75 characters",
					friendlyName: "Subtitle",
					type: "string",
					defaultValue: "Title",
				},
				{
					name: "description",
					helperText:
						"To add a title, please use 'H3'. For footnotes, 'H5' | ~350 characters",
					friendlyName: "Description",
					type: "richText",
					defaultValue: "description text",
				},
				{
					name: "primaryButton",
					friendlyName: "Primary CTA Button",
					type: "object",
					defaultValue: {
						showButton: false,
						buttonText: "Example Text",
						buttonType: "primary",
						buttonLink: "#",
					},
					subFields: [
						{
							name: "showButton",
							friendlyName: "Add Button",
							type: "boolean",
						},
						{
							name: "buttonText",
							friendlyName: "Button Text",
							type: "string",
							showIf: `options.get('showButton')`,
							helperText: "~15 characters",
						},
						{
							name: "buttonType",
							friendlyName: "Button Type",
							type: "string",
							enum: ["primary", "secondary"],
							showIf: `options.get('showButton')`,
						},
						{
							name: "buttonLink",
							friendlyName: "Button Link",
							type: "string",
							showIf: `options.get('showButton')`,
						},
						{
							name: "openInNewTab",
							friendlyName: "Open in new tab?",
							type: "boolean",
							showIf: `options.get('showButton')`,
						},
						{
							name: "alternativeAria",
							friendlyName: "Add Custom Link Aria Label?",
							type: "boolean",
							defaultValue: false,
						},
						{
							name: "ariaLabel",
							friendlyName: "Aria Label",
							type: "long-text",
							showIf: `options.get('alternativeAria')`,
						},
					],
				},
				{
					name: "secondaryButton",
					friendlyName: "Secondary CTA Button",
					type: "object",
					defaultValue: {
						showButton: false,
						buttonText: "Example Text",
						buttonType: "secondary",
						buttonLink: "#",
					},
					subFields: [
						{
							name: "showButton",
							friendlyName: "Add Button",
							type: "boolean",
						},
						{
							name: "buttonText",
							friendlyName: "Button Text",
							type: "string",
							showIf: `options.get('showButton')`,
							helperText: "~15 characters",
						},
						{
							name: "buttonType",
							friendlyName: "Button Type",
							type: "string",
							enum: ["primary", "secondary"],
							showIf: `options.get('showButton')`,
						},
						{
							name: "buttonLink",
							friendlyName: "Button Link",
							type: "string",
							showIf: `options.get('showButton')`,
						},
						{
							name: "openInNewTab",
							friendlyName: "Open in new tab?",
							type: "boolean",
							showIf: `options.get('showButton')`,
						},
						{
							name: "alternativeAria",
							friendlyName: "Add Custom Link Aria Label?",
							type: "boolean",
							defaultValue: false,
						},
						{
							name: "ariaLabel",
							friendlyName: "Aria Label",
							type: "long-text",
							showIf: `options.get('alternativeAria')`,
						},
					],
				},
			],
		},
	],
});
