"use client";

import { ProductData } from "@/lib/5874/types";
import {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useState,
} from "react";

interface ProductContextProps {
	product: ProductData;
	selectedVariant: any;
	setSelectedVariant: (variant: any) => void;
	showModal: boolean;
	handleShowModal: (show: boolean) => void;
	wishlistModalProduct: ProductData | undefined;
	handleSetWishlistModalProduct: (product: ProductData) => void;
	skuList: string[];
	setSkuList: Dispatch<SetStateAction<string[]>>;
}

export const ProductContext = createContext({} as ProductContextProps);

interface ProviderProps {
	product: ProductData;
	children?: React.ReactNode;
}

export const ProductContextProvider = ({
	product,
	children,
}: ProviderProps) => {
	const [skuList, setSkuList] = useState<string[]>([]);
	const [selectedVariant, setSelectedVariant] = useState();
	const [showModal, setShowModal] = useState(false);
	const [wishlistModalProduct, setWishlistModalProduct] = useState<
		ProductData | undefined
	>();

	const handleShowModal = (show: boolean) => {
		setShowModal(show);
	};
	const handleSetWishlistModalProduct = (product: ProductData) => {
		if (!product) setWishlistModalProduct(undefined);
		setWishlistModalProduct(product);
	};

	const value = {
		product,
		selectedVariant,
		setSelectedVariant,
		showModal,
		handleShowModal,
		wishlistModalProduct,
		handleSetWishlistModalProduct,
		skuList,
		setSkuList,
	};

	return (
		<ProductContext.Provider value={value}>{children}</ProductContext.Provider>
	);
};

export const useProductContext = () => {
	const context = useContext(ProductContext);

	if (!context) {
		throw new Error(
			"useProductContext must be used within a ProductContextProvider",
		);
	}

	return context;
};
