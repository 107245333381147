const mapProductOptions = (productOptions: any) => {
	const options: {
		label: string;
		options: { label: string; value: string }[];
	}[] = [];

	for (const product of productOptions) {
		if (!product || !product.customFields) continue;

		for (const customField of product.customFields) {
			if (customField.name.startsWith("O")) {
				const key = customField.name.split("|");

				// check to see if we have already added the option
				const existingOption = options.find(
					(option) => option.label === key[1],
				);

				if (existingOption) {
					// Check to make sure we haven't already added this option
					const existingValue = existingOption.options.find(
						(option) => option.label === customField.value,
					);

					if (existingValue) continue;

					existingOption.options.push({
						label: customField.value,
						value: product.path,
					});
				} else {
					const newOption = {
						label: key[1],
						options: [
							{
								label: customField.value,
								value: product.path,
							},
						],
					};

					options.push(newOption);
				}
			}
		}
	}

	return options;
};

export const getProductOptions = (productOptions: any) => {
	const productOptionsAndPath: {
		productPath: string;
		variantOptions: {
			[key: string]: string;
		};
	}[] = [];

	for (const product of productOptions) {
		if (!product || !product.customFields) continue;
		let variantOptions: Record<string, string> = {};
		for (const customField of product.customFields) {
			if (customField.name.startsWith("O")) {
				const key = customField.name.split("|");
				variantOptions[key[1]] = customField.value;
			}
		}
		if (Object.keys(variantOptions).length) {
			productOptionsAndPath.push({
				productPath: product.path,
				variantOptions,
			});
		}
	}
	return productOptionsAndPath;
};
export default mapProductOptions;
