"use client";

import useIsPreviewingBuilder from "@/hooks/useIsPreviewingBuilder";
import { Builder, useIsPreviewing } from "@builder.io/react";
import * as Accordion from "@radix-ui/react-accordion";
import React from "react";
import IconArrow from "../UI/Icon/IconArrow/IconArrow";
import Markdown from "../UI/Markdown/Markdown";
import styles from "./FAQ.module.scss";
import { hashString } from "@/lib/5874/util-functions/hashString";

interface FAQProps {
	header: string;
	questions: [
		{
			question: string;
			answer: string;
		},
	];
}

const FAQ: React.FC<FAQProps> = ({ header, questions }) => {
	const { isEditingBuilder } = useIsPreviewingBuilder();

	const value = !isEditingBuilder
		? undefined
		: questions.map((_, index) => `item-${index}`);

	return (
		<section className={styles.FaqSection}>
			{header && <h2 className={styles.heading}>{header}</h2>}
			<Accordion.Root
				type='multiple'
				value={value}
				className={styles.accordionRoot}
			>
				{questions?.map((question, index) => (
					<Accordion.Item
						key={hashString(question.question)}
						value={`item-${index}`}
						className={styles.AccordionItem}
					>
						<Accordion.Header className={styles.AccordionHeader}>
							<Accordion.Trigger className={styles.AccordionTrigger}>
								{question.question}
								<IconArrow
									direction='down'
									aria-labelledby='directional chevron pointing down'
								/>
							</Accordion.Trigger>
						</Accordion.Header>
						<Accordion.Content className={styles.AccordionContent}>
							<Markdown className={styles.markdown} content={question.answer} />
						</Accordion.Content>
					</Accordion.Item>
				))}
			</Accordion.Root>
		</section>
	);
};

export default FAQ;

Builder.registerComponent(FAQ, {
	name: "FAQ",
	inputs: [
		{
			name: "header",
			friendlyName: "Header",
			type: "longText",
			defaultValue: "FAQ Header",
		},
		{
			name: "questions",
			friendlyName: "Questions",
			type: "list",
			helperText: "Answers are always shown in preview mode by default",
			defaultValue: [
				{
					question: "What is the question?",
					answer: "What is the answer?",
				},
				{
					question: "What is the question?",
					answer: "What is the answer?",
				},
			],
			subFields: [
				{
					name: "question",
					friendlyName: "Question",
					type: "longText",
					defaultValue: "What is the question?",
					required: true,
				},
				{
					name: "answer",
					friendlyName: "Answer",
					type: "richText",
					defaultValue: "What is the answer?",
					required: true,
				},
			],
		},
	],
});
