"use client";
import { Builder } from "@builder.io/react";
import React from "react";
import Markdown from "../UI/Markdown/Markdown";
import styles from "./TripleImageRow.module.scss";

interface TripleImageRowProps {
	title: string;
	description: string;
	images: [
		{
			imageTitle: string;
			imageSrc: string;
			altText: string;
		},
	];
}

const TripleImageRow: React.FC<TripleImageRowProps> = ({
	title,
	description,
	images,
}) => {
	return (
		<section className={styles.container}>
			<div className={styles.innerWrapper}>
				<div className={styles.content}>
					<h6>{title}</h6>
					<Markdown className={styles.markdown} content={description} />
				</div>
				<div className={styles.imagesWrapper}>
					{images &&
						images.map((image) => (
							<div className={styles.imgContainer} key={image.imageSrc}>
								<img
									src={`${image.imageSrc}?format=webp`}
									alt={image.altText}
								/>
								<p className={styles.text}>{image.imageTitle}</p>
							</div>
						))}
				</div>
			</div>
		</section>
	);
};

export default TripleImageRow;

Builder.registerComponent(TripleImageRow, {
	name: "Triple Image Row",
	inputs: [
		{
			name: "title",
			friendlyName: "Title",
			type: "string",
			defaultValue: "Title",
			helperText: "~25 characters",
		},
		{
			name: "description",
			friendlyName: "Description",
			type: "richText",
			defaultValue: "Description",
			helperText: "~225 characters",
		},
		{
			name: "images",
			friendlyName: "Images (Max 3)",
			type: "list",
			onChange: (options: any) => {
				if (options.get("images").length > 3) {
					options.set("images", options.get("images").slice(0, 3));
					alert("The maximum number of images is 3");
				}
			},
			defaultValue: [
				{
					imageTitle: "Image Title",
					imageSrc: "https://placehold.co/320x200",
					altText: "Alt text",
				},
			],
			subFields: [
				{
					name: "imageTitle",
					friendlyName: "Image Title",
					type: "string",
					defaultValue: "Image Title",
					helperText: "~25 characters",
				},
				{
					name: "imageSrc",
					type: "file",
					friendlyName: "Image",
					defaultValue: "https://placehold.co/320x200/webp",
					helperText: "320×320 px | .jpeg, .jpg, .svg, .png | ~150 KB",
					allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
				},
				{
					name: "altText",
					type: "string",
					friendlyName: "Alt text",
					defaultValue: "Alt text",
					required: true,
				},
			],
		},
	],
});
