"use client";
import React, { FC, useState } from "react";
import HeaderDropdown from "@/components/HeaderDropdown/HeaderDropdown";
import clsx from "clsx";
import Chevron from "@/app/assets/images/svg/chevron.svg";
import { subMenuItem } from "../../headerTypes";
import stylesNC from "../NavCorporate/NavCorporate.module.scss";
import Link from "next/link";
import Button from "@/components/UI/Button/Button";

interface HeaderNavItemsProps {
	text: string;
	link: string;
	ariaLabel: string;
	subMenuItems: subMenuItem[];
	setOpenMobileMenu: (state: boolean) => void;
}

const HeaderNavItems: FC<HeaderNavItemsProps> = ({
	text,
	link,
	ariaLabel,
	subMenuItems,
	setOpenMobileMenu,
}) => {
	const [showSubmenu, setShowSubmenu] = useState(false);
	const [active, setActive] = useState(false);
	const handleOpenSubMenu = () => {
		setShowSubmenu(!showSubmenu);
	};

	return (
		<div
			onMouseEnter={() => setActive(true)}
			onMouseLeave={() => setActive(false)}
			className={clsx(stylesNC.navList)}
		>
			<Link
				onClick={() => setOpenMobileMenu(false)}
				className={clsx(stylesNC.navItem, { [stylesNC.active]: active })}
				key={link}
				href={link}
				aria-label={ariaLabel}
			>
				{text}
			</Link>
			{subMenuItems?.length > 1 && (
				<div>
					<Button
						as='button'
						variant='empty'
						onClick={() => handleOpenSubMenu()}
						className={stylesNC.subMenuBackButton}
					>
						<Chevron aria-labelledby='directional chevron' />
					</Button>
					<div className={stylesNC.showSubDropDown}>
						<HeaderDropdown
							setShowSubmenu={setShowSubmenu}
							submenuItems={subMenuItems}
							showSubmenu={showSubmenu}
							setOpenMobileMenu={setOpenMobileMenu}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default HeaderNavItems;
