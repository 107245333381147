"use client";
import MobileMenu from "@/app/assets/images/svg/burger-menu.svg";
import CloseMobileMenu from "@/app/assets/images/svg/xmark.svg";
import clsx from "clsx";
import React, { FC } from "react";
import styles from "./HeaderBurgerButton.module.scss";

interface HeaderBurgerButtonProps {
	onButtonClick: () => void;
	ariaLabel: string;
	isOpen: boolean;
}

const HeaderBurgerButton: FC<HeaderBurgerButtonProps> = ({
	isOpen,
	onButtonClick,
	ariaLabel,
}) => {
	return (
		<button
			type={"button"}
			className={clsx(styles.burgerButton, { [styles.lines]: !isOpen })}
			onClick={onButtonClick}
			onKeyDown={(e) => {
				if (e.key === "Enter") {
					e.preventDefault();
					onButtonClick();
				}
			}}
			aria-label={ariaLabel}
		>
			{isOpen ? <CloseMobileMenu /> : <MobileMenu />}
		</button>
	);
};

export default HeaderBurgerButton;
