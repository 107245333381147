"use client";

import useStoreKeys from "@/hooks/useStoreKeys";
import { StoreRegion, getSpaceDetails } from "@/lib/builder/builder.config";
import { Builder } from "@builder.io/react";
import { builder } from "@builder.io/sdk";
import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import FacebookIcon from "@/app/assets/images/svg/platformIcons/facebook.svg";
import LinkedInIcon from "@/app/assets/images/svg/platformIcons/linkedin.svg";
import EmailIcon from "@/app/assets/images/svg/platformIcons/mail.svg";
import ShareIcon from "@/app/assets/images/svg/platformIcons/share.svg";
import Icon from "../UI/Icon/Icon";
import styles from "./ShareButton.module.scss";

interface ShareButtonBuilderProps {
	platforms: {
		icon: string;
		altText: string;
		ariaLabel: string;
		shareLink: string;
	}[];
}

interface ShareButtonUserProps {
	toggleButtonAria: string;
	className?: string;
}

const ShareButton: React.FC<ShareButtonUserProps> = ({
	toggleButtonAria,
	className,
}) => {
	const [shareButtonData, setShareButtonData] =
		useState<ShareButtonBuilderProps>();

	const { store, lang } = useStoreKeys();
	// Builder Public API Key set in .env file
	const apiKey = getSpaceDetails(store as StoreRegion);
	builder.init(apiKey.publicKey);

	useEffect(() => {
		builder
			.get("share-button-data", {
				userAttributes: {
					// Use the page path specified in the URL to fetch the content
					urlPath: lang ? `/${lang}/` : "/",
				},
			})
			.promise()
			.then((response) => {
				if (response?.data) {
					return setShareButtonData(response.data);
				}
				setShareButtonData(undefined);
			});
	}, [lang]);

	function handlePlatform(link: string) {
		//wrapped in if statement as rendering serverside there is no document
		if (typeof window !== "undefined") {
			const pageTitle = document?.title;
			const pageUrl = window.location.href;

			const formattedLink = link
				.replace("%%title%%", pageTitle)
				.replace("%%pagelink%%", pageUrl);

			return formattedLink;
		}
	}

	const handleIcon = (iconType: string) => {
		switch (iconType) {
			case "Email":
				return <EmailIcon />;

			case "Facebook":
				return <FacebookIcon />;

			default:
				return <LinkedInIcon />;
		}
	};

	return (
		<section>
			<Popover.Root modal={true}>
				<Popover.Trigger
					className={clsx([styles.toggle, className])}
					aria-label={toggleButtonAria}
				>
					<ShareIcon />
				</Popover.Trigger>

				<Popover.Portal>
					<Popover.Content
						align='start'
						sideOffset={4}
						className={styles.platformsWrapper}
					>
						{shareButtonData?.platforms.map((platform, index: React.Key) => (
							// biome-ignore lint/a11y/useValidAnchor: Links to external site or mail, should use anchor
							<a
								key={`share-${platform.icon}-${index}`}
								target='_blank'
								rel='noreferrer'
								className={styles.platform}
								aria-label={platform.ariaLabel}
								href={handlePlatform(platform.shareLink)}
								onClick={() => handlePlatform(platform.shareLink)}
							>
								<Icon icon={handleIcon(platform.icon)} />
							</a>
						))}
					</Popover.Content>
				</Popover.Portal>
			</Popover.Root>
		</section>
	);
};

export default ShareButton;

Builder.registerComponent(ShareButton, {
	name: "Share Button",
	inputs: [
		{
			name: "toggleButtonAria",
			friendlyName: "Toggle Button Aria Label",
			type: "string",
			required: true,
			defaultValue: "Toggle Share Links Visible",
		},
	],
});
