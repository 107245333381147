"use client";
import { Builder } from "@builder.io/react";
import clsx from "clsx";
import Link from "next/link";
import { useMemo } from "react";
import Button from "../UI/Button/Button";
import styles from "./SolutionsDog.module.scss";
import DotIcon from "./dot";
import dogImage from "./solutions_dog.png";

interface SolutionsDogProps {
	title: string;
	altText: string;
	links: LinkType[];
}

type LinkType = {
	part: string;
	text: string;
	link: string;
};

function SolutionsDog({ title, altText, links = [] }: SolutionsDogProps) {
	const uniqueLinks = useMemo(() => {
		if (!links?.length) {
			return [];
		}

		return links.reduce((acc: LinkType[], link) => {
			if (!link.part) {
				return acc;
			}

			if (!acc.find((item) => item.part === link.part)) {
				acc.push(link);
			}
			return acc;
		}, []);
	}, [links]);

	return (
		<section className={clsx([styles.outer])}>
			<div className={styles.container}>
				<div className={styles.left}>
					<h2 className={styles.title}>{title}</h2>
					<div className={styles.btnLinks}>
						{uniqueLinks.map(({ part, text, link }) => {
							part = part.toLowerCase();

							return (
								<Button
									key={`button-${part}`}
									variant='rounded'
									as='link'
									href={link || "#"}
									id={`button-${part}`}
								>
									{text || ""}
								</Button>
							);
						})}
					</div>
				</div>
				<div className={styles.right}>
					<img
						className={styles.dogImage}
						src={`${dogImage.src}?format=webp`}
						alt={altText}
						height={360}
						width={640}
					/>
					{uniqueLinks.map(({ part, text, link }) => {
						part = part.toLowerCase();

						return (
							<Link
								key={`button-${part}`}
								className={clsx([styles.dot, styles[part]])}
								href={link || "#"}
								aria-labelledby={`button-${part}`}
								title={text || part}
							>
								<DotIcon />
							</Link>
						);
					})}
				</div>
			</div>
		</section>
	);
}

export default SolutionsDog;

Builder.registerComponent(SolutionsDog, {
	name: "Anatomy Dog",
	inputs: [
		{
			name: "title",
			type: "string",
			defaultValue: "Shop by Anatomy",
			required: true,
		},
		{
			name: "altText",
			type: "string",
			defaultValue: "A dog runs",
			helperText: "Describe the image of the dog",
			required: true,
		},
		{
			name: "links",
			type: "list",
			defaultValue: [
				{
					part: "Ankle",
					text: "Ankle",
					link: "#",
					ariaLabel: "Ankles",
				},
				{
					part: "Elbow",
					text: "Elbow",
					link: "#",
				},
				{
					part: "Hip",
					text: "Hip",
					link: "#",
				},
				{
					part: "Knee",
					text: "Knee",
					link: "#",
				},
			],
			subFields: [
				{
					name: "part",
					type: "string",
					enum: ["Ankle", "Elbow", "Hip", "Knee"],
					required: true,
				},
				{
					name: "text",
					type: "string",
					friendlyName: "Button text",
					required: true,
				},
				{
					name: "link",
					type: "string",
					friendlyName: "Button Link",
					helperText: "Provide a url path you would like to link too.",
					required: true,
				},
			],
			onChange: (options: any) => {
				const links = options.get("links") || [];

				// Make sure they can add no more than 4 items
				if (links.length > 4) {
					options.set("links", links.slice(0, 4));
					alert("The maximum number of links is 4");
				}
			},
		},
	],
});
