"use client";

import { useState } from "react";
import { useI18n } from "@/i18n/client";
import useStoreKeys from "@/hooks/useStoreKeys";
import { ProductAddToCart } from "@/lib/5874/types";
import { useSessionContext } from "@/lib/auth/SessionContextProvider";
import { useCartContext } from "@/providers/CartContextProvider";
import { getLineItemForProduct } from "@/utils/cart-utils";
import { Form } from "@radix-ui/react-form";
import { FormProvider, useForm } from "react-hook-form";
import { useProductContext } from "@/providers/ProductContextProvider";
import checkUserHasRolePermission from "@/hooks/checkUserHasRolePermission";
import {
	handleAddToCart,
	handleUpdateCartLineItem,
} from "./_actions/addToCart";
import ChooseOptionsCTA from "@/components/ProductCard/ChooseOptionsCTA/ChooseOptionsCTA";
import AddToWishlistCTA from "@/components/UI/AddToWishlistCTA/AddToWishlistCTA";
import QuantityChanger from "@/components/QuantityChanger/QuantityChanger";
import Button from "@/components/UI/Button/Button";
import Notification from "@/components/UI/Notification/Notification";
import styles from "./ProductForm.module.scss";
import useIntellisuggestTracking from "@/hooks/useIntelliSuggestTracking";
import { ProductAvailability } from "@/lib/bigcommerce/enums";
import {
	getCartDatalayerDetails,
	getItemListData,
	useDataLayerContext,
} from "@/datalayer/DataLayerContextProvider";

interface ProductFormProps {
	showAddToCart?: boolean;
}

export const ProductForm: React.FC<ProductFormProps> = ({
	showAddToCart = true,
}) => {
	const { session } = useSessionContext();
	const { store, lang } = useStoreKeys();
	const { cart, setCart, setIsModifyingCart, setConfirmationProduct } =
		useCartContext();
	const { product } = useProductContext();
	const { handleSubmit, register, ...methods } = useForm<ProductAddToCart>();
	const [showRemoveMessage, setShowRemoveMessage] = useState(false);
	const t = useI18n();

	if (!session) return null;

	if (
		!checkUserHasRolePermission("purchase_enable") &&
		!checkUserHasRolePermission("update_shopping_list_item")
	)
		return <></>;

	// Intellisuggest
	const { basketContents } = useIntellisuggestTracking();

	const lineItem = cart ? getLineItemForProduct(cart, product) : undefined;

	const productFormSubmit = async (data: ProductAddToCart) => {
		setIsModifyingCart(true);

		// Determine the result of the operation
		const res = lineItem
			? await handleUpdateCartLineItem(data, lineItem)
			: await handleAddToCart([data]);

		// Handle scenarios where the result has no data
		if (!res.data) {
			handleRemovedFromCart();
			setCart(res.data);
			setIsModifyingCart(false);
			return;
		}

		// Process the cart update on success
		if (res.status === "success") {
			const basket = res.data.line_items.physical_items;
			setCart(res.data);
			basketContents(basket);
			setConfirmationProduct(product);
		}

		setIsModifyingCart(false);
	};

	const handleRemovedFromCart = () => {
		setShowRemoveMessage(true);
		setTimeout(() => setShowRemoveMessage(false), 3000);
	};

	// Datalayer events
	const { addToCart } = useDataLayerContext();
	const { itemListId, itemListName } = getItemListData(product);
	const cartDetails = getCartDatalayerDetails(cart);
	const datalayerProduct = {
		product: product,
		quantity: 1,
		variantId: product.id,
	};

	return (
		<FormProvider handleSubmit={handleSubmit} register={register} {...methods}>
			<Form onSubmit={handleSubmit(productFormSubmit)}>
				<input type='hidden' value={product.id} {...register("id")} />
				<input
					type='hidden'
					value={lineItem ? lineItem.quantity : 1}
					{...register("quantity")}
				/>
				{showAddToCart && (
					<div className={styles.actions}>
						{checkUserHasRolePermission("purchase_enable") && (
							<>
								{product.productClass?.toLowerCase() === "kit" ||
								product.availability === ProductAvailability.UNAVAILABLE ? (
									<Button
										as='link'
										href={`/${store}/${lang}/${product.path}`}
										className={styles.cartButton}
									>
										{t("text.view")}
									</Button>
								) : product.productClass?.toLowerCase() === "parent" ? (
									<ChooseOptionsCTA
										productOptions={product?.children}
										handleRemovedFromCart={handleRemovedFromCart}
									/>
								) : lineItem !== undefined ? (
									<QuantityChanger
										cart={cart}
										minValue={0}
										value={lineItem.quantity}
										onChange={(quantity) => {
											methods.setValue("quantity", quantity);
											productFormSubmit(methods.getValues());
										}}
										debounceTime={600}
									/>
								) : (
									<Button
										as='button'
										type='submit'
										onClick={(e) => {
											e.stopPropagation();
											addToCart({
												products: [datalayerProduct],
												itemListId: itemListId,
												itemListName: itemListName,
												cart: cartDetails,
											});
										}}
										className={styles.cartButton}
									>
										{t("cart.addToCart")}
									</Button>
								)}
							</>
						)}
						{!product?.children &&
							product.productClass?.toLowerCase() !== "kit" &&
							checkUserHasRolePermission("update_shopping_list_item") && (
								<AddToWishlistCTA product={product} userId={session.userId} />
							)}
					</div>
				)}
			</Form>
			{showRemoveMessage && (
				<Notification
					type='toast'
					variant='success'
					title={t("cart.removedFromCart")}
				/>
			)}
		</FormProvider>
	);
};
