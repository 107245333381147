"use client";

import useStoreKeys from "@/hooks/useStoreKeys";
import { Cart, ProductData } from "@/lib/5874/types";
import { useSessionContext } from "@/lib/auth/SessionContextProvider";
import { getProductsWithoutChildrenByIds } from "@/lib/bigcommerce/product";
import {
	deleteClientCookie,
	getCartIdCookie,
	setClientCartCookie,
} from "@/utils/client-cookie-utils";
import {
	createContext,
	Dispatch,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";

interface ContextProps {
	cart: Cart | undefined;
	setCart: (cart: Cart | undefined) => void;

	isModifyingCart: boolean;
	setIsModifyingCart: (isModifyingCart: boolean) => void;

	confirmationProduct:
		| ProductData
		| { id: number; quantity: number }
		| undefined;
	setConfirmationProduct: (
		confirmationProduct: ProductData | undefined,
	) => void;
	totalItems: number;
	cartProducts: ProductData[] | undefined;
}

interface Props {
	initialCart: Cart | undefined;
	deleteCookie: boolean;
	children: React.ReactNode;
}

const Context = createContext({} as ContextProps);

export const CartContextProvider = ({
	initialCart,
	deleteCookie,
	children,
}: Props) => {
	const { store } = useStoreKeys();
	const { session } = useSessionContext();

	const [cart, setCart] = useState<Cart | undefined>(initialCart);
	const [confirmationProduct, setConfirmationProduct] = useState<
		ProductData | undefined
	>(undefined);
	const [isModifyingCart, setIsModifyingCart] = useState(false);
	const [cartProducts, setCartProducts] = useState<
		Array<ProductData> | undefined
	>();

	useEffect(() => {
		if (deleteCookie) {
			deleteClientCookie("cartId");
		}
	}, []);

	useEffect(() => {
		if (session === null && cart !== undefined) {
			setClientCartCookie(cart.id, store, 0);
			setCart(undefined);
		}

		// check to see if customerId in cookie matches session
		if (session !== null && cart === undefined) {
			const cookie = getCartIdCookie();

			if (
				cookie &&
				(cookie.customerId !== session.customerId || cookie.store !== store)
			) {
				deleteClientCookie("cartId");
			}
		}
	}, [session]);

	useEffect(() => {
		if (cart) {
			setClientCartCookie(cart.id, store, session?.customerId ?? 0);
		}
	}, [cart]);

	const totalItems = useMemo(() => {
		if (!cart) {
			return 0;
		}

		return cart.line_items.physical_items.reduce(
			(acc: number, item) => acc + item.quantity,
			0,
		);
	}, [cart]);

	useEffect(() => {
		const entityIds = cart?.line_items.physical_items.map((i) => i.product_id);
		if (entityIds === undefined) {
			return;
		}
		getProductsWithoutChildrenByIds(entityIds)
			.then((data) => {
				if (!data) {
					return undefined;
				}
				setCartProducts(data);
			})
			.catch((err) => console.error(err));

		return () => {
			setCartProducts([]);
		};
	}, [cart]);

	const value = {
		cart,
		totalItems,
		setCart,
		cartProducts,
		isModifyingCart,
		setIsModifyingCart,
		confirmationProduct,
		setConfirmationProduct,
	};

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCartContext = () => {
	const context = useContext(Context);

	if (!context) {
		throw new Error("useCartContext must be used within a CartContextProvider");
	}

	return context;
};
