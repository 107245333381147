"use client";

import { Builder } from "@builder.io/react";
import clsx from "clsx";
import React from "react";
import Button from "../UI/Button/Button";
import styles from "./TwoPanelContentDisplay.module.scss";

type TwoPanelProps = {
	contentBox: {
		title: string;
		description: string;
		image: string;
		altText: string;
		buttonText: string;
		link: string;
	}[];
};

const TwoPanelContentDisplay: React.FC<TwoPanelProps> = ({ contentBox }) => {
	if (contentBox.length === 0) {
		return null;
	}

	return (
		<section className={styles.section}>
			<div className={clsx([styles.content, "container"])}>
				{contentBox?.map((box, idx) => (
					<div key={`${idx}-${box.link}`} className={styles.contentBox}>
						<img
							className={styles.bottomRightCurve}
							src={`${box.image}?format=webp`}
							alt={box.altText}
						/>
						<div className={styles.contentText}>
							{box.title && <h3>{box.title}</h3>}
							{box.description && <p>{box.description}</p>}
							{box.buttonText && (
								<Button
									variant='secondary'
									as='link'
									href={box.link}
									className={styles.button}
								>
									{box.buttonText}
								</Button>
							)}
						</div>
					</div>
				))}
			</div>
		</section>
	);
};

export default TwoPanelContentDisplay;

Builder.registerComponent(TwoPanelContentDisplay, {
	name: "TwoPanelContentDisplay",
	inputs: [
		{
			name: "contentBox",
			type: "list",
			defaultValue: [
				{
					image: "https://placehold.co/710x355",
					altText: "add alt text",
					title: "Add Title",
					description: "Add Description",
					buttonText: "Learn More",
					link: "#",
				},
			],
			subFields: [
				{
					name: "image",
					friendlyName: "Image",
					helperText: "Add an image with aspect ratio 21:9 (710 x 338)",
					type: "file",
					allowedFileTypes: ["jpeg", "jpg", "png"],
					defaultValue: "https://placehold.co/710x355",
					required: true,
				},
				{
					name: "altText",
					friendlyName: "Alt Text",
					type: "string",
					defaultValue: "add alt text",
				},
				{
					name: "title",
					friendlyName: "Title",
					type: "longText",
					defaultValue: "Add Title",
				},
				{
					name: "description",
					friendlyName: "Description",
					type: "longText",
					defaultValue: "Add Description",
				},
				{
					name: "buttonText",
					friendlyName: "Button Text",
					type: "string",
					defaultValue: "Learn More",
				},
				{
					name: "link",
					friendlyName: "Link",
					type: "string",
					defaultValue: "#",
				},
			],
			onChange: (options: any) => {
				const contentBox = options.get("contentBox") || [];

				if (contentBox.length > 2) {
					options.set("contentBox", contentBox.slice(0, 2));
					alert("The maximum number of content boxes is 2");
				}
			},
		},
	],
});
