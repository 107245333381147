export enum StorefrontStatusType {
	HIBERNATION = "HIBERNATION",
	LAUNCHED = "LAUNCHED",
	MAINTENANCE = "MAINTENANCE",
	PRE_LAUNCH = "PRE_LAUNCH",
}

export enum ProductOutOfStockBehavior {
	DO_NOTHING = "DO_NOTHING",
	HIDE_PRODUCT = "HIDE_PRODUCT",
	HIDE_PRODUCT_AND_ACCESSIBLE = "HIDE_PRODUCT_AND_ACCESSIBLE",
	HIDE_PRODUCT_AND_REDIRECT = "HIDE_PRODUCT_AND_REDIRECT",
}

export enum OptionOutOfStockBehavior {
	DO_NOTHING = "DO_NOTHING",
	HIDE_OPTION = "HIDE_OPTION",
	LABEL_OPTION = "LABEL_OPTION",
}

export enum TaxPriceDisplay {
	BOTH = "BOTH",
	EX = "EX",
	INC = "INC",
}

export enum ProductCondition {
	NEW = "NEW",
	REFURBISHED = "REFURBISHED",
	USED = "USED",
}

export enum ProductAvailability {
	AVAILABLE = "Available",
	PREORDER = "Preorder",
	UNAVAILABLE = "Unavailable",
}
