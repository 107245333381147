"use client";

import React, { useEffect, useState } from "react";
import styles from "./LocalWebsiteSelector.module.scss";
import { Builder } from "@builder.io/react";
import { builder } from "@builder.io/sdk";
import * as Select from "@radix-ui/react-select";
import Button from "../UI/Button/Button";
import IconArrow from "../UI/Icon/IconArrow/IconArrow";
import Markdown from "../UI/Markdown/Markdown";
import { initBuilder } from "@/lib/builder/builder-utils";

interface LocalWebsiteSelectorProps {
	leftSideContent: {
		content: string;
		showButton: boolean;
		button: {
			buttonText: string;
			buttonLink: string;
			leftSideAlternativeAria: boolean;
			ariaLabel: string;
		};
	};
	localWebsiteSelector: {
		title: string;
		subtitle: string;
		description: string;
		dropdownHeader: string;
		button: {
			buttonText: string;
			alternativeAria: boolean;
			ariaLabel: string;
		};
	};
}

const LocalWebsiteSelector: React.FC<LocalWebsiteSelectorProps> = ({
	leftSideContent,
	localWebsiteSelector,
}) => {
	const [regionDropdowns, setRegionDropdowns] = useState<
		{ title: string; link: string; browserLanguageCode: string }[]
	>([]);
	const [selectedRegion, setSelectedRegion] = useState<string>("");
	const [formattedLink, setFormattedLink] = useState<string>("");

	initBuilder("global");
	useEffect(() => {
		getRegions();
	}, []);

	const getRegions = async () => {
		await builder.getAll("local-regions", {}).then((regions) => {
			const definedRegions = regions.map((region) => {
				return {
					title: region?.data?.title,
					link: region?.data?.link,
					browserLanguageCode: region?.data?.browserLanguageCode,
				};
			});
			return setRegionDropdowns(definedRegions);
		});
	};

	const handleRegion = (region: string) => {
		setSelectedRegion(region);
		const formatString = region.search("//");
		if (formatString === -1) {
			return setFormattedLink(region);
		}
		setFormattedLink(region.split("//")[1]);
	};

	const handleDefaultValues = (code: any, type: string): string => {
		const filteredRegion = regionDropdowns.find(
			(region) => region.browserLanguageCode === code,
		);
		if (!filteredRegion) return ""; // Handle no matching region case

		switch (type) {
			case "title":
				return filteredRegion.title;
			case "url":
				return filteredRegion.link;
			default:
				return filteredRegion.link.split("//")[1];
		}
	};

	const { leftSideAlternativeAria, ariaLabel, buttonLink, buttonText } =
		leftSideContent.button;
	const {
		buttonText: selectorButtonText,
		ariaLabel: selectorAriaLabel,
		alternativeAria,
	} = localWebsiteSelector.button;

	const splittingTitle = localWebsiteSelector?.title.split(".");

	return (
		<section className={styles.LocalWebsiteSelectorSection}>
			<div className={styles.leftSideContent}>
				<Markdown content={leftSideContent.content} />
				{leftSideContent.showButton && (
					<Button
						as='link'
						variant='secondary'
						href={buttonLink}
						aria-label={leftSideAlternativeAria ? ariaLabel : buttonText}
					>
						{buttonText}
					</Button>
				)}
			</div>
			<div className={styles.LocalWebsiteSelector}>
				<div className={styles.title}>
					{splittingTitle.map((splitTitle, index) =>
						splitTitle.length ? (
							<React.Fragment key={index}>
								{splitTitle}.<br />
							</React.Fragment>
						) : (
							""
						),
					)}
				</div>

				{localWebsiteSelector.subtitle && (
					<Markdown
						content={localWebsiteSelector.subtitle}
						className={styles.subtitle}
					/>
				)}
				{localWebsiteSelector.description && (
					<Markdown
						className={styles.description}
						content={localWebsiteSelector.description}
					/>
				)}
				{localWebsiteSelector.dropdownHeader && (
					<label htmlFor='select'>{localWebsiteSelector.dropdownHeader}</label>
				)}
				<div id='select'>
					<Select.Root
						onValueChange={(value) => handleRegion(value)}
						value={selectedRegion}
					>
						<Select.Trigger
							className={styles.trigger}
							aria-label={`Go to ${selectedRegion}`}
						>
							<Select.Value
								className={styles.value}
								placeholder={handleDefaultValues(
									regionDropdowns[0]?.browserLanguageCode,
									"title",
								)}
							/>
							<IconArrow
								direction='down'
								aria-labelledby='directional chevron pointing down'
							/>
						</Select.Trigger>
						<Select.Portal>
							<Select.Content className={styles.content}>
								<Select.Viewport className={styles.viewport}>
									{regionDropdowns?.map((dropdown) => (
										<Select.Item
											key={`${dropdown.title}-${dropdown.browserLanguageCode}`}
											value={dropdown.link}
											className={styles.item}
										>
											<Select.ItemText className={styles.item}>
												{dropdown.title}
											</Select.ItemText>
										</Select.Item>
									))}
								</Select.Viewport>
							</Select.Content>
						</Select.Portal>
					</Select.Root>
				</div>
				<Button
					as='link'
					variant='primary'
					target='_blank'
					href={
						selectedRegion
							? selectedRegion
							: handleDefaultValues(
									regionDropdowns[0]?.browserLanguageCode,
									"url",
							  )
					}
					aria-label={alternativeAria ? selectorAriaLabel : selectorButtonText}
				>
					{selectorButtonText}
				</Button>
				{regionDropdowns && (
					<p className={styles.link}>
						Online store: &nbsp;
						<span>
							{formattedLink !== ""
								? formattedLink
								: handleDefaultValues(
										regionDropdowns[0]?.browserLanguageCode,
										"",
								  )}
						</span>
					</p>
				)}
			</div>
		</section>
	);
};

export default LocalWebsiteSelector;

Builder.registerComponent(LocalWebsiteSelector, {
	name: "Local Website Selector",
	inputs: [
		{
			name: "leftSideContent",
			type: "object",
			defaultValue: {
				content: "Add your content here",
				showButton: true,
				button: {
					buttonText: "Button text",
					buttonLink: "#",
				},
			},
			subFields: [
				{
					name: "content",
					type: "richText",
					defaultValue: "content goes here",
					helperText:
						"'Heading 3' for titles & 'Normal' for text | ~725 characters",
				},
				{
					name: "showButton",
					type: "boolean",
					defaultValue: true,
					helperText: "Enabled to display a button under the content",
				},
				{
					name: "button",
					type: "object",
					defaultValue: {
						buttonText: "Button Text",
						buttonLink: "#",
						leftSideAlternativeAria: false,
						ariaLabel: "aria-label",
					},
					subFields: [
						{
							name: "buttonText",
							type: "longText",
							defaultValue: "Button Text",
							helperText: "~15 characters",
						},
						{
							name: "buttonLink",
							friendlyName: "Link",
							type: "longText",
							defaultValue: "#",
						},
						{
							name: "leftSideAlternativeAria",
							friendlyName: "Add Custom Link Aria Label?",
							type: "boolean",
							defaultValue: false,
						},
						{
							name: "ariaLabel",
							friendlyName: "Aria Label",
							type: "long-text",
							showIf: `options.get('leftSideAlternativeAria')`,
						},
					],
				},
			],
		},
		{
			name: "localWebsiteSelector",
			type: "object",
			defaultValue: {
				title: "Global Access. Local Support.",
				subtitle: "Browse and shop online using our dedicated regional stores.",
				description: "This is the description text",
				dropdownHeader: "Region and language",
				button: {
					buttonText: "Order Online",
				},
			},
			subFields: [
				{
					name: "title",
					type: "richText",
					defaultValue: "Global Access. Local Support.",
					helperText: "~25 characters",
				},
				{
					name: "subtitle",
					type: "richText",
					defaultValue:
						"Browse and shop online using our dedicated regional stores.",
					helperText: "~30 characters",
				},
				{
					name: "description",
					type: "richText",
					defaultValue: "This is the description text",
					helperText: "~225 characters",
				},
				{
					name: "dropdownHeader",
					type: "longText",
					defaultValue: "Region and language",
					helperText: "~25 characters",
				},
				{
					name: "button",
					type: "object",
					defaultValue: {
						buttonText: "Order Online",
					},
					subFields: [
						{
							name: "buttonText",
							type: "longText",
							helperText: "~15 characters",
						},
						{
							name: "alternativeAria",
							friendlyName: "Add Custom Aria Label?",
							type: "boolean",
							defaultValue: false,
						},
						{
							name: "ariaLabel",
							friendlyName: "Aria Label",
							type: "long-text",
							showIf: `options.get('alternativeAria')`,
						},
					],
				},
			],
		},
	],
});
