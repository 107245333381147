import { usePathname, useSearchParams } from "next/navigation";

const useCurrentPath = () => {
	const path = usePathname();
	const searchParams = useSearchParams();

	return searchParams.toString() ? `${path}?${searchParams.toString()}` : path;
};

export default useCurrentPath;
