import Arrow from "@/app/assets/images/svg/movora-arrow.svg";
import clsx from "clsx";
import { FC, SVGProps } from "react";
import Icon from "../Icon";
import styles from "./IconArrow.module.scss";

interface Props extends SVGProps<typeof Icon> {
	direction: "left" | "right" | "up" | "down" | "next" | "previous";
	carouselArrows?: boolean;
}

const IconArrow: FC<Props> = ({
	direction = "left",
	carouselArrows,
	...props
}) => {
	return (
		<Icon
			icon={
				<Arrow
					className={clsx([
						styles.icon,
						styles[`direction--${direction}`],
						props.className,
					])}
					{...props}
				/>
			}
		/>
	);
};

export default IconArrow;
