"use client";

import { Builder, BuilderBlocks } from "@builder.io/react";
import clsx from "clsx";
import React from "react";
import Markdown from "../UI/Markdown/Markdown";
import styles from "./BlueAndWhiteBlock.module.scss";

interface BlueAndWhiteBlockProps {
	titleLeft: string;
	contentLeft: string;
	titleRight: string;
}

const BlueAndWhiteBlock: React.FC<BlueAndWhiteBlockProps> = ({
	titleLeft,
	contentLeft,
	titleRight,
	...props
}) => {
	return (
		<section className={styles.section}>
			<div className={styles.contentWrapper}>
				<div className={styles.contentLeft}>
					<h2>{titleLeft}</h2>
					<Markdown className={styles.markdown} content={contentLeft} />
				</div>
				<div className={styles.contentRight}>
					<h2>{titleRight}</h2>
					<BuilderBlocks
						parentElementId={(props as any).builderBlock.id}
						blocks={(props as any).builderBlock.children}
					/>
				</div>
			</div>
		</section>
	);
};

export default BlueAndWhiteBlock;

Builder.registerComponent(BlueAndWhiteBlock, {
	name: "Blue And White Block",
	canHaveChildren: true,
	defaults: {
		component: {
			name: "Blue And White Block",
			options: {
				titleLeft: "Title",
				contentLeft:
					"Add content here. Use H3 tags for titles and p tags for content",
				titleRight: "Title",
				contentRight:
					"Add content here. Use H3 tags for titles and p tags for content",
			},
		},
	},
	inputs: [
		{
			name: "titleLeft",
			friendlyName: "Left Block Title",
			type: "longText",
			required: true,
			helperText: "~30 characters",
		},
		{
			name: "contentLeft",
			friendlyName: "Left Block Content",
			helperText: "Style with paragraphs and H3 headers | ~200 characters",
			type: "richText",
			required: true,
		},
		{
			name: "titleRight",
			friendlyName: "Right Block Title",
			type: "Right Block Title",
			required: true,
			helperText: "~25 characters",
		},
	],
});
