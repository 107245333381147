"use client";
import { Builder } from "@builder.io/react";
import React from "react";
import Markdown from "../UI/Markdown/Markdown";
import styles from "./QuadGrid.module.scss";

interface QuadGridProps {
	title: string;
	description: string;
	images: [
		{
			imageTitle: string;
			imageSrc: string;
			altText: string;
		},
	];
}

const QuadGrid: React.FC<QuadGridProps> = ({ title, description, images }) => {
	return (
		<section className={styles.container}>
			<div className={styles.innerWrapper}>
				<div className={styles.content}>
					<h6>{title}</h6>
					<Markdown className={styles.markdown} content={description} />
				</div>
				<div className={styles.imagesWrapper}>
					{images &&
						images.map((image) => (
							<div className={styles.imgContainer} key={image.imageSrc}>
								<img
									src={`${image.imageSrc}?format=webp`}
									alt={image.altText}
								/>
								<p className={styles.text}>{image.imageTitle}</p>
							</div>
						))}
				</div>
			</div>
		</section>
	);
};

export default QuadGrid;

Builder.registerComponent(QuadGrid, {
	name: "Quad Grid",
	inputs: [
		{
			name: "title",
			friendlyName: "Title",
			type: "string",
			defaultValue: "Title",
			helperText: "~25 characters",
		},
		{
			name: "description",
			friendlyName: "Description",
			type: "richText",
			defaultValue: "Description",
			helperText: "~250 characters",
		},
		{
			name: "images",
			friendlyName: "Images (Max 4)",
			type: "list",
			onChange: (options: any) => {
				if (options.get("images").length > 4) {
					options.set("images", options.get("images").slice(0, 4));
					alert("The maximum number of images is 4");
				}
			},
			defaultValue: [
				{
					imageTitle: "Image Title",
					imageSrc: "https://placehold.co/465x360",
					altText: "Alt text",
				},
			],
			subFields: [
				{
					name: "imageTitle",
					friendlyName: "Image Title",
					type: "string",
					defaultValue: "Image Title",
					helperText: "~40 characters",
				},
				{
					name: "imageSrc",
					type: "file",
					friendlyName: "Image",
					defaultValue: "https://placehold.co/465x360/webp",
					helperText: "465×360 px | .jpeg, .jpg, .png | ~300 KB",
					allowedFileTypes: ["jpeg", "jpg", "png"],
				},
				{
					name: "altText",
					type: "string",
					friendlyName: "Alt text",
					defaultValue: "Alt text",
					required: true,
				},
			],
		},
	],
});
