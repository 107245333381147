"use client";

import AddToListIcon from "@/app/assets/images/svg/wishlist_add.svg";
import Modal from "@/components/Modal/Modal";
import { useI18n } from "@/i18n/client";
import { ProductData } from "@/lib/5874/types";
import { addItemToWishlist } from "@/lib/bigcommerce-b2b/api/account/wishlist";
import { createWishlist } from "@/lib/bigcommerce-b2b/api/account/wishlist";
import { WishlistDetailsData } from "@/lib/bigcommerce-b2b/types";
import React, { FC, useState, useEffect } from "react";
import WishlistEditModal from "../Account/Wishlist/WishlistEditModal/WishlistEditModal";
import Button from "../Button/Button";
import IconOnlyButton from "../Button/IconOnlyButton/IconOnlyButton";
import Notification from "../Notification/Notification";
import AddToListWishlistListing from "./AddToListWishlistListing/AddToListWishlistListing";
import {
	getItemListData,
	useDataLayerContext,
} from "@/datalayer/DataLayerContextProvider";
import { useSessionContext } from "@/lib/auth/SessionContextProvider";

interface AddToWishlistCTAProps {
	product: ProductData;
	userId?: number;
	customClass?: string;
	handleParentModal?: () => void;
	handleAddToWishlist?: (
		productId: number,
	) => Promise<WishlistDetailsData | undefined>;
	wishlistId?: number;
}

const AddToWishlistCTA: FC<AddToWishlistCTAProps> = ({
	product,
	userId,
	customClass,
	handleParentModal,
	wishlistId,
	handleAddToWishlist,
}) => {
	if (!userId) return null;
	const [wishlistModalOpen, setWishlistModalOpen] = useState(false);
	const [wishlistModalStep, setWishlistModalStep] = useState(
		wishlistId ? 4 : 1,
	);

	// Datalayer
	const { addToWishlist } = useDataLayerContext();
	const { itemListId, itemListName } = getItemListData(product);

	const { session } = useSessionContext();

	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const t = useI18n();

	const handleOpenWishlistModal = () => {
		if (handleParentModal) handleParentModal();
		setWishlistModalOpen(true);
	};

	const handleCloseWishlistModal = () => {
		setWishlistModalOpen(false);
		setWishlistModalStep(1);
		if (handleParentModal) handleParentModal();
	};

	const handleCreateWishlist = async (name: string) => {
		const data = {
			userId: Number(userId),
			name: name,
			status: 0,
			items: [{ productId: product.id, quantity: 1 }],
		};
		// Datalayer
		addToWishlist(
			product,
			1,
			product.id,
			itemListId,
			itemListName,
			session?.currencyCode ?? "",
		);

		const newWishlistInfo = await createWishlist(data);

		if (newWishlistInfo && newWishlistInfo.code === 200) {
			handleCloseWishlistModal();
			setShowSuccessMessage(true);
			setTimeout(() => setShowSuccessMessage(false), 3000);
		}
	};

	const handleAddProductToList = async (
		productId: number,
		wishlistId: number,
	) => {
		if (!wishlistId) return;
		setIsLoading(true);

		let responsePromise: Promise<WishlistDetailsData | undefined>;
		addToWishlist(
			product,
			1,
			productId,
			itemListId,
			itemListName,
			session?.currencyCode ?? "",
		);
		if (handleAddToWishlist) {
			responsePromise = handleAddToWishlist(productId);
		} else {
			responsePromise = addItemToWishlist(wishlistId, productId);
		}
		const wishlistResponse = await responsePromise;

		if (wishlistResponse) {
			handleCloseWishlistModal();
			setShowSuccessMessage(true);
			setTimeout(() => setShowSuccessMessage(false), 3000);
		}

		setIsLoading(false);
	};

	useEffect(() => {
		if (wishlistModalStep === 0) handleCloseWishlistModal();
	}, [wishlistModalStep, handleCloseWishlistModal]);

	return (
		<>
			{wishlistId ? (
				<Button
					as='button'
					type='button'
					variant='secondary'
					onClick={(e) => {
						e.stopPropagation();
						handleAddProductToList(product.id, wishlistId);
					}}
					aria-label={t("account.wishlist.addToList")}
					className={customClass}
					isLoading={isLoading}
				>
					{t("account.wishlist.addToList")}
				</Button>
			) : (
				<IconOnlyButton
					as='button'
					type='button'
					variant='secondary'
					onClick={(e) => {
						e.stopPropagation();
						handleOpenWishlistModal();
					}}
					aria-label={t("account.wishlist.addToList")}
					icon={<AddToListIcon />}
					className={customClass}
				/>
			)}
			<Modal
				ariaLabel={t("account.wishlist.addToList")}
				contentId='add-to-wishlist-modal'
				isOpen={wishlistModalOpen}
				toggleModal={handleCloseWishlistModal}
				title={t("account.wishlist.addToList")}
			>
				{wishlistModalStep === 1 && (
					<AddToListWishlistListing
						productId={product.id}
						userId={userId}
						setStep={setWishlistModalStep}
						handleAddProduct={handleAddProductToList}
					/>
				)}
				{wishlistModalStep === 2 && (
					<WishlistEditModal
						createNewWishlist={true}
						handleUpdateWishlist={handleCreateWishlist}
					/>
				)}
			</Modal>
			{showSuccessMessage && (
				<Notification
					type='toast'
					variant='success'
					title={t("account.wishlist.addedToList")}
				/>
			)}
		</>
	);
};

export default AddToWishlistCTA;
