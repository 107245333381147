import { useSessionContext } from "@/lib/auth/SessionContextProvider";

const checkUserHasRolePermission = (
	role: string,
	rolePermissions?: { actions: string[] },
	permissionLevel?: number,
) => {
	let permissions: string[] | undefined;

	if (!rolePermissions) {
		const { session } = useSessionContext();

		permissions = session?.rolePermissions?.actions;
	} else {
		permissions = rolePermissions?.actions;
	}

	return permissions?.some((permission) => {
		const [permissionRole, level] = permission.split("|"); // Split the permission string
		if (!permissionLevel) return permissionRole === role;

		return permissionRole === role && permissionLevel === Number(level);
	});
};

export default checkUserHasRolePermission;

// These are all the known available roles
// "get_addresses",
// "get_address_detail",
// "get_default_shipping",
// "get_default_billing",
// "create_address",
// "update_address",
// "delete_address",
// "get_invoices",
// "get_invoice_detail",
// "export_invoices",
// "get_invoice_pdf",q
// "get_invoice_payments_history",
// "pay_invoice",
// "get_orders",
// "get_order_detail",
// "get_quotes",
// "get_quote_detail",
// "create_quote",
// "update_quote_message",
// "checkout_with_quote",
// "get_quote_pdf",
// "get_shopping_lists",
// "get_shopping_list_detail",
// "create_shopping_list",
// "deplicate_shopping_list",
// "update_shopping_list",
// "approve_draft_shopping_list",
// "delete_shopping_list",
// "create_shopping_list_item",
// "update_shopping_list_item",
// "delete_shopping_list_item",
// "get_users",
// "get_user_detail",
// "create_user",
// "update_user",
// "delete_user",
// "get_roles",
// "get_role_detail",
// "create_role",
// "update_role",
// "delete_role",
// "purchase_enable"
