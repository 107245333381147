"use client";

import React, { useEffect } from "react";
import styles from "./Representatives.module.scss";
import builder, { Builder } from "@builder.io/react";
import { initBuilder } from "@/lib/builder/builder-utils";
import Markdown from "../UI/Markdown/Markdown";
import useIsPreviewingBuilder from "@/hooks/useIsPreviewingBuilder";

interface SelectRep {
	value: {
		data?: {
			name: string;
			title: string;
			location?: string;
			email: string;
			phoneNumber?: string;
			image: string;
			imageAltText: string;
		};
	};
}
interface RepresentativesProps {
	mainRepresentative: {
		selectRepresentative: SelectRep;
		quote: string;
	};
	representatives: [
		{
			selectRepresentative: SelectRep;
		},
	];
}

const Representatives: React.FC<RepresentativesProps> = ({
	mainRepresentative,
	representatives,
}) => {
	const { publicKey } = initBuilder("global");

	const { isEditingBuilder } = useIsPreviewingBuilder();

	const [mainRepresentativeData, setMainRepresentativeData] =
		React.useState<any>(null);

	const [representativesData, setRepresentativesData] = React.useState<any>([]);

	useEffect(() => {
		if (!isEditingBuilder) {
			setMainRepresentativeData(
				mainRepresentative?.selectRepresentative?.value,
			);
			setRepresentativesData(
				representatives?.map(
					(representative) => representative?.selectRepresentative?.value,
				),
			);
		}

		if (mainRepresentative?.selectRepresentative) {
			builder
				.get("representatives", {
					apiKey: publicKey,
					query: {
						id: (mainRepresentative.selectRepresentative as any).id,
					},
				})
				.toPromise()
				.then((res) => setMainRepresentativeData(res));
		}

		if (representatives?.length) {
			builder
				.getAll("representatives", {
					apiKey: publicKey,
					query: {
						id: {
							$in: representatives?.map(
								(rep: any) => rep.selectRepresentative?.id,
							),
						},
					},
				})
				.then((res) => {
					const sorted = res.sort((a: any, b: any) => {
						const indexA = representatives.findIndex(
							(rep: any) => rep.selectRepresentative?.id === a.id,
						);

						const indexB = representatives.findIndex(
							(rep: any) => rep.selectRepresentative?.id === b.id,
						);

						if (indexA < indexB) return -1;
						if (indexA > indexB) return 1;
						return 0;
					});

					setRepresentativesData(sorted);
				});
		}
	}, [isEditingBuilder, mainRepresentative]);

	const { name, title, location, email, phoneNumber, image, imageAltText } =
		mainRepresentativeData?.data || {};

	return (
		<section className={styles.representativesSection}>
			<div className={styles.representativesWrapper}>
				<div className={styles.mainRepresentative}>
					<img src={`${image}?format=webp`} alt={imageAltText} />
					<div className={styles.representativeText}>
						<Markdown
							content={mainRepresentative?.quote}
							className={styles.markdown}
						/>
						<div>
							<p className={styles.name}>{name}</p>
							<p className={styles.job}>
								{title} {location && `- ${location}`}{" "}
							</p>
						</div>
						<div>
							<a href={`mailto:${email}`}>
								<p>{email}</p>
							</a>
							{phoneNumber && (
								<a href={`tel:${phoneNumber}`}>
									<p className={styles.phone}>{phoneNumber}</p>
								</a>
							)}
						</div>
					</div>
				</div>
				<div className={styles.otherRepresentatives}>
					<h3>Representatives</h3>
					<div className={styles.representatives}>
						{representativesData &&
							representativesData?.map((rep: any) => (
								<div key={rep.id} className={styles.otherRep}>
									<img
										src={`${rep.data?.image}?format=webp`}
										alt={rep.data?.imageAltText}
									/>
									<div className={styles.representativeText}>
										<div>
											<p>{rep.data?.name}</p>
											{/* {rep.data?.title && <p>{rep.data?.title}</p>}
											{rep.data?.location && <p>{rep.data?.location}</p>} */}
											<a href={`mailto:${rep.data?.email}`}>
												<p>{rep.data?.email}</p>
											</a>
											{/* {rep.data?.phoneNumber && <p>{rep.data?.phoneNumber}</p>} */}
										</div>
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Representatives;

Builder.registerComponent(Representatives, {
	name: "Representatives",
	inputs: [
		{
			name: "mainRepresentative",
			type: "object",
			subFields: [
				{
					name: "selectRepresentative",
					type: "reference",
					model: "representatives",
				},
				{
					name: "quote",
					type: "richText",
					required: true,
					defaultValue: "Add in your quote here",
				},
			],
			defaultValue: {
				quote: "Type in your quote message here",
			},
		},
		{
			name: "representatives",
			type: "list",
			subFields: [
				{
					name: "selectRepresentative",
					type: "reference",
					model: "representatives",
				},
			],
		},
	],
});
