"use client";
import { getBuilderStatus, initBuilder } from "@/lib/builder/builder-utils";
import { getSpaceDetails, StoreRegion } from "@/lib/builder/builder.config";
import { BuilderComponent } from "@builder.io/react";
import { BuilderContent } from "@builder.io/sdk";
import { notFound } from "next/navigation";
import "../builder-registry";

interface BuilderPageProps {
	model?: string;
	store: StoreRegion;
	content?: BuilderContent;
	no404?: boolean;
}

export function RenderBuilderContent({
	model = "page",
	store,
	content,
	no404 = false,
}: BuilderPageProps) {
	const config = initBuilder(store);

	// Call the useIsPreviewing hook to determine if
	// the page is being previewed in Builder
	const { isPreviewing } = getBuilderStatus();

	// If "content" has a value or the page is being previewed in Builder,
	// render the BuilderComponent with the specified content and model props.
	if (content || isPreviewing) {
		return (
			<BuilderComponent
				content={content}
				model={model}
				apiKey={config.publicKey}
			/>
		);
	}

	if (!no404) {
		// If the "content" is falsy and the page is
		// not being previewed in Builder, render the
		// DefaultErrorPage with a 404.
		return notFound();
	}

	return null;
}
