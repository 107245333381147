import {
	BeaconCategory,
	BeaconType,
	BeaconContext,
	Tracker,
} from "@searchspring/snap-tracker";
import SwiperType from "swiper";
import { Image } from "@/lib/5874/types";
import { CartPhysicalItem, ProductData } from "@/lib/5874/types";
import { v4 } from "uuid";
import { getConfig } from "./searchspring.config";
import { StoreRegion } from "../builder/builder.config";

export type BeaconEventData = {
	type: BeaconType;
	category: BeaconCategory;
	event: any;
	context: BeaconContext;
};

type SsCookieData = { ssUserId?: string; ssSessionId?: string };

export type PlacementType =
	| "product-page"
	| "category-page"
	| "cart-page"
	| "blog-page"
	| "home-page"
	| "other";

export interface CommonArgs {
	siteId: string;
	pageLoadId: string;
	ssCookieData: SsCookieData;
}
export interface AdditionalArgs {
	tag: string;
	placement: PlacementType;
	skus?: string[];
	placementProductId: string;
}

interface AllBeaconArgs extends CommonArgs, AdditionalArgs {
	products: ProductData[];
}

type CartLineItemDataType = {
	uid: string;
	sku: string;
	name: string;
	path: string;
	imageUrl: string;
	price?: number;
	msrp?: number;
};

/*
GENERIC BEACON SEND FUNCTION
args: BeaconPayload, Store
*/

export const genericSendBeaconEvent = (
	events: BeaconEventData[],
	store: StoreRegion,
) => {
	const configs = getConfig(store);
	const globals = configs.globals;
	const tracker = new Tracker(globals);
	tracker.sendEvents(events);
};

/**
  Product Click
**/

type ClickEventArgs = {
	href: string;
	product: ProductData;
};

export type ClickData = CommonArgs & AdditionalArgs & ClickEventArgs;
export function beaconTrackProductClick(
	clickData: ClickData,
	store: StoreRegion,
) {
	const { siteId, pageLoadId, ssCookieData, tag, placement, skus, product } =
		clickData;

	const configs = getConfig(store);
	const globals = configs.globals;

	const tracker = new Tracker(globals);
	const payload = beaconTrackProfileClick(clickData);
	const sku = product.id;

	const productPayload = {
		type: BeaconType.PROFILE_PRODUCT_CLICK,
		category: BeaconCategory.INTERACTION,
		event: {
			product: {
				id: product.id,
				...(skus && skus.length > 0 && { seed: { sku } }),
				mappings: {
					core: {
						sku: product.sku,
						name: product.name,
						imageUrl: extractImageUrl(product.media.images, "mainImage"),
						url: product.path,
						thumbnailImageUrl: extractImageUrl(
							product.media.images,
							"thumbnail",
						),
						price: product.pricesExTax?.price?.value,
						msrp: product.pricesExTax?.basePrice?.value,
					},
				},
			},
			context: {
				type: "product-recommendation",
				tag,
				placement: placement as PlacementType,
			},
		},
		context: {
			website: {
				trackingCode: siteId ?? "unknown",
			}, // TODO: note that this .env variable does not exist
			userId: ssCookieData.ssUserId,
			sessionId: ssCookieData.ssSessionId,
			pageLoadId,
		},
	};
	tracker.track.event(payload);
	tracker.track.event(productPayload);
}

/**
  Product View
**/
export function beaconTrackProductView(
	viewData: CommonArgs & { product: ProductData },
) {
	const { product, siteId, pageLoadId, ssCookieData } = viewData;
	const payload = {
		type: BeaconType.PRODUCT,
		id: v4(),
		pid: null,
		event: {
			sku: product.sku,
		},
		category: BeaconCategory.INTERACTION,
		context: {
			website: {
				trackingCode: siteId ?? "unknown",
			}, // TODO: note that this .env variable does not exist
			userId: ssCookieData.ssUserId,
			sessionId: ssCookieData.ssSessionId,
			pageLoadId,
		},
	};

	return payload;
}

/**
  Products Render
**/
export const beaconTrackProductsRender = (trackingData: AllBeaconArgs) => {
	const parentId = v4();
	const {
		products,
		ssCookieData,
		siteId,
		pageLoadId,
		tag,
		placement,
		skus,
		placementProductId,
	} = trackingData;
	const payload = {
		type: BeaconType.PROFILE_RENDER,
		id: parentId,
		event: {
			profile: {
				tag,
				placement,
				seed: [{ sku: placementProductId }],
			},
			context: {
				type: "product-recommendation",
				tag,
				placement,
			},
		},
		category: BeaconCategory.RECOMMENDATIONS,
		context: {
			website: { trackingCode: siteId ?? "unknown" },
			userId: ssCookieData.ssUserId,
			sessionId: ssCookieData.ssSessionId,
			pageLoadId,
		},
	};

	const productPayload = products.map((product) => {
		const sku = product.id;
		const payload = {
			type: BeaconType.PROFILE_PRODUCT_RENDER,
			id: v4(),
			pid: parentId,
			event: {
				product: {
					id: product.id,
					...(skus &&
						skus.length > 0 && {
							seed: { sku },
						}),
					mappings: {
						core: {
							uid: product.id,
							sku: product.sku,
							name: product.name,
							url: product.path,
							imageUrl:
								product.media.images.map((i) => i.url.standard).toString() ??
								"",
							thumbnailImageUrl:
								product.media.images.map((i) => i.url.thumbnail).toString() ??
								"",
							price: product.pricesExTax?.price?.value,
							msrp: product.pricesExTax?.basePrice?.value,
						},
					},
				},
				context: {
					type: "product-recommendation",
					tag,
					placement,
				},
			},
			category: BeaconCategory.RECOMMENDATIONS,
			context: {
				website: { trackingCode: siteId ?? "unknown" },
				userId: ssCookieData.ssUserId,
				sessionId: ssCookieData.ssSessionId,
				pageLoadId,
			},
		};
		return payload;
	});
	return [payload, ...productPayload];
};

/**
  Products Impression
**/
export const beaconTrackProductsImpression = (
	impressionData: AllBeaconArgs,
) => {
	const parentId = v4();
	const {
		products,
		siteId,
		pageLoadId,
		ssCookieData,
		tag,
		placement,
		skus,
		placementProductId,
	} = impressionData;
	const payload = beaconTrackProfileImpression({
		products,
		siteId,
		pageLoadId,
		ssCookieData,
		tag,
		placement,
		skus,
		placementProductId,
	});

	const productPayload = products.map((product) => {
		const sku = placementProductId;
		const payload = {
			type: BeaconType.PROFILE_PRODUCT_IMPRESSION,
			id: v4(),
			pid: parentId,
			event: {
				product: {
					id: product.id,
					...(skus &&
						skus.length > 0 && {
							seed: { sku },
						}),
					mappings: {
						core: {
							uid: product.id,
							sku,
							name: product.name,
							url: product.path,
							imageUrl:
								product.media.images.map((i) => i.url.standard).toString() ??
								"",
							thumbnailImageUrl:
								product.media.images.map((i) => i.url.thumbnail).toString() ??
								"",
							price: product.pricesExTax?.price?.value,
							msrp: product.pricesExTax?.basePrice?.value,
						},
					},
				},
				context: {
					type: "product-recommendation",
					tag,
					placement,
				},
			},
			category: BeaconCategory.RECOMMENDATIONS,
			context: {
				website: { trackingCode: siteId ?? "unknown" },
				userId: ssCookieData.ssUserId,
				sessionId: ssCookieData.ssSessionId,
				pageLoadId: "",
			},
		};
		return payload;
	});

	return [payload, ...productPayload];
};

/*
Profile Impression
*/
export const beaconTrackProfileImpression = (impressionData: AllBeaconArgs) => {
	const parentId = v4();
	const {
		products,
		siteId,
		pageLoadId,
		ssCookieData,
		tag,
		placement,
		skus,
		placementProductId,
	} = impressionData;
	const payload = {
		type: BeaconType.PROFILE_IMPRESSION,
		id: parentId,
		event: {
			profile: {
				tag,
				placement,
				...(placementProductId !== "" && {
					seed: [{ sku: placementProductId }],
				}),
				threshold: products.length,
			},
			context: {
				type: "product-recommendation",
				tag,
				placement,
			},
		},
		category: BeaconCategory.RECOMMENDATIONS,
		context: {
			website: { trackingCode: siteId ?? "unknown" },
			userId: ssCookieData.ssUserId,
			sessionId: ssCookieData.ssSessionId,
			pageLoadId,
		},
	};
	return payload;
};

/*
Profile Render
*/
export const beaconTrackProfileRender = (
	trackingData: CommonArgs & AdditionalArgs,
) => {
	const parentId = v4();
	const {
		ssCookieData,
		siteId,
		pageLoadId,
		tag,
		placement,
		skus,
		placementProductId,
	} = trackingData;
	const payload = {
		type: BeaconType.PROFILE_RENDER,
		id: parentId,
		event: {
			profile: {
				tag,
				placement,
				...(placementProductId === "" && {
					seed: [{ sku: placementProductId }],
				}),
			},
			context: {
				type: "product-recommendation",
				tag,
				placement,
			},
		},
		category: BeaconCategory.RECOMMENDATIONS,
		context: {
			website: { trackingCode: siteId ?? "unknown" },
			userId: ssCookieData.ssUserId,
			sessionId: ssCookieData.ssSessionId,
			pageLoadId,
		},
	};

	return payload;
};

/*
Profile Click
*/
export const beaconTrackProfileClick = (
	trackingData: CommonArgs & AdditionalArgs,
) => {
	const parentId = v4();
	const {
		ssCookieData,
		siteId,
		pageLoadId,
		tag,
		placement,
		skus,
		placementProductId,
	} = trackingData;
	const payload = {
		type: BeaconType.PROFILE_CLICK,
		id: parentId,
		event: {
			profile: {
				tag,
				placement,
				seed: [{ sku: placementProductId }],
			},
			context: {
				type: "product-recommendation",
				tag,
				placement,
			},
		},
		category: BeaconCategory.RECOMMENDATIONS,
		context: {
			website: { trackingCode: siteId ?? "unknown" },
			userId: ssCookieData.ssUserId,
			sessionId: ssCookieData.ssSessionId,
			pageLoadId,
		},
	};

	return payload;
};

export const beaconTrackCartLineItemsRender = (
	cartLineItemData: CartPhysicalItem[],
	trackingData: CommonArgs & AdditionalArgs,
) => {
	const parentId = v4();
	const { ssCookieData, siteId, pageLoadId, tag, placement, skus } =
		trackingData;
	const payload = {
		type: BeaconType.PROFILE_RENDER,
		id: parentId,
		event: {
			profile: {
				tag,
				placement,
				...(skus &&
					skus.length > 0 && { seed: skus.map((value) => ({ sku: value })) }),
			},
			context: {
				type: "product-recommendation",
				tag,
				placement,
			},
		},
		category: BeaconCategory.RECOMMENDATIONS,
		context: {
			website: { trackingCode: siteId ?? "unknown" },
			userId: ssCookieData.ssUserId,
			sessionId: ssCookieData.ssSessionId,
			pageLoadId,
		},
	};

	const products = getBeaconProductsFromCartPhysicalItems(cartLineItemData);

	const productPayload = products.map((product) => {
		const payload = {
			type: BeaconType.PROFILE_PRODUCT_RENDER,
			id: v4(),
			pid: parentId,
			event: {
				product: {
					id: product.uid,
					...(skus &&
						skus.length > 0 && {
							seed: skus.map((value) => ({ sku: value })),
						}),
					mappings: {
						core: {
							uid: product.uid,
							sku: product.sku,
							name: product.name,
							url: product.path,
							imageUrl: product.imageUrl,
							price: product.price,
							msrp: product.msrp,
						},
					},
				},
				context: {
					type: "product-recommendation",
					tag,
					placement,
				},
			},
			category: BeaconCategory.RECOMMENDATIONS,
			context: {
				website: { trackingCode: siteId ?? "unknown" },
				userId: ssCookieData.ssUserId,
				sessionId: ssCookieData.ssSessionId,
				pageLoadId,
			},
		};
		return payload;
	});
	return [payload, ...productPayload];
};

export const getBeaconProductsFromCartPhysicalItems = (
	lineItems: CartPhysicalItem[],
): CartLineItemDataType[] => {
	const products = lineItems.map((l) => ({
		uid: l.id,
		sku: l.sku,
		name: l.name,
		path: l.url,
		imageUrl: l.image_url,
		price: l.original_price,
		msrp: l.sale_price,
	}));

	return products;
};

export const extractImageUrl = (
	media: Image[] | undefined,
	imageType: "thumbnail" | "mainImage",
): string => {
	if (!media || media.length === 0) return "";

	const thumbnailImage = media.find((item) => item.url.thumbnail)?.url
		.thumbnail;
	const mainImages = media.filter((item) => item.url.standard);
	if (imageType === "thumbnail" && thumbnailImage) {
		return thumbnailImage;
	} else if (imageType === "mainImage" && mainImages[0]) {
		return mainImages[0]?.url.standard;
	} else {
		return "";
	}
};

export const getNewlyVisibleProduct = (
	products: ProductData[] | undefined,
	visibleIndices: number[],
) => {
	if (!products || !visibleIndices) {
		return undefined;
	}

	return products.filter((_, index) => visibleIndices.includes(index));
};

export const getVisibleProducts = (
	products: ProductData[],
	swiper: SwiperType,
) => {
	if (!swiper.slides) {
		return undefined;
	}

	const swiperRect = swiper.el.getBoundingClientRect();

	const visibleSlides = swiper.slides.filter((slide) => {
		const slideRect = slide.getBoundingClientRect();
		// Check if the slide intersects with the Swiper's container
		return (
			slideRect.right > swiperRect.left && slideRect.left < swiperRect.right
		);
	});

	const indexes = visibleSlides.map((_, index) => index);
	if (indexes.length === 0) return;
	return products.filter((_, index) => indexes.includes(index));
};
