export const hashString = (str: string) => {
	const hash = str.split("").reduce((hash, char) => {
		hash = (hash << 5) - hash + char.charCodeAt(0);
		return hash & hash; // Convert to 32bit integer
	}, 0);

	return hash.toString();
};

export function jumbleString(input: string): string {
	const arr = input.split("");

	// Shuffle the array using Fisher-Yates algorithm
	for (let i = arr.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[arr[i], arr[j]] = [arr[j], arr[i]]; // Swap elements
	}

	return arr.join("");
}
