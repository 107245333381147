"use client";

import React from "react";
import styles from "./QuoteTestimonials.module.scss";

// Import Swiper React components
import {
	A11y,
	EffectFade,
	Keyboard,
	Navigation,
	Scrollbar,
} from "swiper/modules";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import { Builder } from "@builder.io/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import SwiperNavigationButtons from "../SwiperNavigationButton/SwiperNavigationButton";

type QuoteTestimonialsProps = {
	centreAlignText: boolean;
	quotes: [
		{
			quoteTitle: string;
			quoteText: string;
			name: string;
			company: string;
		},
	];
};

const QuoteTestimonials: React.FC<QuoteTestimonialsProps> = ({
	centreAlignText,
	quotes = [],
}) => {
	const carouselId = "quote-testimonials";
	return (
		<section className={styles.quoteSection}>
			<Swiper
				className={styles.swiper}
				slidesPerView={1}
				modules={[Navigation, Scrollbar, EffectFade, A11y, Keyboard]}
				loop={quotes?.length > 1 ? true : false}
				a11y={{
					prevSlideMessage: "Previous Slide",
					nextSlideMessage: "Next Slide",
				}}
				keyboard={{
					enabled: true,
				}}
				navigation={{
					enabled: true,
					nextEl: `[data-button-ref="${carouselId}-button-next"]`,
					prevEl: `[data-button-ref="${carouselId}-button-previous"]`,
				}}
				scrollbar={true}
				effect='fade'
				fadeEffect={{
					crossFade: true,
				}}
			>
				{quotes?.map((quote, index) => (
					<SwiperSlide key={index}>
						<div
							className={`${styles.quoteArea} ${
								centreAlignText ? styles.centreAlign : ""
							}`}
						>
							{quote.quoteTitle && (
								<h2 className={centreAlignText ? styles.centreAlign : ""}>
									{quote.quoteTitle}
								</h2>
							)}
							<p
								className={`${styles.quoteText} ${
									centreAlignText ? styles.centreAlign : ""
								}`}
							>
								{quote.quoteText}
							</p>
							{quote.name && <p className={styles.quoteName}>{quote.name}</p>}
							{quote.company && (
								<p
									className={`${styles.quoteCompany} ${
										centreAlignText ? styles.centreAlign : ""
									}`}
								>
									{quote.company}
								</p>
							)}
						</div>
					</SwiperSlide>
				))}
				<SwiperNavigationButtons buttonIdentifier={carouselId} />
			</Swiper>
		</section>
	);
};

export default QuoteTestimonials;

Builder.registerComponent(QuoteTestimonials, {
	name: "Quote Testimonials",
	inputs: [
		{
			name: "centreAlignText",
			friendlyName: "Center Text ?",
			type: "boolean",
			defaultValue: false,
		},
		{
			name: "quotes",
			friendlyName: "Quotes",
			type: "list",
			subFields: [
				{
					name: "quoteTitle",
					friendlyName: "Quote Title",
					type: "string",
					defaultValue: "What our Employees are saying!",
					helperText: "~50 characters",
				},
				{
					name: "quoteText",
					friendlyName: "Quote Text",
					type: "longText",
					defaultValue:
						"“Movora proved to be helpful, accommodating and succeeded in meeting with our sometimes demanding deadlines and budget!” ",
					required: true,
					helperText: "~75 characters",
				},
				{
					name: "name",
					friendlyName: "Name",
					type: "string",
					defaultValue: "Peter Renwick",
					helperText: "~50 characters",
				},
				{
					name: "company",
					friendlyName: "Company",
					type: "string",
					defaultValue: "Willows Veterinary Centre",
					helperText: "~50 characters",
				},
			],
			defaultValue: [
				{
					quoteText:
						"“Movora proved to be helpful, accommodating and succeeded in meeting with our sometimes demanding deadlines and budget!” ",
					name: "Peter Renwick",
					company: "Willows Veterinary Centre",
				},
			],
		},
	],
});
