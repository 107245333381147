"use client";

import React, { FC } from "react";
import HeaderBurgerButton from "../HeaderBurgerButton/HeaderBurgerButton";
import HeaderLogo from "../HeaderLogo/HeaderLogo";
import { HeaderBuilderContent } from "../headerTypes";
import { useHeader } from "../hooks/useHeader";
import styles from "./HeaderCorporate.module.scss";
import NavCorporate from "./NavCorporate/NavCorporate";
import { Region } from "@/components/LocalWebsiteSelector/RegionSelectorModal";

interface HeaderProps {
	builderMenuContent: HeaderBuilderContent;
	regionDropdowns: Region[];
}

const HeaderCorporate: FC<HeaderProps> = ({
	builderMenuContent,
	regionDropdowns,
}) => {
	const { openMobileMenu, handleOpenMobileMenu } = useHeader();
	return (
		<header className={styles.headerCorporate}>
			<div className={styles.layout}>
				<HeaderBurgerButton
					isOpen={false}
					ariaLabel='Open main navigation menu'
					onButtonClick={() => handleOpenMobileMenu(true)}
				/>
				<HeaderLogo
					url={`${builderMenuContent.logo?.url}?format=webp`}
					altText={builderMenuContent.logo?.altText}
				/>
				<div className={styles.emptySquare} />

				<NavCorporate
					builderMenuContent={builderMenuContent}
					openMobileMenu={openMobileMenu}
					setOpenMobileMenu={handleOpenMobileMenu}
					regionDropdowns={regionDropdowns}
				/>
			</div>
		</header>
	);
};

export default HeaderCorporate;
