"use client";

import { Builder } from "@builder.io/react";
import React from "react";
import Markdown from "../UI/Markdown/Markdown";
import styles from "./SubBanner.module.scss";
import clsx from "clsx";

interface SubBannerProps {
	title: string;
	addFilter: boolean;
	description: string;
	backgroundImage: string;
}

const SubBanner: React.FC<SubBannerProps> = ({
	title,
	addFilter,
	description,
	backgroundImage,
}) => {
	return (
		<section className={styles.subBannerSection}>
			<div
				className={clsx(styles.subBanner, {
					[styles.backgroundColor]: addFilter,
				})}
				style={{ backgroundImage: `url(${backgroundImage}?format=webp)` }}
				role='presentation'
			>
				<div className={styles.subBannerText}>
					<h2>{title}</h2>
					<Markdown content={description} className={styles.markdown} />
				</div>
			</div>
		</section>
	);
};

export default SubBanner;

Builder.registerComponent(SubBanner, {
	name: "Sub Banner",
	inputs: [
		{
			name: "title",
			type: "longText",
			defaultValue: "Title",
			required: true,
			helperText: "~25 characters",
		},
		{
			name: "addFilter",
			friendlyName: "Add Blue Filter?",
			type: "boolean",
			defaultValue: true,
		},
		{
			name: "description",
			type: "richText",
			defaultValue: "description text",
			required: true,
			helperText: "~225 characters",
		},
		{
			name: "backgroundImage",
			type: "file",
			allowedFileTypes: ["jpeg", "jpg", "png"],
			defaultValue: "https://placehold.co/1500x350",
			helperText: "1500×350 px | .jpeg, .jpg, .png | ~350 KB",
		},
	],
});
