"use client";
import { useCallback, useEffect, useMemo, useState } from "react";

export const useHeader = () => {
	const [openMobileMenu, setOpenMobileMenu] = useState(false);
	const handleOpenMobileMenu = useCallback((state = false) => {
		setOpenMobileMenu(state);
	}, []);

	useEffect(() => {
		const bodyClassList = document.querySelector("body")?.classList;
		if (!bodyClassList) return;
		openMobileMenu
			? bodyClassList.add("removeScroll")
			: bodyClassList.remove("removeScroll");
	}, [openMobileMenu]);

	return useMemo(
		() => ({
			handleOpenMobileMenu,
			openMobileMenu,
		}),
		[handleOpenMobileMenu, openMobileMenu],
	);
};
