"use client";

import useStoreKeys from "./useStoreKeys";
import { getConfig } from "@/lib/searchspring/searchspring.config";
import { v4 } from "uuid";
import Cookies from "js-cookie";
import { SearchSpringCookie } from "@/lib/searchspring/types";
const useBeaconClientArgs = () => {
	const { store, lang } = useStoreKeys();
	const pageLoadId = v4();
	const config = getConfig(store);
	const siteId = config.globals.siteId;
	const ssCookieData = {
		[SearchSpringCookie.ssUserId]:
			Cookies.get(SearchSpringCookie.ssUserId) || v4(),
		[SearchSpringCookie.ssSessionId]:
			Cookies.get(SearchSpringCookie.ssSessionId) || v4(),
		[SearchSpringCookie.ssShopperId]:
			Cookies.get(SearchSpringCookie.ssShopperId) || undefined,
		[SearchSpringCookie.ssCartProducts]:
			Cookies.get(SearchSpringCookie.ssCartProducts) || undefined,
		[SearchSpringCookie.ssViewedProducts]:
			Cookies.get(SearchSpringCookie.ssViewedProducts) || undefined,
	};

	return { store, lang, siteId, pageLoadId, ssCookieData };
};

export default useBeaconClientArgs;
