import { UrlObject } from "./urls";

const fr: UrlObject = {
	login: "se-connecter",
	cart: "chariot",
	"create-account": "creer-un-compte",
	"forgot-password": "mot-de-passe-oublie",
};

export default fr;
