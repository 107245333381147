import clsx from "clsx";
import Link from "next/link";
import {
	AnchorHTMLAttributes,
	ButtonHTMLAttributes,
	LegacyRef,
	Ref,
	forwardRef,
} from "react";
import IconMiniLogo from "../Icon/IconMiniLogo/IconMiniLogo";
import styles from "./Button.module.scss";

type Props = {
	variant?: "primary" | "secondary" | "tertiary" | "rounded" | "empty";
	children: React.ReactNode;
	className?: string;
	id?: string;
	isLoading?: boolean;
	active?: boolean;
	disabled?: boolean;
};

type ButtonProps = Props &
	ButtonHTMLAttributes<HTMLButtonElement> & {
		as: "button";
	};

type ButtonWithLinkProps = Props &
	AnchorHTMLAttributes<HTMLAnchorElement> & {
		as: "link";
	};

const Button = ({
	as,
	variant = "primary",
	children,
	className = "",
	isLoading,
	active = false,
	disabled = false,
	...props
}: ButtonProps | ButtonWithLinkProps) => {
	if (as === "link" && (props as ButtonWithLinkProps).href) {
		const p = props as ButtonWithLinkProps;
		return (
			<ButtonWithLink
				{...p}
				variant={variant}
				className={className}
				active={active}
			>
				{children}
			</ButtonWithLink>
		);
	}

	const p = props as ButtonProps;
	return (
		<button
			{...p}
			type={p.type}
			className={clsx([
				styles.btn,
				styles[variant],
				active && styles.active,
				className,
			])}
			disabled={disabled || isLoading}
		>
			{isLoading ? <IconMiniLogo /> : children}
		</button>
	);
};

const ButtonWithLink = ({
	variant = "primary",
	className,
	children,
	active = false,
	...props
}: ButtonWithLinkProps) => {
	return (
		<Link
			className={clsx([
				styles.btn,
				styles[variant],
				active && styles.active,
				className,
			])}
			href={props.href || ""}
			{...props}
		>
			{children}
		</Link>
	);
};

export default Button;
