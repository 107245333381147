import { Cart, CartPhysicalItem, ProductData } from "@/lib/5874/types";
import { getProduct } from "@/lib/bigcommerce/product";

export const getLineItemForProduct = (
	cart: Cart | undefined,
	product: ProductData | { id: string | number },
) => {
	if (!cart) {
		return undefined;
	}

	return cart.line_items.physical_items.find(
		(item) => item.product_id === product.id,
	);
};

export const getProductForLineItem = async (lineItemId: string) => {
	return await getProduct(Number(lineItemId));
};
