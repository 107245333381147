import { FC, ReactElement } from "react";
import styles from "./Icon.module.scss";

interface Props {
	icon: ReactElement | any;
}

const Icon: FC<Props> = ({ icon }) => {
	return <i className={styles.icon}>{icon}</i>;
};

export default Icon;
