"use client";
import { Builder } from "@builder.io/react";
import React from "react";
import styles from "./CallOutText.module.scss";

interface CallOutTextProps {
	text?: string;
}

function CallOutText({ text }: CallOutTextProps) {
	if (!text) return null;

	return (
		<div className={styles.callOutTextContainer}>
			<div className={styles.callOutText}>{text}</div>
		</div>
	);
}

export default CallOutText;

Builder.registerComponent(CallOutText, {
	name: "CallOutText",
	inputs: [
		{
			name: "text",
			type: "longText",
			required: true,
			defaultValue:
				"Dolore cillum et anim culpa ut quis do commodo eiusmod fugiat. Lorem ad culpa aliquip aute eu aute ipsum minim cillum consequat tempor deserunt incididunt.",
		},
	],
});
