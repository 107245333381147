"use client";

import React from "react";
import styles from "./TriContentBlock.module.scss";
import { Builder } from "@builder.io/react";
import Markdown from "../UI/Markdown/Markdown";
import clsx from "clsx";

interface TriContentBlockProps {
	whiteBlock: {
		content: string;
	};
	topRightBlock: {
		title: string;
		description: string;
		backgroundImage: string;
		addFilter: boolean;
	};
	bottomRightBlock: {
		title: string;
		description: string;
		backgroundImage: string;
		addFilter: boolean;
	};
}

const TriContentBlock: React.FC<TriContentBlockProps> = ({
	whiteBlock,
	topRightBlock,
	bottomRightBlock,
}) => {
	return (
		<section className={styles.triContentBlockSection}>
			<div className={styles.blocks}>
				<div className={styles.whiteBlock}>
					<div className={styles.whiteBlockText}>
						<Markdown
							content={whiteBlock.content}
							className={styles.markdown}
						/>
					</div>
				</div>
				<div className={styles.rightSide}>
					<div
						className={clsx(styles.rightBlock, {
							[styles.backgroundColor]: topRightBlock.addFilter,
						})}
						style={
							topRightBlock.backgroundImage
								? {
										backgroundImage: `url(${topRightBlock.backgroundImage}?format=webp)`,
								  }
								: undefined
						}
						role='presentation'
					>
						<div className={styles.blockText}>
							<h2>{topRightBlock.title}</h2>
							<Markdown
								content={topRightBlock.description}
								className={styles.markdown}
							/>
						</div>
					</div>
					<div
						className={clsx(styles.rightBlock, {
							[styles.backgroundColor]: bottomRightBlock.addFilter,
						})}
						style={
							bottomRightBlock.backgroundImage
								? {
										backgroundImage: `url(${bottomRightBlock.backgroundImage}?format=webp)`,
								  }
								: undefined
						}
						role='presentation'
					>
						<div className={styles.blockText}>
							<h2>{bottomRightBlock.title}</h2>
							<Markdown
								content={bottomRightBlock.description}
								className={styles.markdown}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default TriContentBlock;

Builder.registerComponent(TriContentBlock, {
	name: "Tri-Content Block",
	inputs: [
		{
			name: "whiteBlock",
			type: "object",
			defaultValue: {
				content: "some content",
			},
			subFields: [
				{
					name: "content",
					type: "richText",
					required: true,
				},
			],
		},
		{
			name: "topRightBlock",
			type: "object",
			defaultValue: {
				title: "title",
				description: "this is the description",
				backgroundImage: "https://placehold.co/150x100",
				addFilter: true,
			},
			subFields: [
				{
					name: "title",
					type: "longText",
					required: true,
				},
				{
					name: "description",
					type: "richText",
					required: true,
				},
				{
					name: "backgroundImage",
					type: "file",
					allowedFileTypes: ["jpeg", "jpg", "png"],
				},
				{
					name: "addFilter",
					friendlyName: "Add Blue Filter?",
					type: "boolean",
				},
			],
		},
		{
			name: "bottomRightBlock",
			type: "object",
			defaultValue: {
				title: "title",
				description: "this is the description",
				backgroundImage: "https://placehold.co/150x100",
				helperText: "150×100 px | .jpeg, .jpg, .png | ~100 KB",
				addFilter: true,
			},
			subFields: [
				{
					name: "title",
					type: "longText",
					required: true,
				},
				{
					name: "description",
					type: "richText",
					required: true,
				},
				{
					name: "backgroundImage",
					type: "file",
					allowedFileTypes: ["jpeg", "jpg", "png"],
					helperText: "150×100 px | .jpeg, .jpg, .png | ~100 KB",
				},
				{
					name: "addFilter",
					friendlyName: "Add Blue Filter?",
					type: "boolean",
				},
			],
		},
	],
});
