"use client";
import { BigCommerceStoreContext } from "@/providers/BigCommerceStoreProvider";
import { formatPrice } from "@/utils/price-utils";
import { FC, useContext } from "react";

interface Props {
	price: number;
	className?: string;
}

const FormattedPrice: FC<Props> = ({ price, className }) => {
	const { currencyFormattingRules } = useContext(BigCommerceStoreContext);

	const { formattedValue } = formatPrice(price, currencyFormattingRules);

	return <span className={className}>{formattedValue}</span>;
};

export default FormattedPrice;
