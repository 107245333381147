"use client";
import { Builder } from "@builder.io/react";
import React from "react";
import styles from "./TagMap.module.scss";
import Button from "../UI/Button/Button";

interface Tag {
	tag: string;
	link: string;
	alternativeAria: boolean;
	ariaLabel: string;
}

interface TagMapProps {
	title?: string;
	tags?: Tag[];
	image?: {
		image: string;
		altText: string;
	};
}

function TagMap({ title, tags, image }: TagMapProps) {
	if (!title || !tags || !image) return null;

	return (
		<section className={styles.outer}>
			<div className={styles.container}>
				<div className={styles.left}>
					<h2 className={styles.title}>{title}</h2>
					<div className={styles.btnLinks}>
						{tags.map(({ tag, link, alternativeAria, ariaLabel }) => {
							return (
								<Button
									key={link}
									variant='rounded'
									as='link'
									href={link || "#"}
									target='_blank'
									aria-label={alternativeAria ? ariaLabel : tag}
								>
									{tag || ""}
								</Button>
							);
						})}
					</div>
				</div>
				<div className={styles.right}>
					<img
						className={styles.image}
						src={`${image.image}?format=webp`}
						alt={image.altText}
						height={360}
						width={640}
					/>
				</div>
			</div>
		</section>
	);
}

export default TagMap;

Builder.registerComponent(TagMap, {
	name: "TagMap",
	inputs: [
		{
			name: "title",
			type: "longText",
			required: true,
			defaultValue: "Enter title here.",
			helperText: "~30 characters",
		},
		{
			name: "image",
			type: "object",
			defaultValue: {
				image: "https://placehold.co/318x180",
				altText: "alt text",
			},
			subFields: [
				{
					name: "image",
					type: "file",
					allowedFileTypes: ["jpeg", "jpg", "png"],
					helperText: "318×180 px | .jpeg, .jpg, .png | ~150 KB",
					required: true,
				},
				{
					name: "altText",
					friendlyName: "Alt Text",
					type: "longText",
					required: true,
				},
			],
		},
		{
			name: "tags",
			type: "list",
			subFields: [
				{
					name: "tag",
					type: "string",
					required: true,
					helperText: "~25 characters",
				},
				{
					name: "link",
					type: "longText",
					required: true,
				},
				{
					name: "alternativeAria",
					friendlyName: "Add Custom Link Aria Label?",
					type: "boolean",
					defaultValue: false,
				},
				{
					name: "ariaLabel",
					friendlyName: "Aria Label",
					type: "long-text",
					showIf: `options.get('alternativeAria')`,
				},
			],
		},
	],
});
