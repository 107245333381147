"use client";
import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from "react";

import clsx from "clsx";
import IconOnlyButton from "../UI/Button/IconOnlyButton/IconOnlyButton";
import IconMinus from "@/app/assets/images/svg/minus.svg";
import IconPlus from "@/app/assets/images/svg/plus.svg";
import styles from "./QuantityChanger.module.scss";
import { Cart, ProductData } from "@/lib/5874/types";
import { useFireDataLayerCartEvents } from "@/hooks/useFireDataLayerEvents";
import { ChangeType } from "@/datalayer/DataLayerContextProvider";

export interface QuantityChangerProps {
	value?: number;
	minValue?: number;
	maxValue?: number;
	onChange?: (quantity: number) => void;
	className?: string;
	loading?: boolean;
	disabled?: boolean;
	debounceTime?: number;
	isWhiteVersion?: boolean;
	isWishlist?: boolean;
	cart?: Cart | undefined;
	product?: ProductData | undefined;
}

const QuantityChanger: FC<QuantityChangerProps> = ({
	value = 1,
	minValue = 1,
	maxValue,
	onChange,
	className = "",
	loading = false,
	disabled = false,
	debounceTime = 200,
	isWhiteVersion = false,
	isWishlist = false,
	cart,
	product,
}) => {
	const initialValue = minValue && value < minValue ? minValue : value;
	const [quantity, setQuantity] = useState<number>(initialValue);
	const [typedInput, setTypedInput] = useState<string>(String(initialValue));
	const [debounce, setDebounce] = useState<NodeJS.Timeout>();
	const { fireCartEvent } = useFireDataLayerCartEvents();

	useEffect(() => {
		setQuantity(value);
		setTypedInput(String(value));
	}, [value]);

	const handleQuantityChange = (changeType: ChangeType) => {
		// Prevent minus quantities
		if (quantity === minValue && changeType === "decrease") {
			return;
		}
		if (
			maxValue !== undefined &&
			quantity === maxValue &&
			changeType === "increase"
		) {
			return;
		}
		if (changeType === "increase") {
			debounceChange(quantity + 1);
			if (isWishlist === true) return;
			fireCartEvent("increase", 1, cart, product);
		}
		if (changeType === "decrease") {
			debounceChange(quantity - 1);
			if (isWishlist === true) return;
			fireCartEvent("decrease", 1, cart, product);
		}
	};

	const getInputAsNumber = () => {
		try {
			return parseInt(typedInput);
		} catch {
			return 1;
		}
	};

	const debounceChange = (quantity: number) => {
		setQuantity(quantity);
		setTypedInput(String(quantity));

		if (!onChange) {
			return;
		}

		const timeout = setTimeout(
			() => {
				onChange?.(quantity);
				clearTimeout(debounce);
				setDebounce(undefined);
			},
			debounceTime < 200 ? 200 : debounceTime,
		);

		setDebounce((prev) => {
			clearTimeout(prev);
			return timeout;
		});
	};

	const handleBlurChange = () => {
		const value = getInputAsNumber() || 1;

		if (!typedInput) {
			setTypedInput(String(value));
		}

		if (value === quantity) {
			return;
		}

		debounceChange(value);
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			handleBlurChange();
		}
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;

		if (value.length === 0) {
			setTypedInput("");
			return;
		}

		try {
			const valueAsNumber = parseInt(value);

			if (valueAsNumber < minValue) {
				debounceChange(minValue);
				return;
			}
			if (maxValue && valueAsNumber > maxValue) {
				debounceChange(maxValue);
				return;
			}

			setTypedInput(event.target.value);
		} catch {
			console.error("Input isn't valid number");
		}
	};

	return (
		<div
			className={clsx(
				styles.quantityChanger,
				isWhiteVersion && styles.quantityChangerWhite,
				disabled ? "disabled" : "",
				className,
			)}
		>
			<IconOnlyButton
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleQuantityChange("decrease");
				}}
				onKeyDown={(e) => {
					e.stopPropagation();
					if (e.key !== "Enter") {
						return;
					}
					handleQuantityChange("decrease");
				}}
				className={clsx([styles.decrease])}
				disabled={
					loading ||
					disabled ||
					(minValue !== undefined && quantity === minValue)
				}
				type='button'
				icon={<IconMinus />}
			/>
			<input
				value={typedInput}
				type='number'
				inputMode='numeric'
				className='quantityChanger__qty'
				onChange={handleInputChange}
				onBlur={handleBlurChange}
				onKeyDown={handleKeyDown}
				min={minValue}
				max={maxValue}
				step={1}
				disabled={loading || disabled}
			/>
			<IconOnlyButton
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleQuantityChange("increase");
				}}
				onKeyDown={(e) => {
					if (e.key !== "Enter") {
						return;
					}
					handleQuantityChange("increase");
				}}
				className={clsx([styles.increase])}
				disabled={loading || disabled}
				type='button'
				icon={<IconPlus />}
			/>
		</div>
	);
};

export default QuantityChanger;
