"use client";

import {
	handleAddToCart,
	handleUpdateCartLineItem,
} from "@/components/PDP/ProductForm/_actions/addToCart";
import QuantityChanger from "@/components/QuantityChanger/QuantityChanger";
import StockAvailability from "@/components/StockAvailability/StockAvailability";
import AddToWishlistCTA from "@/components/UI/AddToWishlistCTA/AddToWishlistCTA";
import Button from "@/components/UI/Button/Button";
import FormattedPrice from "@/components/UI/FormattedPrice/FormattedPrice";
import checkUserHasRolePermission from "@/hooks/checkUserHasRolePermission";
import useStoreKeys from "@/hooks/useStoreKeys";
import { useI18n } from "@/i18n/client";
import { ProductAddToCart, ProductData } from "@/lib/5874/types";
import { useSessionContext } from "@/lib/auth/SessionContextProvider";
import useIntellisuggestTracking from "@/hooks/useIntelliSuggestTracking";
import { WishlistDetailsData } from "@/lib/bigcommerce-b2b/types";
import { useCartContext } from "@/providers/CartContextProvider";
import { getLineItemForProduct } from "@/utils/cart-utils";
import Link from "next/link";
import { useMemo, useState } from "react";
import styles from "./ProductOption.module.scss";
import { ProductAvailability } from "@/lib/bigcommerce/enums";
import {
	getCartDatalayerDetails,
	getItemListData,
	useDataLayerContext,
} from "@/datalayer/DataLayerContextProvider";
import mapProductOptions from "@/hooks/mapProductOptions";
import AddToWishlistButtonComplex from "@/components/UI/AddToWishlistCTA/AddToWishlistButtonComplex";

export interface ProductOptionData {
	productData: ProductData;
	handleRemovedFromCart?: () => void;
	handleParentModal?: () => void;
	wishlistId?: number;
	handleAddToWishlist?: (
		productId: number,
	) => Promise<WishlistDetailsData | undefined>;
}

const ProductOption: React.FC<ProductOptionData> = ({
	productData,
	handleRemovedFromCart,
	wishlistId,
	handleAddToWishlist,
}) => {
	const { session } = useSessionContext();
	const { store, lang } = useStoreKeys();
	const { cart, setCart, isModifyingCart, setIsModifyingCart } =
		useCartContext();
	const t = useI18n();
	const [isAddingToCart, setIsAddingToCart] = useState(false);
	const lineItem = cart ? getLineItemForProduct(cart, productData) : undefined;

	if (!session || !productData) return null;

	// Datalayer events
	const { addToCart } = useDataLayerContext();
	const { itemListId, itemListName } = getItemListData(productData);
	const cartDetails = getCartDatalayerDetails(cart);
	const datalayerProduct = {
		product: productData,
		quantity: 1,
		variantId: productData.id,
	};

	// Intellisuggest
	const { basketContents } = useIntellisuggestTracking();

	const productSpecifications = useMemo(() => {
		return mapProductOptions([productData]);
	}, [productData]);

	const addProductToCart = async (data: ProductAddToCart, lineItem?: any) => {
		setIsAddingToCart(true);
		setIsModifyingCart(true);

		const res = lineItem
			? await handleUpdateCartLineItem(data, lineItem)
			: await handleAddToCart([data]);

		// Handle scenarios where the res has no data
		if (!res.data) {
			handleRemovedFromCart && handleRemovedFromCart();
			setCart(res.data);
		}

		// Process the cart update on success
		if (res.status === "success") {
			const basket = res.data.line_items.physical_items;
			setCart(res.data);
			basketContents(basket);
			setIsModifyingCart(false);
			setIsAddingToCart(false);
		}

		setIsModifyingCart(false);
		setIsAddingToCart(false);
	};

	return (
		<div key={productData.id} className={styles.product}>
			<Link
				href={`/${store}/${lang}/${productData.path}`}
				className={styles.name}
			>
				{productData.name}
			</Link>
			<span className={styles.sku}>{productData.sku}</span>
			<div className={styles.specs}>
				{productSpecifications?.map((spec) => (
					<div key={spec.label} className={styles.spec}>
						<span className={styles.label}>{spec.label}:</span>
						<span className={styles.value}>{spec.options[0].label}</span>
					</div>
				))}
			</div>
			<span className={styles.price}>
				<FormattedPrice price={productData?.pricesExTax?.basePrice?.value} />
			</span>
			<div className={styles.actions}>
				{!wishlistId ? (
					<>
						{lineItem !== undefined ? (
							<QuantityChanger
								minValue={0}
								value={lineItem.quantity}
								cart={cart}
								onChange={(quantity) => {
									addProductToCart(
										{
											id: productData.id,
											quantity,
										},
										lineItem,
									);
								}}
								debounceTime={600}
							/>
						) : (
							<Button
								as='button'
								type='button'
								className={styles.cartButton}
								disabled={
									isModifyingCart ||
									productData.availability === ProductAvailability.UNAVAILABLE
								}
								isLoading={isAddingToCart}
								onClick={(e) => {
									e.stopPropagation();
									addToCart({
										products: [datalayerProduct],
										itemListId: itemListId,
										itemListName: itemListName,
										cart: cartDetails,
									});
									addProductToCart(
										{
											id: productData.id,
											quantity: 1,
										},
										null,
									);
								}}
							>
								{t("cart.addToCart")}
							</Button>
						)}
						{checkUserHasRolePermission("update_shopping_list_item") && (
							<AddToWishlistButtonComplex
								userId={session.userId}
								product={productData}
							/>
						)}
					</>
				) : (
					""
				)}
				{wishlistId && (
					<AddToWishlistCTA
						product={productData}
						userId={session.userId}
						wishlistId={wishlistId}
						customClass={wishlistId ? styles.wishlistButton : ""}
						handleAddToWishlist={handleAddToWishlist}
					/>
				)}
			</div>
			<StockAvailability product={productData} className={styles.stock} />
		</div>
	);
};

export default ProductOption;
