"use client";

import { Builder } from "@builder.io/react";
import clsx from "clsx";
import React from "react";
import Button from "../UI/Button/Button";
import Markdown from "../UI/Markdown/Markdown";
import styles from "./ContentHeader.module.scss";

type ButtonInput = {
	text: string;
	link: string;
	alternativeAria: boolean;
	ariaLabel: string;
};

interface ContentHeaderProps {
	title: string;
	subtitle: string;
	showTitle: boolean;
	primaryButton: ButtonInput;
	secondaryButton: ButtonInput;
}

const ContentHeader: React.FC<ContentHeaderProps> = ({
	title,
	subtitle,
	showTitle = true,
	primaryButton,
	secondaryButton,
}) => {
	return (
		<section className={styles.section}>
			<div className={clsx([styles.contentHeaderWrapper])}>
				{showTitle ? <h1>{title}</h1> : null}
				<Markdown className={styles.markdown} content={subtitle} />
				<div className={styles.buttons}>
					{primaryButton?.text && (
						<Button
							as='link'
							variant='primary'
							href={primaryButton.link}
							aria-label={
								primaryButton.alternativeAria
									? primaryButton.ariaLabel
									: primaryButton.text
							}
						>
							{primaryButton.text}
						</Button>
					)}
					{secondaryButton?.text && (
						<Button
							as='link'
							variant='secondary'
							href={secondaryButton.link}
							aria-label={
								secondaryButton.alternativeAria
									? secondaryButton.ariaLabel
									: secondaryButton.text
							}
						>
							{secondaryButton.text}
						</Button>
					)}
				</div>
			</div>
		</section>
	);
};

export default ContentHeader;

const buttonInputs = [
	{
		name: "text",
		friendlyName: "Button Text",
		type: "text",
		helperText: "Leave blank to remove button",
	},
	{
		name: "link",
		friendlyName: "Button Link",
		type: "url",
	},
	{
		name: "alternativeAria",
		friendlyName: "Add Custom Link Aria Label?",
		type: "boolean",
		defaultValue: false,
	},
	{
		name: "ariaLabel",
		friendlyName: "Aria Label",
		type: "long-text",
		showIf: `options.get('alternativeAria')`,
	},
];

Builder.registerComponent(ContentHeader, {
	name: "Content Header",
	defaults: {
		component: {
			name: "Content Header",
			options: {
				title: "Title",
				subtitle: "Subtitle",
				showTitle: "Show Title?",
				primaryButton: {
					text: "Primary Button",
					link: "#",
				},
				secondaryButton: {
					text: "Secondary Button",
					link: "#",
				},
			},
		},
	},
	inputs: [
		{
			name: "title",
			friendlyName: "Title",
			type: "string",
			required: true,
			helperText: "~25 characters",
		},
		{
			name: "subtitle",
			friendlyName: "Subtitle",
			type: "richText",
			helperText: "~125 characters",
		},
		{
			name: "showTitle",
			friendlyName: "Show Title?",
			type: "boolean",
		},
		{
			name: "primaryButton",
			friendlyName: "Primary Button",
			type: "object",
			subFields: buttonInputs,
			helperText: "~15 characters",
		},
		{
			name: "secondaryButton",
			friendlyName: "Secondary Button",
			type: "object",
			subFields: buttonInputs,
			helperText: "~15 characters",
		},
	],
});
